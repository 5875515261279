import {observer} from "mobx-react";
import './termsOfUser.css'

export const TermsOfUser = observer( () => {
    return <div id='terms' className="pt_header">
        <h1 id={'top'}>Пользовательское соглашение</h1>
        <h1 className={'paragraph'}>1. Общие положения</h1>
        <p>1.1. Настоящее Пользовательское соглашение (далее по тексту – Соглашение) относится к целевой аудитории сайта «Инновации детям», расположенному по адресу https://lk.playstand.ru (далее по тексту — Сайт), и ко всем соответствующим сайтам, связанным с ним.</p>
        <p>1.2. Настоящее Соглашение регулирует отношения между ООО "СтендАп Инновации" (далее по тексту – Администрация сайта) и Пользователем данного Сайта (далее по тексту — Пользователь), вместе именуемые "Стороны".</p>
        <p>1.3. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</p>
        <p>1.4. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</p>
        <p>1.5. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</p>

        <h1 className={'paragraph'}>2. Определение терминов</h1>
        <p>2.1. Для целей настоящего Соглашения определены следующие термины:</p>
        <p>2.1.1 «Инновации детям» – сайт, расположенный на доменном имени https://lk.playstand.ru, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов и принадлежащий ООО «СтендАп Инновации».</p>
        <p>2.1.2. Администрация сайта – уполномоченные сотрудники на управление Сайтом, действующие от имени ООО «СтендАп Инновации».</p>
        <p>2.1.3. Пользователь сайта (далее — Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</p>
        <p>2.1.4. Содержание сайта (далее – Содержание) — охраняемые результаты интеллектуальной деятельности.</p>

        <h1 className={'paragraph'}>3. Предмет соглашения</h1>
        <p>3.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к содержащейся на сайте информации.</p>
        <p>3.1.1. Сайт предоставляет Пользователю следующие виды услуг (сервисов):</p>
        <ul>
            <li>доступ к электронному контенту, с правом просмотра контента;</li>
            <li>иные виды услуг (сервисов), реализуемые на страницах сайта.</li>
        </ul>
        <p>3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) сайта, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы).</p>
        <p>3.2. Доступ к сайту предоставляется на бесплатной основе.</p>
        <p>3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту, Пользователь считается присоединившимся к настоящему Соглашению.</p>
        <p>3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.</p>

        <h1 className={'paragraph'}>4. Права и обязанности сторон</h1>
        <p>4.1. Администрация сайта вправе: </p>
        <p>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</p>
        <p>4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.</p>
        <p>4.2. Пользователь вправе: </p>
        <p>4.2.1. Пользоваться всеми имеющимися на Сайте услугами.</p>
        <p>4.2.2. Задавать любые вопросы, относящиеся к услугам.</p>
        <p>4.2.3. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.</p>
        <p>4.3. Пользователь Сайта обязуется: </p>
        <p>4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</p>
        <p>4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.</p>
        <p>4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</p>
        <p>4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</p>
        <p>4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</p>
        <p>4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.</p>
        <p>4.3.7. Не использовать сервисы сайта с целью:</p>
        <p>4.3.7.1. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти;</p>
        <p>4.3.7.2. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации;</p>
        <p>4.3.7.3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме;</p>
        <p>4.3.7.4. ущемления прав меньшинств;</p>
        <p>4.3.7.5. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного сайта.</p>
        <p>4.4. Пользователю запрещается: </p>
        <p>4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта.</p>
        <p>4.4.2. Нарушать надлежащее функционирование Сайта.</p>
        <p>4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта.</p>
        <p>4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте.</p>
        <p>4.4.5. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.</p>
        <p>4.4.6. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.</p>
        <p>4.4.7. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права Сайта или других лиц.</p>

        <h1 className={'paragraph'}>5. Использование сайта</h1>
        <p>5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется ООО "СтендАп Инновации". </p>
        <p>5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.</p>
        <p>5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.</p>
        <p>5.4. Некоторые действия, могут потребовать создания учётной записи Пользователя.</p>
        <p>5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи.</p>
        <p>5.6. Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности.</p>
        <p>5.7. Администрация сайта обладает правом в одностороннем порядке аннулировать учетную запись Пользователя, если она не использовалась более 3 календарных месяцев подряд без уведомления Пользователя.</p>
        <p>5.8. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о оказанию услуг, предоставляемых на Сайте.</p>
        <p>5.9. Информация, размещаемая на Сайте, не должна истолковываться как изменение настоящего Соглашения.</p>
        <p>5.10. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения.</p>
        <p>5.11. Политика конфиденциальности указана в Приложении №1 настоящего соглашения, являющейся неотъемлемой частью соглашения</p>
        <p>5.12. Любой из документов, перечисленных в Приложении №1 настоящего Соглашения, может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.</p>

        <h1 className={'paragraph'}>6. Ответственность</h1>
        <p>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</p>
        <p>6.2. Администрация сайта не несет ответственности за:</p>
        <p>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</p>
        <p>6.2.3. Надлежащее функционирование Сайта в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.</p>

        <h1 className={'paragraph'}>7. Нарушение условий пользовательского соглашения</h1>
        <p>7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.</p>
        <p>7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности Сайта, других Пользователей.</p>
        <p>7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</p>
        <p>7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</p>
        <p>7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</p>

        <h1 className={'paragraph'}>8. Разрешение сторон</h1>
        <p>8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора по адресу support@playstand.ru) с пометкой «Урегулирование разногласий».</p>
        <p>8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии по адресу support@playstand.ru.</p>
        <p>8.3. При невозможности разрешить спор в добровольном порядке, любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им законодательством Российской Федерации.</p>
        <p>8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение трех лет после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.</p>

        <h1 className={'paragraph'}>9. Дополнительные условия</h1>
        <p>9.1. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.</p>

        <h1 className={'paragraph'}>Приложение №1</h1>
        <div style={{margin: '0 0 0 0'}}><a href={`${window.location.href.substr(0, window.location.href.indexOf('/', 8))}/privacy`}>5.11.1. Политика конфиденциальности.</a></div>
    </div>

})