import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import countryStore from "./CountryStore";

const CountryUI = observer( (props) => {

    function onDropDownCountry() {
        document.getElementById('dropdown_countr_list').style.display = 'inline';
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const CountryList = (elem, index) => {
        return <button key={"county_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {countryStore.ChoiceCounty(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){countryStore.ChoiceCounty(elem); props.checkChange()}}}>

            <div style={{width: "100%"}}>
                <p className={'dropdown_element'}>{elem}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
            <input className={`${props.disabled? "input_test dropdown_img" : "input_test_change dropdown_img_active"} ${countryStore.validCountry}`} id="dropdown_countr" autoComplete="off"
                   disabled={props.disabled} placeholder={"Выберите страну"} type="text"
                   style={{width: "100%", caretColor: "transparent", cursor: "default"}}
                   value={countryStore.country}
                   onFocus={() => countryStore.FocusCountry()}
                   onBlur={() => countryStore.Blur()}
                   onClick={() => {onDropDownCountry()}}
            />
            <div className="dropdown_col_4" id="dropdown_countr_list" style={{width: "100%", display: "none", background: "#F5F7FA"}}>
                {countryStore.returnCountryList.map((elem, index) => CountryList(elem, index))}
            </div>

            <HelpText text={countryStore.HelpText}/>
    </div>
})

export default CountryUI;