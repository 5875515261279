import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {FormTeacher} from "./formTeacher";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";
import gardenStore from "../../../Stores/Garden/GardenStore";

export const TeacherAdd = observer(() => {

    useEffect(() => {
        nameStore.Clear();
        surnameStore.Clear();
        middleNameStore.Clear();
        dropdownPositionStore.Clear();
        dropdownPositionStore.Clear();
    })

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.userData.UserType !== 'Pedagog' || authStore.userData.CreatedGartenId === 0){
        history.push('/account');
        return null;
    }

    useEffect(() => {
        gardenStore.disabledTeacherButton = 'disabled';
    })

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/kindergarten'} className="grey_text">ДЕТСКИЙ САД/</Link>
                <Link to={'/kindergarten/teacher'} className="blue_text">ДОБАВИТЬ ПЕДАГОГА</Link>
            </p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ ПЕДАГОГА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <FormTeacher/>
                </div>
            </div>
        </div>
    </>
})