import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {nameGardenStore} from './nameGardenStore'

const NameGardenUI = observer((props ) => {
    return <>
            <input className={`${props.disabled? "input_test" : "input_test_change"} ${nameGardenStore.GardenValid}`} type="text" disabled={props.disabled}
                   aria-describedby="emailHelp" placeholder="Название дет. сада" value={nameGardenStore.Garden} onChange={(e) => nameGardenStore.SetGarden(e)}/>
            <HelpText text={nameGardenStore.HelpText}/>
    </>
})

export default NameGardenUI;