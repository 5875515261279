import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {rThen} from "../../requestThen";
import {groupStore} from "../../../Components/Inputs/ChildGroup/ChildGroupStore";

export let updateChildrenInGroup = async (groupId, memberId) => {
    if(validationChildChange)
        return request.put(
            `/api/children_group/update_child/${authStore.userData.CreatedGartenId === 0? authStore.decodeJwt.userId : authStore.userData.CreatedGartenId}/${groupId}/${memberId}`,
            //authStore.decodeJwt.userId
            //authStore.userData.CreatedGartenId
            {
                name: nameStore.GetName,
                surname: surnameStore.GetSurname
            }
        ).then(r => {
            return rThen(r)
        })
}

let validationChildChange = () =>{
    nameStore.ValidName();
    surnameStore.ValidSurname();
    return nameStore.GetName !== '' && surnameStore.GetSurname !== '';
}