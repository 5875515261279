import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";
import request from "../../Request";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import {rThen} from "../../requestThen";

export let addGartenPedagog = async (kindegartenId) => {

    if(validationUpdateChildren)
        return request.post(
            `/api/gartens/add_pedagog/${kindegartenId}`,
            {
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                middleName: middleNameStore.GetmiddleName,
                positionId: dropdownPositionStore.getPosition
            },
            true
        ).then(r => {
            return rThen(r);
        })
}

let validationUpdateChildren = () =>{
    nameStore.ValidName();
    surnameStore.ValidSurname();
    middleNameStore.ValidmiddleName();
    dropdownPositionStore.ValidPosition();

    return (nameStore.GetName !== "" && surnameStore.GetSurname !== "" &&
        dropdownPositionStore.getPosition !== "");
}