import React from 'react';
import { observer } from "mobx-react";
import authStore from '../../Stores/AuthStore/AuthStore'
import {ProfileAccount} from './AccountPages/Profile'
import { useHistory } from 'react-router-dom'
import {oldPasswordStore} from "../../Components/Inputs/Password/OldPasswordStore";
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import {confirmPasswordStore} from "../../Components/Inputs/Password/ConfirmPasswordStore";
import relatedAccsStore from "../../Stores/RelatedAccsStore/RelatedAccsStore";
import buttonProfileStore from "../../Components/Buttons/ProfileButton/buttonProfileStore";
import countryStore from "../../Components/Inputs/Country/CountryStore";
import stateStore from "../../Components/Inputs/State/StateStore";
import {cityStore} from "../../Components/Inputs/City/cityStore";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import {innStore} from "../../Components/Inputs/INN/innStore";
import {nameGardenStore} from "../../Components/Inputs/GardenName/nameGardenStore";
import childDropdownStore from "../../Components/Inputs/ChildDropdown/ChildDropdownStore";
import inviteInputStore from "../../Components/Inputs/InviteCodeInput/InviteInputStore";
import inviteSendStore from "../../Components/Buttons/inviteButton/InviteSendStore";
import {genderStore} from "../../Components/Inputs/gender/genderStore";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import masterRegStore from "../../Stores/Registration/MasterRegStore";
import {middleNameStore} from "../../Components/Inputs/middleName/middleNameStore";
import {telephoneStore} from "../../Components/Inputs/Telephone/TelephoneStore";

export const Account1 = observer( () => {
    authStore.headerVisible = true;
    sessionStorage.removeItem('invite')
    sessionStorage.removeItem('inviteData')

    let history = useHistory();

    if(authStore.localStoreBlock){
        history.push('/authorization');
        return null;
    }

    if (authStore.userData === undefined){
        if(sessionStorage.getItem('step') === null){
            masterRegStore.setStep('invite')
        }

        history.push('/registration/invite');
        return null;
    } else masterRegStore.setStep('invite');

    if (!authStore.isAuth) {
      history.push('/')
      return null
    }

    if(authStore?.userData?.UserType === null){
        history.push('/')
        return null;
    }

    oldPasswordStore.Clear();
    newPasswordStore.Clear();
    confirmPasswordStore.Clear();

    childDropdownStore.Clear();
    inviteInputStore.Clear();
    inviteSendStore.Clear();

    nameStore.Clear();
    surnameStore.Clear();
    middleNameStore.Clear();
    countryStore.Clear();
    stateStore.Clear();
    cityStore.Clear();
    dropdownPositionStore.Clear();
    genderStore.Clear();
    innStore.Clear();
    nameGardenStore.Clear();
    genderStore.Clear();
    telephoneStore.Clear();
    relatedAccsStore.ReletedAccount = true;
    buttonProfileStore.buttonBlock = false;

    return <ProfileAccount/>
})
