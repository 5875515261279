import {makeAutoObservable, action} from "mobx";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import placeStore from "../../../../../Stores/Registration/place";
import modalStore from "../../../../../Components/modal/modalStore";
import countryStore from "../../../../../Components/Inputs/Country/CountryStore";
import stateStore from "../../../../../Components/Inputs/State/StateStore";
import {cityStore} from "../../../../../Components/Inputs/City/cityStore";
import buttonProfileStore from "../../../../../Components/Buttons/ProfileButton/buttonProfileStore";
import {nameStore} from "../../../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../../../Components/Inputs/gender/genderStore";
import {middleNameStore} from "../../../../../Components/Inputs/middleName/middleNameStore";
import {updateParent} from "../../../../../Request/api/Parents/updateParent";
import Notify from "../../../../../Notifications/Notify";
import {telephoneStore} from "../../../../../Components/Inputs/Telephone/TelephoneStore";
import {updateTelephone} from "../../../../../Request/api/User/updateUser";

class ParentStore {

    @action getUserData = () => {
        nameStore.Name = authStore.userData.Name;
        surnameStore.Surname = authStore.userData.Surname;
        middleNameStore.middleName = authStore.userData.MiddleName;
        genderStore.Gender = (authStore.userData.Sex? "Мужской" : "Женский");
        countryStore.country = authStore.userData.Country;
        stateStore.state = authStore.userData.State;
        cityStore.City = authStore.userData.City;
        telephoneStore.Telephone = authStore.masterAccountData.phone.slice(1);
    }

    constructor() {
        makeAutoObservable(this);
    }

    CheckForm = () => {

        if(cityStore.City !== '' && nameStore.Name !== '' && surnameStore.Surname !== '' &&
            genderStore.Gender !== '' && countryStore.country !== '' && stateStore.state !== '' &&
            telephoneStore.Telephone !== '' &&
            (nameStore.Name !== authStore.userData.Name ||
            surnameStore.Surname !== authStore.userData.Surname ||
            middleNameStore.middleName !== authStore.userData.MiddleName ||
            genderStore.Gender !== (authStore.userData.Sex? "Мужской" : "Женский") ||
            countryStore.country !== authStore.userData.Country ||
            stateStore.state !== authStore.userData.State ||
            cityStore.City !== authStore.userData.City ||
            (telephoneStore.Telephone.slice(0,1) === '7'? telephoneStore.Telephone.slice(1) : telephoneStore.Telephone) !== authStore.masterAccountData.phone.slice(2))){
            buttonProfileStore.SetSaveButtonBlock(false)
        } else
            buttonProfileStore.SetSaveButtonBlock(true)
    }

    UpdateParent = async () => {
        nameStore.ValidName();
        surnameStore.ValidSurname();
        middleNameStore.ValidmiddleName();
        genderStore.ValidGender();
        countryStore.ValidCountry();
        stateStore.ValidState();
        cityStore.ValidCity();
        telephoneStore.ValidTelephone();
        if(nameStore.GetName !== '' && surnameStore.GetSurname !== '' && typeof genderStore.getGender === 'boolean'
            && countryStore.getCountry !== '' && stateStore.getState !== '' && cityStore.GetCity !== '' && telephoneStore.GetTelephone !== ''){
            updateParent().then(r => {
                if(r !== null && r.type === "Data"){
                    buttonProfileStore.SetSaveButtonBlock(true)
                    buttonProfileStore.SetButtonBlock()
                    modalStore.SetModalInfo("", "ДАННЫЕ АККАУНТА ОБНОВЛЕНЫ")
                    modalStore.simpleModalOpen();

                    if(!Notify.authResult){
                        authStore.getCompulsoryToken();
                    }
                } else {
                    modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                    modalStore.simpleModalOpen();
                }

            })
            if(telephoneStore.Telephone !== authStore.masterAccountData.phone.slice(1) && telephoneStore.GetTelephone !== ''){
                updateTelephone().then(r => {
                    if(r !== null && r.type === "Data"){
                        if(!Notify.authResult){
                            authStore.getCompulsoryToken();
                        }
                    } else {
                        modalStore.SetModalInfo('Произошла ошибка при обновлении телефона', "Ошибка")
                        modalStore.simpleModalOpen();
                    }
                })
            }
        }
    }

    keyDownForm = (e) => {
        if(!buttonProfileStore.saveButtonBlock && e.code === "Enter"
            && countryStore.elem !== 'dropdown-item' &&
            genderStore.elem !== 'dropdown-item' &&
            stateStore.elem !== 'dropdown-item'){
            this.UpdateParent();
        }
        stateStore.elem = ''
        countryStore.elem = ''
        genderStore.elem = ''
    }
}

const parentStore = new ParentStore();

export default parentStore;