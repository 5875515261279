import request from "../../Request";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";
import {rThen} from "../../requestThen";

export let updatePedagogPosition = async (kindegartenId, PutMemberId) => {

    if(dropdownPositionStore.getPosition !== '')
        return request.put(
            `/api/gartens/set_new_position/${kindegartenId}/${PutMemberId}/${dropdownPositionStore.getPosition}`,
            {}
        ).then(r => {
            return rThen(r);
        })
}