import React from "react";
import { observer } from "mobx-react";
import kindergardenStore from './StoreProfile/KindergardenProfile';
import relatedAccsStore from "../../../../Stores/RelatedAccsStore/RelatedAccsStore";
import InnUI from "../../../../Components/Inputs/INN/iinUI";
import NameGardenUI from "../../../../Components/Inputs/GardenName/nameGardenUI";

export const KindergardenProfile = observer( (props) => {
    if(relatedAccsStore.ReletedAccount){
        kindergardenStore.getUserData();
        relatedAccsStore.SetRelatedAccountFlag()
    }

    return <form onChange={kindergardenStore.CheckForm} onKeyDown={(e) => kindergardenStore.keyDownForm(e)}>

        <div className="form_group pt-30">
            <div className="form_row">
                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <NameGardenUI disabled={props.disabled}/>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group ml-5 mb-5 mt-sm-5">
                        <InnUI disabled={props.disabled}/>
                    </div>
                </div>
            </div>
        </div>
    </form>
})

