import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import modalStore from "../../../Components/modal/modalStore";
import React from "react";
import {rThen} from "../../requestThen";

export let createPedagogInvite = async (kindegartenId, memberId) => {

    return request.get(`/api/gartens/create_pedagog_invite/${kindegartenId}/${memberId}`, true).then(r => {
        return rThen(r);
    })
}

