import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import '../../../styles/dropdown.css'
import teacherDropdownStore from "./teacherDropdownStore";

export const DropdownTeacherUI = observer( (props) => {

    function onDropDownTeacherSelct() {
        document.getElementById('dropdown_teacher_list').style.display = 'inline';
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const teacherList = (elem, index) => {
        return <button key={"teacher_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {teacherDropdownStore.ChoiceTeacher(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){teacherDropdownStore.ChoiceTeacher(elem); props.checkChange()}}}
        >
            <div style={{width: '100%'}}>
                <p className={'dropdown_element'}>
                    {elem.User == null ? elem.FIO : elem.User.Surname + ' ' + elem.User.Name}
                </p>
            </div>
        </button>
    }

    return <div className="mr-5 dropdown_div">
                <input className={`input_test_change dropdown_img_active ${teacherDropdownStore.validTeacher}`} id="dropdown_teacher" autoComplete="off"
                       disabled={props.disabled} placeholder={"Укажите педагога"} type="text"
                       style={{width: "100%", caretColor: "transparent", cursor: "default"}}
                       value={teacherDropdownStore.teacher}
                       onFocus={() => teacherDropdownStore.FocusTeacher()}
                       onClick={() => {onDropDownTeacherSelct()}}
                       onBlur={() => teacherDropdownStore.Blur()}
                />
                <div className="dropdown_col_12" id="dropdown_teacher_list" style={{background: '#F5F7FA'}}>

                    {teacherDropdownStore.teacherList.map((elem, index) => teacherList(elem, index))}
                </div>
                <HelpText text={teacherDropdownStore.HelpText}/>
    </div>
})