import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {rThen} from "../../requestThen";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";

export let updateKindegartenPedagog = async (memberId) => {

    if(validationupdateKindegartenPedagog)
        return request.put(
            `/api/gartens/update_pedagog/${authStore.userData.CreatedGartenId}/${memberId}`,
            {
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                middleName: middleNameStore.GetmiddleName,
                positionId: dropdownPositionStore.getPosition
            }
        ).then(r => {
            return rThen(r)
        })
}

let validationupdateKindegartenPedagog = () =>{
    nameStore.ValidName();
    surnameStore.ValidSurname();
    middleNameStore.ValidmiddleName();
    dropdownPositionStore.ValidPosition();
    return nameStore.GetName !== '' && surnameStore.GetSurname !== '' && dropdownPositionStore.getPosition !== '';
}