import {observer} from "mobx-react";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import React, {useEffect} from "react";
import RegStep3Children from "../../RegAuth/RegStep/RegStep3Children";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {regChild} from "../../../Stores/Registration/SecondRegistration";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {ageStore} from "../../../Components/Inputs/Age/ageStore";

export const ChildrenAdd = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType !== "Parent"){
        history.push('/account')
    }

    useEffect(() => {
        nameStore.Clear();
        surnameStore.Clear();
        genderStore.Clear();
        ageStore.Clear();
        regChild.CheckForm();
    })

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                <Link to={'/children'} className="grey_text">ДЕТИ/</Link>
                <Link to={'/children/add'} className="blue_text">ДОБАВИТЬ РЕБЁНКА</Link>
            </p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ РЕБЁНКА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <RegStep3Children/>
                </div>
            </div>
        </div>
    </>
})