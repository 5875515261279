import {action, makeAutoObservable, observable, runInAction} from "mobx";

class NameGardenStore {
    Garden = "";
    @observable GardenValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(gardenValid="", helpText=""){
        this.GardenValid = gardenValid;
        this.HelpText = helpText;
    }

    SetGarden(e){
        this.Garden = e.target.value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        this.UserKeyDown();
    }

    ValidGarden(){
        if(this.Garden.trim().length >= 6){
            runInAction(() => {this.UserKeyDown();})
        } else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Слишком короткое название');})
        }
    }

    get GetGarden(){
        this.ValidGarden();
        if(this.GardenValid === "is-invalid"){
            return "";
        } else {
            return this.Garden;
        }
    }

    @action Clear(){
        this.Garden = "";
        this.HelpText = "";
        this.GardenValid = "";
    }
}

export const nameGardenStore = new NameGardenStore();