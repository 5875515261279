import {observer} from "mobx-react";
import React from "react";
import {registrationButtonStore} from "./RegistrationButtonStore";
import '../../../styles/divButton.css'

const RegistrationButtonUI = observer((props) => {


    return <>
        {(window.location.href.indexOf('/registration/') !== -1)?
            <>
                <button type="button" id="auth_button" style={{width: '100%', padding: 0}} className={`buttons button_blue ${registrationButtonStore.disabledButton}`} onClick={props.checkUserData}>Далее</button>
            </>
            :
                <button type="button" id="auth_button" style={{width: '100%', padding: 0}} className={`buttons button_blue ${registrationButtonStore.disabledButton}`} onClick={props.checkUserData}>Далее</button>
        }
    </>
})

export default RegistrationButtonUI;