import {observer} from "mobx-react";
import gardenStore from "../../../Stores/Garden/GardenStore";
import React from "react";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";
import modalStore from "../../../Components/modal/modalStore";
import {Link} from "react-router-dom";

export const TeacherTable = observer(() => {

    return <>
        <table className="table mr-20 ml-20 mt-5">
            <thead style={{width: '100%'}}>
            <tr className="table_header">
                <th className="col-6">Фамилия Имя</th>
                <th className="col-6">Должность</th>
            </tr>
            </thead>
            <tbody>
            {gardenStore.Teachers.map((teacher) =>
                <>
                    {gardenStore.teacherId !== 0 && gardenStore.changeFlag && teacher.MemberId === gardenStore.teacherId ?
                        <>
                            <tr className="table_main" style={{background: "#E6E9ED", color: "black"}} onClick={() => {gardenStore.closeButtons()}}>
                                <td>{teacher.User == null ? teacher.Surname + ' ' + teacher.Name : teacher.User.Surname + ' ' + teacher.User.Name}</td>
                                <td>{teacher.Position.PositionName}</td>
                            </tr>
                            <tr className="table_main" style={{background: "#E6E9ED", color: "black"}}>
                                <td colSpan={2}>
                                    <div className="row">
                                        <div className="form_row">
                                            <div className={teacher.User === null? "col-lg-4 col-md-4 col-sm-4": "col-sm-6"}>
                                                <div className="form_group mr-5 ml-5 mb-5">
                                                    <Link to={'/kindergarten/teacher/change'} style={{width: '100%', display: 'flex', textDecoration: 'none'}}>
                                                        <button id="select_education" className="buttons button_green"
                                                                style={{width: '100%', margin: 0, padding: 0}}>ИЗМЕНИТЬ ДАННЫЕ
                                                            <span className="sm_none"> ПЕДАГОГА</span>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className={teacher.User === null? "col-lg-4 col-md-4 col-sm-4": "col-sm-6"}>
                                                <div className="form_group mr-5 ml-5 mb-5">
                                                    <button onClick={() => gardenStore.deletePedagog()} className="buttons button_blue" style={{width: '100%', margin: 0, padding: 0, background: "#4FC1E9"}}>
                                                        УДАЛИТЬ ПЕДАГОГА
                                                    </button>
                                                </div>
                                            </div>

                                            {gardenStore.teacher.User === null?
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <div className="form_group mr-5 ml-5 mb-5">
                                                    <button onClick={() => gardenStore.GetInviteCode()} id="select_education" className="buttons button_yellow" style={{width: '100%', margin: 0, padding: 0, background: "#FFCE54"}}>
                                                        <span className="invite_mobile">ПОЛУЧИТЬ ИНВАЙТ-КОД</span>
                                                        <span className="invite_comp">ПОЛУЧИТЬ КОД-ПРИГЛАШЕНИЕ</span>
                                                    </button>
                                                </div>
                                            </div>
                                                : <></>}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </>
                        :
                        <tr className="table_main" onClick={() => {
                            gardenStore.ChangeTd(teacher)
                        }}>
                            {teacher.IsDefault? <></>:
                                <>

                                    <td>{teacher.User == null ? teacher.Surname + ' ' + teacher.Name : teacher.User.Surname + ' ' + teacher.User.Name}</td>
                                    <td>{teacher.Position.PositionName}</td>

                                </>}
                        </tr>
                    }
                </>
            )}
            </tbody>
        </table>
    </>
    })

function changePositionClick(teacher){
    const promiseToModal = new Promise((resolve) => {
        setTimeout(() => {resolve();}, 0);
    });
    promiseToModal.then(r => {
        modalStore.modalCode = true;
        gardenStore.PutMemberId = teacher.MemberId;
        dropdownPositionStore.Clear();
        modalStore.SetModalInfo("", "УКАЖИТЕ НОВУЮ ДОЛЖНОСТЬ")
        modalStore.simpleModalOpen();
    })

}