import {observer} from "mobx-react";
import modalStore from "../../../Components/modal/modalStore";
import React from "react";
import {useHistory} from "react-router-dom";
import {CheckStep} from "../registration1";
import masterRegStore from "../../../Stores/Registration/MasterRegStore";
import {ChangeUserAfterReg} from "../../../Stores/Registration/SecondRegistration";

const RegStepQuestion = observer( (props) => {
    let history = useHistory();
    if(props.children){
        CheckStep('questChild');
    } else {
        CheckStep('questKindegarten');
    }

    return <>

        <p className="title_greetings">Регистрация аккаунта</p>
        <p className="greetings">{props.question}</p>

        <div className="rect">
            <svg width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
        </div>

        <div className="row buttons_div pt-sm-100">
            <div className="col-md-6 col-sm-12 mb-15 ">
                <div className="form_group">
                    <div className="form_row">
                        <button id="select_education" className="buttons max_200 right_button sm_button button_green"
                                onClick={() => {
                                    history.push(`${props.link}`);
                                    if(props.children)
                                        masterRegStore.setStep('children');
                                    else
                                        masterRegStore.setStep('kindegarten')
                                }}
                        >ДА
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-md-6 col-sm-12">
                <div className="form_group">
                    <div className="form_row">
                        <button id="registration_button" className="buttons max_200 sm_button button_blue"
                                onClick={() => {
                                    const promiseToModal = new Promise((resolve) => {
                                        setTimeout(() => {resolve();}, 0);
                                    });
                                    promiseToModal.then(r => {
                                        modalStore.EndReg = true;
                                        ChangeUserAfterReg();
                                        modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                                        modalStore.ModalOpen();
                                        masterRegStore.setStep('invite');
                                    })
                                }}
                        >НЕТ</button>
                    </div>
                </div>
            </div>
        </div>

        <svg className="step_reg" width="70" height="10" viewBox="0 0 70 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <circle cx="65" cy="5" r="5" fill="#434A54"/>
            <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
            <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
        </svg>

    </>

    return <>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-11 mt-4">
                    <div className="card text-center h-100">
                        <div className="card-body px-5 pt-5  flex-column">
                            <div>
                                <div className="h3 text-primary font-weight-300">{props.question}</div>
                            </div>
                            <svg viewBox="0 0 100 100" style={{stroke: "black"}}>
                                <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5"/>
                                <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5"/>
                            </svg>
                        </div>
                        <div className="card-footer bg-transparent px-5 py-4">
                            <div style={{display: "flex", justifyContent: "space-around"}}>
                                <a className="doc_link" href="#" onClick={() => {
                                    history.push(`${props.link}`);
                                    if(props.children)
                                        masterRegStore.setStep('children');
                                    else
                                        masterRegStore.setStep('kindegarten')
                                }}>Да</a>
                                <a className="doc_link" href="#" onClick={() => {
                                    modalStore.EndReg = true;
                                    modalStore.SetModalInfo("Ура!!! Вы зарегистрировались, потвердите ваш email.", "Упешно")
                                    window.$('#message_modal').modal('show');
                                    masterRegStore.setStep('invite');
                                    ChangeUserAfterReg();
                                }}>Нет</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
})

export default RegStepQuestion