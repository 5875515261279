import {observer} from "mobx-react";
import React from "react";
import HelpText from "../../HelpText";
import {ageStore} from "../ageStore";
import monthStore from "../ChildAgeStore/monthStore";

const MonthUI = observer( (props) => {

    function onDropDownCountry() {
        document.getElementById('dropdown_month_list').style.display = 'inline';
        // ageStore.changeStyle();
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const monthList = (elem, index) => {
        return <button key={"month_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {monthStore.ChoiceMonth(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){monthStore.ChoiceMonth(elem); props.checkChange()}}}>
            <div style={{width: "100%"}}>
                <p className={'dropdown_element'}>{elem}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
        <input className={`${props.disabled? "input_test" : "date_input_change_middle dropdown_img_active"}`} id="dropdown_month" autoComplete="off"
               disabled={props.disabled} placeholder={"Выберите месяц"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={monthStore.month}
               onFocus={() => ageStore.changeStyle()}
               onClick={() => {onDropDownCountry()}}
               onBlur={() => monthStore.Blur()}/>

        <div className="dropdown_col_4" id="dropdown_month_list" style={{width: "100%", display: "none", background: "#F5F7FA", overflowY: "scroll", maxHeight: '10em'}}>
            {ageStore.monthList.map((elem, index) => monthList(elem, index))}
        </div>

        <HelpText text={monthStore.HelpText}/>
    </div>
})

export default MonthUI;