import {observer} from "mobx-react";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import GenderUI from "../../../Components/Inputs/gender/genderUI";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {ageStore} from "../../../Components/Inputs/Age/ageStore";
import AgeUI from "../../../Components/Inputs/Age/AgeUI";
import {childrenStore} from "../../../Stores/Children/Children";
import dayStore from "../../../Components/Inputs/Age/ChildAgeStore/dayStore";
import monthStore from "../../../Components/Inputs/Age/ChildAgeStore/monthStore";
import yearStore from "../../../Components/Inputs/Age/ChildAgeStore/yearStore";

export const ChildrenChange = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if (authStore.decodeJwt.userType !== "Parent") {
        history.push('/account')
    }

    if(childrenStore.child === null){
        history.push('/children')
        return null
    }

    useEffect(() => {
        nameStore.Name = childrenStore.child.Name;
        surnameStore.Surname = childrenStore.child.Surname;
        genderStore.Gender = (childrenStore.child.Sex? "Мужской" : "Женский");
        ageStore.Age = childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T"));
        dayStore.day = childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(8).replace('0', '');
        monthStore.month = ageStore.monthList[childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(5, 7).replace('0', '') -1]
        yearStore.year = childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(0,4);
        childrenStore.CheckFormChange();
    })

    return <Page/>
})

const Page = observer(() => {
    let history = useHistory();

    return <>
        <>
            <LeftHeader/>
            <RightHeader/>

            <div className="main mt_215 pt_header">
                <p className="title_greetings">
                    <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                    <Link to={'/children'} className="grey_text">ДЕТИ/</Link>
                    <Link to={'/children/change'} className="blue_text">ИЗМЕНИТЬ РЕБЁНКА</Link>
                </p>
                <p className="greetings">ВВЕДИТЕ ДАННЫЕ РЕБЁНКА</p>

                <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                </svg>

                <div className="row">
                    <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                        <form onChange={childrenStore.CheckFormChange} onKeyDown={(e) => childrenStore.keyDownFormChange(e, history)}>
                            <div className="row pt-30">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-10">
                                        <NameUI/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-10">
                                        <SurnameUI/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5">
                                        <GenderUI checkChange={childrenStore.CheckFormChange}/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                        <AgeUI checkChange={childrenStore.CheckFormChange}/>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                        <button type="button" id="auth_button" style={{width: '100%', padding: 0}} className={`buttons button_blue ${childrenStore.disabledChangeButton}`} onClick={() => childrenStore.CheckChildChange(history)}>Далее</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    </>
})