import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {ChildrenGroupForm} from "./childrenGroupForm";
import {LeftHeader} from "../../../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import {RightHeader} from "../../../../Structure/RightHeader/rightHeader";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import {nameStore} from "../../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../../Components/Inputs/Surname/surnameStore";

export const AddGroupChildren = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if (authStore.userData.UserType !== 'Pedagog') {
        history.push('/account');
        return null;
    }

    if(childGroupsStore.groupChildId === null || childGroupsStore.groupChildId === ""){
        history.push('/groups')
        return null;
    }

    useEffect(()=> {
        nameStore.Clear();
        surnameStore.Clear();
        childGroupsStore.CheckFormChildChange();
    })

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/groups'} className="grey_text">ГРУППЫ/</Link>
                <Link to={'/groups/children/add'} className="blue_text">ДОБАВИТЬ РЕБЁНКА</Link>
            </p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ РЕБЁНКА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <ChildrenGroupForm/>
                </div>
            </div>
        </div>
    </>
})