import {observer} from "mobx-react";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import React from "react";
import inviteSendStore from "../../../Components/Buttons/inviteButton/InviteSendStore";
import {DropdownChildUI} from "../../../Components/Inputs/ChildDropdown/ChildDropdownUI";
import InviteInput from "../../../Components/Inputs/InviteCodeInput/InviteInput";
import InviteSendButton from "../../../Components/Buttons/inviteButton/InviteSendButton";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";

export const ChildrenInvite = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType !== "Parent"){
        history.push('/account')
    }

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                <Link to={'/children'} className="grey_text">ДЕТИ/</Link>
                <Link to={'/children/invite'} className="blue_text">
                    <span className="invite_mobile">ИНВАЙТ-КОД</span>
                    <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                </Link>
            </p>
            <p className="greetings">
                <span className="invite_mobile">ИНВАЙТ-КОД</span>
                <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
            </p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                    <div className="row" style={{width: '100%'}}>
                        <form style={{width: '100%'}} onChange={() => inviteSendStore.FormChange()} onKeyDown={inviteSendStore.KeyDown}>
                            {authStore.decodeJwt.userType === "Pedagog"? <></> : <>
                                <div className="col-md-12 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mt-5 mb-10">
                                        <DropdownChildUI checkChange={inviteSendStore.FormChange}/>
                                    </div>
                                </div>
                            </>}
                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-10">
                                    <InviteInput />
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5">
                                    <InviteSendButton />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
})