import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {getPositions} from "../../../Request/api/Pedagogs/getPositions";
import modalStore from "../../modal/modalStore";

class DropdownPositionStore{
    positionList = []
    position = "";
    positionId = null;
    @observable dropdownPositionFlag = true;
    @observable validPosition = "";
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    setPosition(e){
        this.UserKeyDown()
        e.preventDefault();
    }

    async GetPosition(){
        this.positionList = await getPositions().then(r => {
            if(r !== null && r.type === 'Data'){
                return r.data;
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка");
                return [];
            }
        })
    }

    FocusPosition(){
        this.GetPosition();
    }

    @action UserKeyDown(validPos="", helpText=""){
        this.validPosition = validPos;
        this.HelpText = helpText;
    }

    ValidPosition(){
        if(this.positionId !== null){
            runInAction(() => {this.UserKeyDown();})
        }
        else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите должность');})
        }
    }

    ChoicePosition(position){
        document.getElementById('dropdown_pos_list').style.display = 'none';
        this.position = position.PositionName;
        this.positionId = position.PositionId;
        this.UserKeyDown();
        this.elem = document.activeElement.className
        document.getElementById('dropdown_position').focus();
    }

    SetDefaultPosition(){
        this.validPosition = ''
    }


    get getPosition(){
        this.ValidPosition();
        if(this.validPosition === ""){
            return this.positionId
        } else
            return ""
    }

    @action Clear(){
        this.positionId = null;
        this.position = "";
        this.validPosition = "";
        this.HelpText = "";
        this.elem = "";
    }

    Blur(){
        document.getElementById('dropdown_pos_list').style.display = 'none';
    }
}

const dropdownPositionStore = new DropdownPositionStore();

export default dropdownPositionStore;