import request from "../../Request";
import {rThen} from "../../requestThen";
import authStore from "../../../Stores/AuthStore/AuthStore";

export let checkPedagogInvite = async (inviteCode) => {

    return request.post(
        `/api/pedagogs/check_invite/${authStore.decodeJwt.userId}/${inviteCode}`,
        {},
        true
    ).then(r => {
        return rThen(r);
    })
}