import request from "../../Request";
import {rThen} from "../../requestThen";
import {oldPasswordStore} from "../../../Components/Inputs/Password/OldPasswordStore";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";

export let restorePasswordPut = async (cid, key) => {

    return request.put(
        `/api/User/restore_password/email/check/${cid}/${key}`,
        {
            password: newPasswordStore.GetPassword
        }, null, false
    ).then(r => {
        return rThen(r);
    })
}