import {observer} from "mobx-react";
import authStore from "../../../Stores/AuthStore/AuthStore";
import imageStore from "../../Account/AccountPages/ProfileUser/StoreProfile/image";
import React from "react";
import kindergardenStore from "../../Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";

export const KindegartenAvatar = observer(() => {

    return <>

        {kindergardenStore.avatar === null? <></>:
        <>

            <p style={{margin: 0, padding: 0}}>АВАТАР</p>
            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
            <div className="pt-30"/>
            <div className="row image_div" style={{display: "flex", justifyContent: "space-around"}}>

                <div className="avatar">
                    <div className={'avatar_hover'}>
                        <input type="file" name="file" id="file" onChange={(e) => imageStore.uploadFile(e)} style={{display: 'none'}}/>
                        <label htmlFor="file" className="btn btn-tertiary js-labelFile" style={{width: '100%'}}>
                            ИЗМЕНИТЬ<br/>АВАТАР
                        </label>
                    </div>
                    <img src={kindergardenStore.avatar} width="100%" height="100%" alt=""/>
                </div>

                <div className="pt-30">
                    <span className="link" onClick={() => {authStore.deleteAvatar(authStore.userData.CreatedGartenId)}}>Удалить аватар</span>
                </div>

            </div>
        </>}
    </>
})