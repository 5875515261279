import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {surnameStore} from "./surnameStore";

const SurnameUI = observer((props) => {
    return <>
            <input className={`${props.disabled? "input_test" : "input_test_change"} ${surnameStore.SurnameValid}`} id="user_surname" type="text" disabled={props.disabled} autoComplete={'off'}
                   aria-describedby="emailHelp" placeholder="Фамилия" value={surnameStore.Surname} onChange={(e) => surnameStore.SetSurname(e)}/>
            <HelpText text={surnameStore.HelpText}/>
    </>
})

export default SurnameUI;