import React from "react";
import { observer } from "mobx-react";
import parentStore from "./StoreProfile/parentProfile";
import relatedAccsStore from '../../../../Stores/RelatedAccsStore/RelatedAccsStore'
import CountryUI from "../../../../Components/Inputs/Country/CountryUI";
import StateUI from "../../../../Components/Inputs/State/StateUI";
import CityUI from "../../../../Components/Inputs/City/cityUI";
import GenderUI from "../../../../Components/Inputs/gender/genderUI";
import NameUI from "../../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../../Components/Inputs/Surname/surnameUI";
import MiddleNameUI from "../../../../Components/Inputs/middleName/middleNameUI";

export const ParentProfile = observer( (props) => {
    if(relatedAccsStore.ReletedAccount){
        parentStore.getUserData();
        relatedAccsStore.SetRelatedAccountFlag()
    }
    return <form onChange={parentStore.CheckForm} onKeyDown={(e) => parentStore.keyDownForm(e)}>

        <div className="row pt-30">
            <div className="form_row mr-15 ml_account-15">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5">
                        <NameUI disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <SurnameUI disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <MiddleNameUI disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <GenderUI checkChange={parentStore.CheckForm} disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mt-5 mb-5">
                        <CountryUI checkChange={parentStore.CheckForm} disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mt-5 mb-5">
                        <StateUI checkChange={parentStore.CheckForm} disabled={props.disabled}/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form_group mr-5 ml-5 mt-5 mb-5">
                        <CityUI disabled={props.disabled}/>
                    </div>
                </div>
            </div>
        </div>
    </form>
})