import {observer} from "mobx-react";
import React from "react";
import modalStore from "../../../Components/modal/modalStore";
import MessageModal from "../../../Components/modal/messageModal";
import InviteUI from "../../../Components/Inputs/Invite/InviteUI";
import {inviteStore} from "../../../Components/Inputs/Invite/InviteStore";
import {Link, useHistory} from "react-router-dom";
import {CheckStep} from "../registration1";
import authStore from "../../../Stores/AuthStore/AuthStore";
import masterRegStore from "../../../Stores/Registration/MasterRegStore";

const RegStepInvite = observer( () => {
    let history = useHistory();
    CheckStep('invite');

    return <>
        <p className="title_greetings">Регистрация аккаунта</p>
        <p className="greetings mobile_greetings sm_none_block">У ВАС ЕСТЬ КОД ПРИГЛАШЕНИЕ?</p>
        <p className="greetings mobile_greetings md_none_block">У ВАС ЕСТЬ ИНВАЙТ-КОД?</p>


        <div className="rect">
            <svg width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
        </div>

        <div className="row buttons_div pt-sm-100">
            <div className="col-md-6 col-sm-12 mb-15 ">
                <Link to={'/registration/invite/add'} style={{textDecoration: 'none'}}>
                    <div className="form_group">
                        <div className="form_row">
                            <button id="select_education" className="buttons max_200 right_button sm_button button_green" onClick={() => masterRegStore.pageSwapBool = true}>ДА</button>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-6 col-sm-12">
                <div className="form_group mb-sm-10">
                    <div className="form_row">
                        <button id="registration_button" className="buttons max_200 sm_button button_blue"
                                onKeyDown={(e) => {
                                    sessionStorage.removeItem('invite');
                                    sessionStorage.removeItem('inviteData');
                                    if(e.code === "Enter"){if(authStore.access === null){history.push('/registration/master'); masterRegStore.setStep('master')}
                                    else {history.push('/registration/select'); masterRegStore.setStep('select')}}
                                }}
                                onClick={() => {
                                    sessionStorage.removeItem('invite');
                                    sessionStorage.removeItem('inviteData');
                                    if(authStore.access === null){history.push('/registration/master'); masterRegStore.setStep('master')}
                                    else {history.push('/registration/select'); masterRegStore.setStep('select')}}}
                        >НЕТ</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="link">
            <Link to="/authorization" className="link" onClick={() => {authStore.Logout()}}>Авторизация</Link>
        </div>

        <svg className="step_reg" width="70" height="10" viewBox="0 0 70 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
            <circle cx="5" cy="5" r="5" fill="#434A54"/>
            <circle cx="65" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
        </svg>

    </>
})

export default RegStepInvite