import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import {groupStore} from "../../../Components/Inputs/ChildGroup/ChildGroupStore";
import teacherDropdownStore from "../../../Components/Inputs/TeacherDropdown/teacherDropdownStore";

export let createChildrenGroup = async (kindegartenId) => {

    if(validationCreateChildGroup)
        return request.post(
            `/api/children_group/create/${kindegartenId}/${teacherDropdownStore.getTeacher}`,
            {
                name: groupStore.GetGroup
            },
            true
        ).then(r => {
            return rThen(r);
        })
}

let validationCreateChildGroup = () =>{
    teacherDropdownStore.ValidTeacher();
    groupStore.ValidGroup();

    return teacherDropdownStore.getTeacher !== '' && groupStore.GetGroup !== "";
}