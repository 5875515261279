import React from 'react';
import { observer } from "mobx-react";
import 'react-select-search/style.css';
import {regChild, regKindergarden} from "../../../Stores/Registration/SecondRegistration";
import InnUI from "../../../Components/Inputs/INN/iinUI";
import NameGardenUI from "../../../Components/Inputs/GardenName/nameGardenUI";
import RegistrationButtonUI from "../../../Components/Buttons/RegistrationButton/RegistrationButtonUI";
import {registrationButtonStore} from "../../../Components/Buttons/RegistrationButton/RegistrationButtonStore";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {CheckStep} from "../registration1";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import GenderUI from "../../../Components/Inputs/gender/genderUI";

export const RegStep3Kindergarden = observer(() => {
    if(authStore.isRegistration){
        CheckStep('kindegarten');
    }
    try {
      if (authStore.userData.CreatedGartenId != 0) {
        window.location.reload();
      }
    } catch {

    }

    registrationButtonStore.Clear();


    return <>
        <div className="main">
            <p className="title_greetings">РЕГИСТРАЦИЯ АККАУНТА</p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ ДЕТСКОГО САДА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <form style={{width: '100%'}} onChange={regKindergarden.CheckForm} onKeyDown={(e) => regKindergarden.keyDownForm(e)}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 pt-30">
                                    <NameGardenUI/>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group ml-5 mb-5 mt-5">
                                    <InnUI/>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                    <RegistrationButtonUI checkUserData={regKindergarden.CheckKindergardenData}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <svg className="step_reg" width="70" height="10" viewBox="0 0 70 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="65" cy="5" r="5" fill="#434A54"/>
                <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
                <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
            </svg>
        </div>
    </>

    return <form onChange={regKindergarden.CheckForm} onKeyDown={(e) => regKindergarden.keyDownForm(e)}>

        <InnUI/>
        <NameGardenUI/>

        <RegistrationButtonUI checkUserData={regKindergarden.CheckKindergardenData}/>
    </form>
})

export default RegStep3Kindergarden;
