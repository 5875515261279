import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import '../../../styles/validation.css'

const PasswordUI = observer((props) => {

    return <>
        <div className="btn btn-primary tooltip" style={{width: '100%'}}>
            <input className={props.disabled? `input_test ${props.valid}`: `input_test_change ${props.valid}`} type={props.type}
                   placeholder={props.placeholder} value={props.password} onChange={props.func}/>
            <img height={"30px"} width={"30px"} src={props.src} style={{float: "right", margin: "-46px 15px", position: "relative", zIndex: "2", cursor: "pointer"}} onClick={props.eyeClcik}/>
            <HelpText text={props.HelpText}/>

            <div className="top" style={{display: props.styleDisplay}}>
                <p>Минимальная длина пароля 3 символа.</p>
                <i></i>
            </div>
        </div>
    </>
})

export default PasswordUI;