import {observer} from "mobx-react";
import {ColumnDash} from "./ColumnDash";
import {TextDashboard} from "./textDashboard";
import reportStore, {Base64} from "../../../Stores/Report/ReportStore";

export const ProgressDashboard = observer((props) => {
    return <div style={{posotion: 'relative'}}>
        {reportStore.percentsByGame.map((game, i) => <>
            <div style={{padding: '0 80px'}}>
                <div className="row">
                    <div className="col-md-4">
                        <TextDashboard text={Base64.decode(game.LevelName)}/>
                    </div>
                    <div className="col-md-8">
                        <ColumnDash width={game.Percent * 100} iter={i}/>
                    </div>
                </div>
            </div>
        </>)}
    </div>
})