import {observer} from "mobx-react";
import React from "react";
import reportStore from "../../../Stores/Report/ReportStore";
import {ChildIconDashboard} from "./ChildIcon";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export const ChildSelectorDashboard = observer((props) => {

    const primaryOptions = {
        perPage: 5,
        rewind : true,
        perMove: 1
    };

    const secondaryOptions = {
        perPage: 1,
        rewind : true,
        perMove: 1
    };

    return <>

        <div className={"sm_none"}>
            <Splide options={ primaryOptions } renderControls={ () => ( <ArrowsSlide/> ) }>

                {reportStore.ChildList.map(child => {
                    return <SplideSlide><ChildIconDashboard img={child.Avatar} name={child.Name} id={child.UserId}/></SplideSlide>
                })}

            </Splide>
        </div>

        <div className={"md_none"}>
            <Splide options={ secondaryOptions } renderControls={ () => ( <ArrowsSlide/> ) }>

                {reportStore.ChildList.map(child => {
                    return <SplideSlide><ChildIconDashboard img={child.Avatar} name={child.Name} id={child.UserId}/></SplideSlide>
                })}

            </Splide>
        </div>

        <div style={{height: '1px', background: 'rgba(67, 74, 84, 0.2)', margin: '0 21px'}}/>
    </>

    // return <div className="form_group">
    //     <div style={{display: 'flex', flexDirection: "row", overflowX: "scroll", margin: '30px 20px 0 20px'}} className="large-2">
    //         {reportStore.ChildList.map(child => {
    //             return <ChildIconDashboard img={child.Avatar} name={child.Name}/>
    //         })}
    //     </div>
    // </div>
})

const ArrowsSlide = () => {
    return <>
        <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev" style={{transform: 'rotate(-180deg)', marginTop: '-8px'}}>
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="arrow_hover" d="M10.2855 21.249C10.9123 21.8892 12 21.4454 12 20.5494L12 1.37797C12 0.492243 10.9335 0.0437697 10.3005 0.663305L0.714831 10.0451C0.320067 10.4314 0.31333 11.0647 0.699784 11.4594L10.2855 21.249Z" fill="#434A54" fillOpacity="0.2"/>
                </svg>
            </button>
            <button className="splide__arrow splide__arrow--next">
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="arrow_hover" d="M1.71451 0.75099C1.08765 0.110796 0 0.554625 0 1.45061V20.622C0 21.5078 1.06646 21.9562 1.69946 21.3367L11.2852 11.9549C11.6799 11.5686 11.6867 10.9353 11.3002 10.5406L1.71451 0.75099Z" fill="#434A54" fillOpacity="0.2"/>
                </svg>
            </button>
        </div>
    </>
}