import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import countryStore from "../../../Components/Inputs/Country/CountryStore";
import stateStore from "../../../Components/Inputs/State/StateStore";
import {cityStore} from "../../../Components/Inputs/City/cityStore";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";

export let updatePedagog = async () => {

    return request.put(
        `/api/pedagogs/pedagog_update/${authStore.decodeJwt.userId}`,
        {
            name: nameStore.GetName,
            surname: surnameStore.GetSurname,
            middleName: middleNameStore.GetmiddleName,
            sex: genderStore.getGender,
            country: countryStore.getCountry,
            state: stateStore.getState,
            city: cityStore.GetCity,
            organization: "",
            position: dropdownPositionStore.getPosition
        }
    ).then(r => {
        return rThen(r);
    })
}