import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {genderStore} from './genderStore'

const GenderUI = observer((props) => {
    function onDropDownGender() {
        document.getElementById('dropdown_gender_list').style.display = 'inline';
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const GenderList = (elem, index) => {
        return <button
            style={style} key={"gender_index_"+index} type="button" className={"dropdown_buttons"}
            onMouseDown={() => {genderStore.ChoiceGender(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){genderStore.ChoiceGender(elem); props.checkChange()}}}>

            <div style={{width: '100%'}}>
                <p className={'dropdown_element'}>{elem}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
        <input className={`${props.disabled? "input_test dropdown_img" : "input_test_change dropdown_img_active"} ${genderStore.validGender}`} id="dropdown_gender" autoComplete="off"
               disabled={props.disabled} placeholder={"Укажите пол"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={genderStore.Gender}
               onChange={(e) => genderStore.setGender(e)}
               onBlur={() => genderStore.Blur()}
               onClick={() => {onDropDownGender()}}/>

        <div className="dropdown_col_12" id={'dropdown_gender_list'} style={{background: "#F5F7FA"}}>
            {genderStore.GenderList.map((elem, index) => GenderList(elem, index))}
        </div>

        <HelpText text={genderStore.HelpText}/>
    </div>
})

export default GenderUI;