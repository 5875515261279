import { observable, makeAutoObservable } from "mobx"
import modalStore from "../../Components/modal/modalStore";
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import {oldPasswordStore} from "../../Components/Inputs/Password/OldPasswordStore";
import {confirmPasswordStore} from "../../Components/Inputs/Password/ConfirmPasswordStore";
import {updatePassword} from "../../Request/api/User/updatePassword";

class SecurityStore {
    @observable disabledButton = 'disabled';

    constructor() {
        makeAutoObservable(this);
    }

    async CheckData(){
        if(this.disabledButton === ''){
            newPasswordStore.ValidPassword();
            oldPasswordStore.ValidPassword();
            confirmPasswordStore.ValidPassword();

            if(newPasswordStore.GetPassword !== "" && oldPasswordStore.GetPassword !== "" && confirmPasswordStore.GetPassword !== ""){
                await this.ChangePassword();
            }
        }
    }

    ChangePassword(){
        updatePassword().then(r => {
            if(r !== null){
                if(r.type === "Data"){
                    modalStore.SetModalInfo("", "ПАРОЛЬ УСПЕШНО ИЗМЕНЁН!")
                    modalStore.simpleModalOpen();

                    oldPasswordStore.Clear();
                    newPasswordStore.Clear();
                    confirmPasswordStore.Clear();
                    this.disabledButton = 'disabled';
                } else {
                    modalStore.SetModalInfo(r.message, "Ошибка")
                    modalStore.simpleModalOpen();
                }
            } else {
                modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    CheckForm = () => {
        if(oldPasswordStore.password !== '' && newPasswordStore.password !== '' && confirmPasswordStore.password !== ''){
            this.disabledButton = ''
        } else {
            this.disabledButton = 'disabled'
        }
    }

    keyDownForm = (e) => {
        if(this.disabledButton === '' && e.code === "Enter" && document.activeElement.tagName !== "BUTTON"){
            this.CheckData();
        }
    }

}

const securityStore = new SecurityStore();

export default securityStore;