import {observer} from "mobx-react";
import dropdownPositionStore from "./dropdownPositionStore";
import React from "react";
import HelpText from "../HelpText";

const DropdownUIPosition = observer( (props) => {

    function onDropDownPos() {
        document.getElementById('dropdown_pos_list').style.display = 'inline';
    }

    const style = {background: "transparent", outline: "none",
        padding: 0, display: "flex", flexDirection: "column",
        width: '100%', border: '0 solid transparent'}


    const positionList = (elem, index) => {
        return <button key={"position_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {dropdownPositionStore.ChoicePosition(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){dropdownPositionStore.ChoicePosition(elem); props.checkChange()}}}>
            <div style={{width: '100%'}}>
                <p className={'dropdown_element simple_modal_component'}>{elem.PositionName}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">

        <input className={`${props.disabled? "input_test dropdown_img" : "input_test_change dropdown_img_active"} ${dropdownPositionStore.validPosition} simple_modal_component`} id="dropdown_position" autoComplete="off"
               disabled={props.disabled} placeholder={"Укажите должность"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={dropdownPositionStore.position}
               onFocus={() => dropdownPositionStore.FocusPosition()}
               onChange={(e) => dropdownPositionStore.setPosition(e)}
               onBlur={() => dropdownPositionStore.Blur()}
               onClick={() => {onDropDownPos()}}/>

        <div className="dropdown_col_12" id="dropdown_pos_list" style={props.style === undefined? {width: "100%", display: "none", background: "#F5F7FA"} : props.style}>
            {dropdownPositionStore.positionList.map((elem, index) => positionList(elem, index))}
            <HelpText text={dropdownPositionStore.HelpText}/>
        </div>
    </div>
})

export default DropdownUIPosition;