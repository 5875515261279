import {makeAutoObservable, observable} from "mobx";

class CropStore {
    @observable img = "";
    @observable openCropModal = false;

    constructor() {
        makeAutoObservable(this);
    }

    closeCropModal(){
        this.img = ""
        this.openCropModal = false;
    }

    createImage = url => new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
        })


    async getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
        const image = await this.createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        canvas.width = safeArea
        canvas.height = safeArea

        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = ctx.getImageData(0, 0, safeArea, safeArea)

        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
        )

        return new Promise(resolve => {

            canvas.toBlob((blob) => {
                resolve(new File([blob], "fileName.jpg", { type: "image/jpeg" }))
            }, 'image/jpeg')

        })
    }
}

const cropStore = new CropStore();

export default cropStore;
