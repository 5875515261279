import {observer} from "mobx-react";
import React from "react";
import InviteUI from "../../../Components/Inputs/Invite/InviteUI";

const RegStepInviteAdd = observer( () => {
    return <div className="main">

        <p className="title_greetings">Регистрация аккаунта</p>
        <p className="greetings mobile_greetings">КОД-ПРИГЛАШЕНИЕ</p>

        <div className="rect">
            <svg width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
        </div>

        <InviteUI/>


    </div>
    })

export default RegStepInviteAdd