import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {rThen} from "../../requestThen";
import {groupStore} from "../../../Components/Inputs/ChildGroup/ChildGroupStore";

export let updateGroupName = async (groupId) => {

    if(validationGroupName)
        return request.put(
            `/api/children_group/update/${groupId}/${authStore.userData.CreatedGartenId}`,
            {
                name: groupStore.GetGroup
            }
        ).then(r => {
            return rThen(r)
        })
}

let validationGroupName = () =>{
    groupStore.ValidGroup();
    return groupStore.GetGroup !== '';
}