import request from "../../Request";
import {rThen} from "../../requestThen";

export let checkChildInvite = async (inviteCode, childrenId) => {

    return request.post(
        `/api/childrens/check_invite/${childrenId}/${inviteCode}`,
        {},
        true
    ).then(r => {
        return rThen(r);
    })
}