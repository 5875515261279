import {action, makeAutoObservable, observable} from "mobx";

class EmailStore {
    Email = "";
    @observable EmailValid = "";
    @observable HelpText = "";
    pattern  = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(emailValid="", helpText=""){
        this.EmailValid = emailValid;
        this.HelpText = helpText;
    }

    emailKeyDown = (e) => {
        if(e.keyCode === 32)
            e.preventDefault()
    }

    SetEmail(e){
        this.Email = e.target.value;
        this.UserKeyDown();
    }

    ValidEmail(){
        if(!this.pattern.test(this.Email)){
            this.EmailValid = "is-invalid";
            if(window.location.href.substr(window.location.href.lastIndexOf('/')+1) === 'authorization'){
                if(this.Email === "")
                    this.HelpText = "Укажите имя аккаунта"
                else
                    this.HelpText = "Укажите корректное имя аккаунта"
            } else {
                if(this.Email === "")
                    this.HelpText = "Укажите желаемое имя аккаунта"
                else
                    this.HelpText = "Укажите корректное имя аккаунта"
            }
        } else {
            this.EmailValid = "";
            this.HelpText = ""
        }
    }

    get GetEmail(){
        this.ValidEmail();
        if(this.EmailValid === "is-invalid"){
            return "";
        } else {
            return this.Email;
        }
    }

    @action Clear(){
        this.Email = "";
        this.HelpText = "";
        this.EmailValid = "";
    }
}

export const emailStore = new EmailStore();