import {appConfig} from "../config";
import authStore from "../Stores/AuthStore/AuthStore";

class RequestClass {
    get = async (url, tokenFlag) => {

        let response = await fetch(`${appConfig.api_host}${url}`,{
            method: 'GET',
            mode: 'cors',
            headers: tokenFlag? {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`
            } : {
                'Content-Type': 'application/json;charset=utf-8'
            }
        });

        return await response.json().then(
            (response) => {
                if(response?.StatusCode === 500){
                    return null;
                } else {
                    return response;
                }

            },
            (error) =>{
                return null;
            }
        )
    }

    post = async (url, params, tokenFlag) => {
        let response = await fetch(`${appConfig.api_host}${url}`, {
            method: 'POST',
            mode: 'cors',
            headers: tokenFlag? {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`
            } : {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(params)
        });

        return await response.json().then(
            (response) => {
                if(response?.StatusCode === 500){
                    return null;
                }
                else{
                    return response;
                }

            },
            (error) =>{
                return null;
            }
        )
    }

    put = async (url, params, formData=null, tokeFlag=true) => {
        let response = await fetch(`${appConfig.api_host}${url}`, {
            method: 'PUT',
            mode: 'cors',
            headers: tokeFlag?
                formData === null? {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `Bearer ${await authStore.getact()}`
                } : {
                    'Authorization': `Bearer ${await authStore.getact()}`
                } : {'Content-Type': 'application/json;charset=utf-8'},
            body: formData === null? JSON.stringify(params) : formData
        });

        return await response.json().then(
            (response) => {
                if(response?.StatusCode === 500){
                    return null;
                } else {
                    return response;
                }

            },
            (error) =>{
                return null;
            }
        )
    }

    delete = async (url) => {
        let response = await fetch(`${appConfig.api_host}${url}`,{
            method: 'DELETE',
            mode: 'cors',
            headers:  {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`
            }
        });

        return await response.json().then(
            (response) => {
                if(response?.StatusCode === 500){
                    return null;
                } else {
                    return response;
                }
            },
            (error) =>{
                return null;
            }
        )
    }
}

const request = new RequestClass();

export default request;