import {makeAutoObservable, observable} from "mobx";
import {ageStore} from "../ageStore";
import dayStore from "./dayStore";
import yearStore from "./yearStore";

class MonthStore{
    @observable month = "Январь";
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceMonth(month){
        document.getElementById('dropdown_month_list').style.display = 'none';
        this.month = month;

        if(dayStore.day > ageStore.lastDayInMonth[ageStore.monthList.indexOf(this.month)]){
            if(ageStore.monthList.indexOf(this.month) === 1){
                if(yearStore.year % 4 === 0 && (yearStore.year % 100 !== 0 || yearStore.year % 400 === 0)) {
                    dayStore.day = ageStore.lastDayInMonth[ageStore.monthList.indexOf(this.month)];
                } else {
                    dayStore.day = 28;
                }
            } else {
                dayStore.day = ageStore.lastDayInMonth[ageStore.monthList.indexOf(this.month)];
            }
        }

        this.elem = document.activeElement.className;
        document.getElementById('dropdown_month').focus();
        // window.$('#dropdown_countr').dropdown('hide');
    }

    Blur(){
        ageStore.changeStyleBack();
        document.getElementById('dropdown_month_list').style.display = 'none';
    }
}

const monthStore = new MonthStore();

export default monthStore;