import {observer} from "mobx-react";
import React from "react";
import {TableGroup} from "../Components/Group/tableGroup";
import {Link} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";

export const GroupPage = observer(() => {

    return <>

        <p style={{margin: "90px 0 0 0", padding: 0}}>ГРУППЫ ДЕТСКОГО САДА</p>
        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
        </svg>

        <div className="row pt-30">
            <div className="col-md-12 col-sm-12">
                <div className="form_group">
                    <div className="form_row ml-20 mr-20 mb-5">

                        {authStore.userData.CreatedGartenId === 0?
                        <></>
                            :
                        <>
                            <Link to={'/groups/add'} style={{width: '100%'}} onClick={() => {}}>
                                <button id="select_education" className="buttons button_green"
                                        style={{width: '100%', margin: 0, padding: 0}}>СОЗДАТЬ ГРУППУ
                                </button>
                            </Link>
                        </>}

                    </div>
                </div>
            </div>
        </div>

        <TableGroup/>

    </>
})