import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import kindergardenStore from "../../../Modules/Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";

export let deleteChild = async (groupId, memberId) => {
    return request.delete(`/api/children_group/delete_child/${authStore.userData.CreatedGartenId === 0? authStore.decodeJwt.userId : authStore.userData.CreatedGartenId}/${groupId}/${memberId}`,).then(r => {
        //authStore.decodeJwt.userId
        //authStore.userData.CreatedGartenId
        return rThen(r);
    })
}