import {observer} from "mobx-react";
import reportStore from "../../../Stores/Report/ReportStore";

export const CircleDashboard = observer((props) => {

    return <>
        <div style={{width: '280px', height: '280px', margin: '0 auto', position: 'relative'}}>
            <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
                <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#E6E9ED"/>
                <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#E6E9ED"
                        strokeWidth="8"/>
                <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#A0D468"
                        strokeWidth="8" strokeDasharray={`
                        ${reportStore.percentGamesOnLevel === null? 0 : Math.round(reportStore.percentGamesOnLevel[0].percents * 100) / 100 * 100} 
                        ${reportStore.percentGamesOnLevel === null? 0 : 100-(Math.round(reportStore.percentGamesOnLevel[0].percents * 100) / 100 * 100)}
                        `}
                        strokeDashoffset="25"/>
            </svg>
            <span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <p style={{margin: 0, fontSize: '36px'}}>{reportStore.percentGamesOnLevel === null? 0 : parseInt(Math.round(reportStore.percentGamesOnLevel[0].percents * 100) / 100 * 100)}%</p>
                <p style={{margin: 0, fontSize: '12px'}}>{reportStore.declOfNum(reportStore.percentGamesOnLevel === null? "ВЫПОЛНЕНО" :Math.round(reportStore.percentGamesOnLevel[0].percents * 100) / 100 * 100, ['ВЫПОЛНЕН','ВЫПОЛНЕНО','ВЫПОЛНЕНО'])}</p>
            </span>
        </div>
    </>
})