import React from "react";
import { observer } from "mobx-react";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import teacherStore from './StoreProfile/teacherProfile'
import relatedAccsStore from "../../../../Stores/RelatedAccsStore/RelatedAccsStore";
import DropdownUIPosition from "../../../../Components/Inputs/Position/dropdownPosition";
import CountryUI from "../../../../Components/Inputs/Country/CountryUI";
import StateUI from "../../../../Components/Inputs/State/StateUI";
import CityUI from "../../../../Components/Inputs/City/cityUI";
import GenderUI from "../../../../Components/Inputs/gender/genderUI";
import NameUI from "../../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../../Components/Inputs/Surname/surnameUI";
import MiddleNameUI from "../../../../Components/Inputs/middleName/middleNameUI";
import parentStore from "./StoreProfile/parentProfile";

export const TeacherProfile = observer( (props) => {
    if(relatedAccsStore.ReletedAccount){
        teacherStore.getUserData();
        relatedAccsStore.SetRelatedAccountFlag()
    }

    return <>
        <form onChange={teacherStore.CheckForm} onKeyDown={(e) => teacherStore.keyDownForm(e)}>
            <div className="form_group pt-30">
                <div className="form_row mr-15 ml_account-15">
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                            <NameUI disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                            <SurnameUI disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                            <MiddleNameUI disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-5">
                            <GenderUI checkChange={teacherStore.CheckForm} disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-5">
                            <DropdownUIPosition checkChange={teacherStore.CheckForm} disabled={authStore.userData.Position.IsEditable? props.disabled: true}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mt-5 mb-5">
                            <CountryUI checkChange={parentStore.CheckForm} disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mt-5 mb-5">
                            <StateUI checkChange={parentStore.CheckForm} disabled={props.disabled}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="form_group mr-5 ml-5 mt-5 mb-5">
                            <CityUI disabled={props.disabled}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
})