import {observer} from "mobx-react";
import gardenStore from "../../../Stores/Garden/GardenStore";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import MiddleNameUI from "../../../Components/Inputs/middleName/middleNameUI";
import DropdownUIPosition from "../../../Components/Inputs/Position/dropdownPosition";
import React from "react";
import {useHistory} from "react-router-dom";

export const FormTeacher = observer(() => {
    let history = useHistory();

    return <>
    <form style={{width: '100%'}} onChange={() => gardenStore.CheckFormTeacherChange()} onKeyDown={(e) => gardenStore.keyDownFormTeacher(e,history)}>
        <div className="row pt-30">

            <div className="col-md-4 col-sm-12">
                <div className="form_group mr-5 ml-5 mb-5">
                    <NameUI/>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                    <SurnameUI/>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                    <MiddleNameUI/>
                </div>
            </div>
            <div className="col-md-12 col-sm-12">
                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                    <DropdownUIPosition checkChange={gardenStore.CheckFormTeacherChange} style={{width: "100%", display: "none", background: "#F5F7FA", marginLeft: '-50%'}}/>
                </div>
            </div>

            <div className="col-md-12 col-sm-12">
                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                    <button id="registration_button" className={`buttons button_blue ${gardenStore.disabledTeacherButton}`}
                            type="button" onClick={() => gardenStore.AddPedagog(history)} style={{width: '100%', margin: 0}}>Добавить</button>
                </div>
            </div>

        </div>
    </form>
    </>
})