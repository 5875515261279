import {makeAutoObservable, observable} from "mobx";

class LoadStore {

    @observable load = false;

    constructor() {
        makeAutoObservable(this);
    }


}

const loadStore = new LoadStore();

export default loadStore;