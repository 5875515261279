import {action, makeAutoObservable, observable, runInAction} from "mobx";
import VisiblPass from '../../../img/invisiblePass.png'
import InvisPass from '../../../img/visibilityPass.png'

class OldPasswordStore {
    password = "";
    @observable passwordValid = "";
    @observable HelpText = "";
    @observable inputType = 'password';
    @observable imgEye = VisiblPass
    @observable visibleFlag = true;

    @action eyeClcik = () => {
        if(this.visibleFlag){
            this.inputType = 'text';
            this.imgEye = InvisPass
        } else {
            this.inputType = 'password';
            this.imgEye = VisiblPass
        }
        this.visibleFlag = !this.visibleFlag;
    }

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(passwordValid="", HelpText=""){
        this.passwordValid = passwordValid;
        this.HelpText = HelpText;
    }

    SetPassword = (e) => {
        this.password = e.target.value;
        this.UserKeyDown();
    }

    ValidPassword(){
        if(this.password.indexOf(' ') !== -1){
            runInAction(() => {this.UserKeyDown('is-invalid', 'Пароль не должен содержать пробелы');})
        }else if(this.password.length >= 3){
            runInAction(() => {this.UserKeyDown();})
        } else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите пароль длинной более 3 символов');})
        }
    }

    get GetPassword(){
        this.ValidPassword();
        if(this.passwordValid === "is-invalid"){
            return "";
        } else {
            return this.password;
        }
    }

    @action Clear(){
        this.password = "";
        this.HelpText = "";
        this.passwordValid = "";
        this.visibleFlag = true;
        this.inputType = 'password';
        this.imgEye = VisiblPass;
    }
}

export const oldPasswordStore = new OldPasswordStore();