import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {LeftHeader} from "../../Structure/LeftHeader/leftHeader";
import authStore from "../../Stores/AuthStore/AuthStore";
import childGroupsStore from "../../Stores/ChildGroups/ChildGroupsStore";
import {Link, useHistory} from "react-router-dom";
import {GroupPage} from "./Page/GroupPage";
import {ChildrenPage} from "./Page/ChildrenPage";
import {RightHeader} from "../../Structure/RightHeader/rightHeader";

export const Groups = (() => {
    useEffect(() => {
        childGroupsStore.GetGroups();
    })
    return <Page/>
})

const Page = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if (authStore.userData.UserType !== 'Pedagog' || authStore.userData.Position.IsEditable) {
        history.push('/account');
        return null;
    }

    childGroupsStore.groupId = null;
    childGroupsStore.group = null;

    return <>

        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/groups'} className="blue_text">ГРУППЫ</Link>
            </p>

            {childGroupsStore.GardenPage?
                <GroupPage/>
                :
                <ChildrenPage/>
            }

        </div>
    </>
})