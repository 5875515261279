import {makeAutoObservable, action} from "mobx";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../../../Components/Inputs/gender/genderStore";
import {ageStore} from "../../../../../Components/Inputs/Age/ageStore";
import {telephoneStore} from "../../../../../Components/Inputs/Telephone/TelephoneStore";

class ChildStore {
    @action getUserData(){
        nameStore.Name = authStore.userData.Name;
        surnameStore.Surname = authStore.userData.Surname;
        genderStore.Gender = (authStore.userData.Sex? "Мужской" : "Женский");
        ageStore.Age = authStore.userData.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T"));
        telephoneStore.Telephone = authStore.masterAccountData.phone.slice(2);
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const childStore = new ChildStore();

export default childStore;
