import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {middleNameStore} from "./middleNameStore";

const MiddleNameUI = observer((props) => {
    return <>
        <input className={`${props.disabled? "input_test" : "input_test_change"} ${middleNameStore.middleNameValid}`} id="user_middleName" type="text" disabled={props.disabled} autoComplete={'off'}
               aria-describedby="emailHelp" placeholder="Отчество" value={middleNameStore.middleName} onChange={(e) => middleNameStore.SetmiddleName(e)}/>
        <HelpText text={middleNameStore.HelpText}/>
    </>
})

export default MiddleNameUI;