import {makeAutoObservable, observable} from "mobx"
import regStepStore from "./StepRegistration"
import {GetRelatedAccounts} from '../../Stores/RelatedAccsStore/Requests/GetRelatedAccounts'
import {appConfig} from "../../config"
import authStore from "../AuthStore/AuthStore"
import relatedAccsStore from "../RelatedAccsStore/RelatedAccsStore";
import modalStore from "../../Components/modal/modalStore";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import {cityStore} from "../../Components/Inputs/City/cityStore";
import countryStore from "../../Components/Inputs/Country/CountryStore";
import stateStore from "../../Components/Inputs/State/StateStore";
import {innStore} from "../../Components/Inputs/INN/innStore";
import {nameGardenStore} from "../../Components/Inputs/GardenName/nameGardenStore";
import {registrationButtonStore} from "../../Components/Buttons/RegistrationButton/RegistrationButtonStore";
import {inviteStore} from "../../Components/Inputs/Invite/InviteStore";
import jwt_decode from "jwt-decode";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../Components/Inputs/gender/genderStore";
import masterRegStore from "./MasterRegStore";
import {middleNameStore} from "../../Components/Inputs/middleName/middleNameStore";
import Notify from "../../Notifications/Notify";
import {childrenStore} from "../Children/Children";
import {ageStore} from "../../Components/Inputs/Age/ageStore";
import yearStore from "../../Components/Inputs/Age/ChildAgeStore/yearStore";
import monthStore from "../../Components/Inputs/Age/ChildAgeStore/monthStore";
import dayStore from "../../Components/Inputs/Age/ChildAgeStore/dayStore";

class RegTeacher {

    constructor() {
        makeAutoObservable(this);
        this.CheckUserDataTeacher = this.CheckUserDataTeacher.bind(this);
    }

    CheckForm = () => {
        if(cityStore.City !== '' && nameStore.Name !== '' && surnameStore.Surname !== '' && genderStore.Gender !== '' &&
        dropdownPositionStore.position !== '' && countryStore.country !== '' && stateStore.state !== ''){
            registrationButtonStore.SetDisabledButton()
        } else {
            registrationButtonStore.SetDisabledButton('disabled')
        }
    }

    async CheckUserDataTeacher(history=null){
        if(registrationButtonStore.disabledButton === ''){
            nameStore.ValidName();
            surnameStore.ValidSurname();
            middleNameStore.ValidmiddleName();
            cityStore.ValidCity();
            dropdownPositionStore.ValidPosition();
            countryStore.ValidCountry();
            stateStore.ValidState();
            genderStore.ValidGender();

            if(nameStore.GetName !== '' && surnameStore.GetSurname !== ''
                && cityStore.GetCity !== '' && typeof genderStore.getGender === 'boolean' &&
                dropdownPositionStore.getPosition !== '' && countryStore.getCountry !== '' && stateStore.getState !== ''){
                await this.RegTeacher(history);

            }else{
            }
        }
    }

    async RegTeacher(history=null){

        let response = await fetch(`${appConfig.api_host}/api/pedagogs`,{
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`,
                'ignoreId': `${authStore.isRegistration? Notify.connectionId : ''}`
            },
            body: JSON.stringify({
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                middleName: middleNameStore.GetmiddleName,
                country: countryStore.getCountry,
                state: stateStore.getState,
                city: cityStore.GetCity,
                sex: genderStore.getGender,
                organization: "",
                position: dropdownPositionStore.getPosition,
                invite: sessionStorage.getItem('invite') ===  null? '' : sessionStorage.getItem('invite')
            })
        });

        await response.json().then(
             async (response) =>{
                if(authStore.isRegistration){

                    if(sessionStorage.getItem('invite') !== null){
                        ChangeUserAfterReg();
                        modalStore.EndReg = true;
                        modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                        modalStore.ModalOpen();
                        inviteStore.InviteData = {};
                        inviteStore.InviteSend = '';

                    } else {
                        regStepStore.setStep("KindergartenQuestion")
                        masterRegStore.setStep('questKindegarten')
                        if(history !== null && window.location.href.indexOf('/registration/') !== -1){
                            history.push('/registration/question/kindegarten')
                        }
                    }

                } else {
                    GetRelatedAccounts(authStore.decodeJwt.userId,authStore.getAccessToken)
                    regStepStore.setStep('0');
                    modalStore.SetModalInfo("Педагог успешно зарегистрирован", "Успешно")
                    modalStore.simpleModalOpen();
                }
                 cityStore.Clear();
                 genderStore.Clear();
                 nameStore.Clear();
                 surnameStore.Clear();
                 dropdownPositionStore.Clear();
                 countryStore.Clear();
                 stateStore.Clear();

            },
            (error) =>{
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            });
    }


    keyDownForm = (e, history=null) => {
        if(registrationButtonStore.disabledButton === '' && e.code === "Enter"
        && countryStore.elem !== 'dropdown-item' &&
            stateStore.elem !== 'dropdown-item' &&
            genderStore.elem !== 'dropdown-item' &&
            dropdownPositionStore.elem !== 'dropdown-item'){
            this.CheckUserDataTeacher(history);
        }
        dropdownPositionStore.elem = ''
        stateStore.elem = ''
        countryStore.elem = ''
        genderStore.elem = ''
    }
}
export const regTeacher = new RegTeacher();


class RegParent {

    constructor() {
        makeAutoObservable(this);
        this.CheckUserDataParent = this.CheckUserDataParent.bind(this);
    }

    CheckForm = () => {
        if(cityStore.City !== '' && nameStore.Name !== '' && surnameStore.Surname !== '' &&
            countryStore.country !== '' && stateStore.state !== '' && genderStore.Gender !== ''){
            registrationButtonStore.SetDisabledButton()
        } else {
            registrationButtonStore.SetDisabledButton('disabled')
        }
    }

    async CheckUserDataParent(history=null){
        if(registrationButtonStore.disabledButton === ''){
            cityStore.ValidCity();
            countryStore.ValidCountry();
            stateStore.ValidState();
            nameStore.ValidName();
            surnameStore.ValidSurname();
            middleNameStore.ValidmiddleName();
            genderStore.ValidGender();

            if(nameStore.GetName !== '' && surnameStore.GetSurname !== '' && cityStore.GetCity !== '' &&
                countryStore.getCountry !== '' && stateStore.getState !== '' && typeof genderStore.getGender === 'boolean'){
                await this.RegParent(history);

            }else{
            }
        }
    }

    async RegParent(history=null){

        let response = await fetch(`${appConfig.api_host}/api/parents`,{
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`,
                'ignoreId': `${authStore.isRegistration? Notify.connectionId : ''}`
            },
            body: JSON.stringify({
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                middleName: middleNameStore.GetmiddleName,
                sex: genderStore.getGender,
                country: countryStore.getCountry,
                state: stateStore.getState,
                city: cityStore.GetCity
            })
        });

        await response.json().then(
            async (response) =>{

                if(authStore.isRegistration){
                    if(sessionStorage.getItem('invite') !== null){
                        nameStore.Name = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.Name;
                        surnameStore.Surname = JSON.parse(sessionStorage.getItem('inviteData')).GroupMember.Surname;

                        masterRegStore.setStep("children")
                        if(history !== null && window.location.href.indexOf('/registration/') !== -1){
                            history.push('/registration/children')
                        }
                        regStepStore.setStep("RegChild")
                    } else {
                        masterRegStore.setStep('questChild');
                        regStepStore.setStep("ChildQuestion")

                        nameStore.Clear();
                        surnameStore.Clear();

                        if(history !== null && window.location.href.indexOf('/registration/') !== -1){
                            history.push('/registration/question/children')
                        }
                    }
                    cityStore.Clear();
                    countryStore.Clear();
                    stateStore.Clear();
                    genderStore.Clear();
                } else {
                    GetRelatedAccounts(authStore.decodeJwt.userId,authStore.getAccessToken)
                    regStepStore.setStep('0');

                    nameStore.Clear();
                    surnameStore.Clear();

                    modalStore.SetModalInfo("Родитель успешно зарегистрирован", "Успешно")
                    modalStore.simpleModalOpen();
                }
                cityStore.Clear();
                countryStore.Clear();
                stateStore.Clear();
                genderStore.Clear();

            },
            (error) =>{
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            });
    }
    keyDownForm = (e, history=null) => {
        if(registrationButtonStore.disabledButton === '' && e.code === "Enter"
            && countryStore.elem !== 'dropdown-item' &&
            stateStore.elem !== 'dropdown-item' &&
            genderStore.elem !== 'dropdown-item'){
            this.CheckUserDataParent(history);
        }
        stateStore.elem = ''
        countryStore.elem = ''
        genderStore.elem = ''
    }
}
export const regParent = new RegParent();

class RegKindergarden {
    constructor() {
        makeAutoObservable(this);
        this.CheckKindergardenData = this.CheckKindergardenData.bind(this);
    }

    CheckForm = () => {
        if(innStore.INN !== '' && nameGardenStore.Garden !== '' ){
            registrationButtonStore.SetDisabledButton()
        } else {
            registrationButtonStore.SetDisabledButton('disabled')
        }
    }

    async CheckKindergardenData(){
        if(registrationButtonStore.disabledButton !== 'disabled'){
            innStore.ValidINN();
            nameGardenStore.ValidGarden();

            if(innStore.GetINN !== '' && nameGardenStore.GetGarden !== ''){
                await this.PostKindergarden();
            } else {
            }
        }
    }

    async PostKindergarden(){
        let response = await fetch(`${appConfig.api_host}/api/gartens`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`,
                'ignoreId': `${authStore.isRegistration? Notify.connectionId : ''}`
            },
            body: JSON.stringify({
                inn: innStore.GetINN,
                name: nameGardenStore.GetGarden
            })
        });

        response.json().then(
            (response) =>{
                if(response.type === "Data"){
                    if(authStore.isRegistration){
                        modalStore.EndReg = true;
                        ChangeUserAfterReg();
                        masterRegStore.setStep('invite');
                        modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                        modalStore.ModalOpen();

                    } else {
                        GetRelatedAccounts(authStore.decodeJwt.userId,authStore.getAccessToken)
                        regStepStore.setStep('0');

                        modalStore.SetModalInfo("Дет. сад успешно зарегистрирован", "Успешно")
                        modalStore.simpleModalOpen();
                    }
                    innStore.Clear();
                    nameGardenStore.Clear();
                    registrationButtonStore.Clear();
                } else{

                    modalStore.SetModalInfo(response.message, "Ошибка")
                    modalStore.simpleModalOpen();
                }
            },
            (error) =>{
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            });
    }

    keyDownForm = (e) => {
        if(registrationButtonStore.disabledButton === '' && e.code === "Enter"){
            this.CheckKindergardenData();
        }
    }
}
export const regKindergarden = new RegKindergarden();


class RegChild {

    constructor() {
        makeAutoObservable(this);
        this.CheckChildData = this.CheckChildData.bind(this);
    }

    CheckForm = () => {
        if(nameStore.Name !== '' && surnameStore.Surname !== '' && genderStore.Gender !== '' &&
        document.getElementById('date_before').style.display === 'none' &&
        document.getElementById('date_after').style.display === 'flex'){
            registrationButtonStore.SetDisabledButton()
        } else {
            registrationButtonStore.SetDisabledButton('disabled')
        }
    }

    async CheckChildData(history=null){
        if(registrationButtonStore.disabledButton !== 'disabled'){
            nameStore.ValidName();
            surnameStore.ValidSurname();
            genderStore.ValidGender();

            if(nameStore.GetName !== '' && surnameStore.GetSurname !== '' && typeof genderStore.getGender === 'boolean'){
                await this.PostChild(history)
            } else {
            }
        }
    }

    async PostChild(history=null){
        let response = await fetch(`${appConfig.api_host}/api/childrens/create/${authStore.decodeJwt.userId}`,{
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${await authStore.getact()}`,
                'ignoreId': `${authStore.isRegistration? Notify.connectionId : ''}`
            },
            body: JSON.stringify({
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                sex: genderStore.getGender,
                birthday: `${yearStore.year}-${String(ageStore.monthList.indexOf(monthStore.month)+1).length === 1? "0": ""}${ageStore.monthList.indexOf(monthStore.month)+1}-${String(dayStore.day).length === 1? "0" : ""}${dayStore.day}T00:00:00`,
                invite: sessionStorage.getItem('invite') ===  null? '' : sessionStorage.getItem('invite')
            })
        });

        response.json().then(
            (response) =>{
                if(response.type === "Data"){
                    if(authStore.isRegistration){
                        if(sessionStorage.getItem('invite') !== null){
                            modalStore.EndReg = true;
                            ChangeUserAfterReg();
                            modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                            modalStore.ModalOpen();
                            masterRegStore.setStep('invite');

                        } else {

                            modalStore.EndReg = true;
                            ChangeUserAfterReg();
                            modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                            modalStore.ModalOpen();
                            masterRegStore.setStep('invite');
                        }
                        GetRelatedAccounts(authStore.decodeJwt.userId,authStore.getAccessToken)
                    } else {
                        if(history !== null){
                            history.push('/children')
                            if(!Notify.authResult){
                                GetRelatedAccounts(authStore.decodeJwt.userId,authStore.getAccessToken)
                            }
                        }


                        regStepStore.setStep('0');
                        modalStore.SetModalInfo("", "ВЫ ДОБАВИЛИ РЕБЁНКА")
                        modalStore.simpleModalOpen();
                    }
                } else{
                    modalStore.SetModalInfo(response.message, "Ошибка")
                    modalStore.simpleModalOpen();
                    registrationButtonStore.Clear();
                }
                genderStore.Clear();
                nameStore.Clear();
                surnameStore.Clear();
                ageStore.Clear();
            },
            (error) =>{
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            });
    }

    keyDownForm = (e, history=null) => {
        if(e.code === "Enter"){e.preventDefault()}
        if(registrationButtonStore.disabledButton === '' && e.code === "Enter" && genderStore.elem !== 'dropdown-item'){
            this.CheckChildData(history);
        }
        genderStore.elem = ''
    }
}
export const regChild = new RegChild();

export function ChangeUserAfterReg(){
    let tempList = [];

    GetRelatedAccounts(jwt_decode(authStore.access).userId, authStore.access).then(
        r => {
            relatedAccsStore.relatedAccounts.map(user => tempList.push(user.UserId));

            authStore.ChangeRelatedAccount(Math.min.apply(null, tempList));
        }
    );
}
