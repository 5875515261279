import {observer} from "mobx-react";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import authStore from "../../../Stores/AuthStore/AuthStore";
import React from "react";
import InviteInput from "../../../Components/Inputs/InviteCodeInput/InviteInput";
import InviteSendButton from "../../../Components/Buttons/inviteButton/InviteSendButton";
import inviteSendStore from "../../../Components/Buttons/inviteButton/InviteSendStore";
import {Link, useHistory} from "react-router-dom";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";

export const TeacherInvite = observer( () => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType !== "Pedagog"){
        history.push('/account')
    }

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/account/teacher/invite'} className="blue_text">
                    <span className="invite_mobile">ИНВАЙТ-КОД</span>
                    <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                </Link>
            </p>
            <p className="greetings">
                <span className="invite_mobile">ИНВАЙТ-КОД</span>
                <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
            </p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
            <form onChange={() => inviteSendStore.FormChange()} onKeyDown={inviteSendStore.KeyDown}>
            <div className="row">
                <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                    <div className="row pt-30" style={{width: '100%'}}>

                        <div className="col-md-12 col-sm-12">
                            <div className="form_group ml-md-5 mb-10" >
                                <InviteInput />
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                            <div className="form_group ml-md-5 mt-10 mb-10">
                                <InviteSendButton />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </form>
        </div>
    </>
})