import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import childDropdownStore from "./ChildDropdownStore";
import '../../../styles/dropdown.css'

export const DropdownChildUI = observer( (props) => {

    function onDropDownChild() {
        document.getElementById('dropdown_children_list').style.display = 'inline';
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const childDropdownList = (elem, index) => {
        return <button key={"child_dropdown_elem_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {childDropdownStore.ChoiceChild(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){childDropdownStore.ChoiceChild(elem); props.checkChange()}}}>

            <div style={{width: "100%"}}>
                <p className={'dropdown_element'}>{elem.Surname + ' ' +  elem.Name}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
        <input className={`input_test_change dropdown_img_active ${childDropdownStore.validChild}`} id="dropdown_child" autoComplete="off"
               disabled={props.disabled} placeholder={"Выберите ребёнка"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={childDropdownStore.child}
               onFocus={() => childDropdownStore.FocusChild()}
               onBlur={() => childDropdownStore.Blur()}
               onClick={() => {onDropDownChild();}}/>

        <div id="dropdown_children_list" className="dropdown_col_12" style={{width: "100%", display: "none", background: "#F5F7FA", overflowY: "scroll", minHeight: "2em",  maxHeight: "10em"}}>
            {childDropdownStore.returnChildList.map((elem, index) => childDropdownList(elem, index))}
        </div>
        <HelpText text={childDropdownStore.HelpText}/>
    </div>
})