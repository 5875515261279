import { makeAutoObservable } from 'mobx'
import {activationAccount} from "../../Request/api/User/activationAccount";
import modalStore from "../../Components/modal/modalStore";

class NotificationAccountStore {

    constructor() {
        makeAutoObservable(this);
    }

    async activateAccount(){
        await activationAccount().then(r => {
            if(r !== null && r.type === 'Data'){
                modalStore.SetModalInfo(<span>Для завершения регистрации необходимо перейти по ссылке из письма</span>, "ПОДТВЕРЖДЕНИЕ") // style={{textAlign: "center"}}
                modalStore.simpleModalOpen();
            } else {
                modalStore.SetModalInfo("Письмо уже было отправлено", "ОШИБКА")
                modalStore.simpleModalOpen();
            }
        })
    }
}

const notificationAccountStore = new NotificationAccountStore();

export default notificationAccountStore;