import {observer} from "mobx-react";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";
import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import gardenStore from "../../../Stores/Garden/GardenStore";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import MiddleNameUI from "../../../Components/Inputs/middleName/middleNameUI";
import DropdownUIPosition from "../../../Components/Inputs/Position/dropdownPosition";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../../Components/Inputs/middleName/middleNameStore";
import dropdownPositionStore from "../../../Components/Inputs/Position/dropdownPositionStore";


export const ChangeTeacher = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.userData.UserType !== 'Pedagog' || authStore.userData.CreatedGartenId === 0){
        history.push('/account');
        return null;
    }

    if(gardenStore.teacher === null){
        history.push('/kindergarten')
        return null
    }

    useEffect(() => {
        nameStore.Name = gardenStore.teacher.Name;
        surnameStore.Surname = gardenStore.teacher.Surname;
        middleNameStore.middleName = gardenStore.teacher.MiddleName;
        dropdownPositionStore.position = gardenStore.teacher.Position.PositionName;
        dropdownPositionStore.positionId = gardenStore.teacher.Position.PositionId;
        gardenStore.CheckFormTeacherChangeUser();
    })

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/kindergarten'} className="grey_text">ДЕТСКИЙ САД/</Link>
                <Link to={'/kindergarten/teacher/change'} className="blue_text">ИЗМЕНИТЬ ПЕДАГОГА</Link>
            </p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ ПЕДАГОГА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <TeacherPosForm disabled={!(gardenStore.teacher.User === null)}
                    code={<>

                        <div className="col-md-4 col-sm-12">
                            <div className="form_group mr-5 ml-5 mb-5">
                                <NameUI disabled={!(gardenStore.teacher.User === null)}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                <SurnameUI disabled={!(gardenStore.teacher.User === null)}/>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                <MiddleNameUI disabled={!(gardenStore.teacher.User === null)}/>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                <DropdownUIPosition checkChange={gardenStore.CheckFormTeacherChangeUser} style={{width: "100%", display: "none", background: "#F5F7FA", marginLeft: '-50%'}}/>
                            </div>
                        </div>

                    </>}/>
                </div>
            </div>
        </div>
    </>

})

const TeacherPosForm = observer((props) => {
    let history = useHistory();

    if(props.disabled){
        return <>
            <form style={{width: '100%'}} onChange={() => gardenStore.CheckFormTeacherChangeUser()}>
                <div className="row pt-30">
                    {props.code}
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <button id="registration_button" className={`buttons button_blue ${gardenStore.disabledTeacherButton}`}
                                type="button" onClick={() => gardenStore.UpdateTeacherPosition(history)} style={{width: '100%', margin: 0}}>ИЗМЕНИТЬ</button>
                    </div>
                </div>
            </form>
        </>
    } else {
        return <>
            <form style={{width: '100%'}} onChange={() => gardenStore.CheckFormTeacherChangeUser()} onKeyDown={(e) => gardenStore.keyDownFormTeacher(e)}>
                <div className="row pt-30">
                    {props.code}
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <button id="registration_button" className={`buttons button_blue ${gardenStore.disabledTeacherButton}`}
                                type="button" onClick={() => gardenStore.ChangePedagog(history)} style={{width: '100%', margin: 0}}>ИЗМЕНИТЬ</button>
                    </div>
                </div>
            </form>
        </>
    }

})