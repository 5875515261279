import React from "react";
import { observer } from "mobx-react";
import notificationAccountStore from "../../../Stores/account/notificationAccount";
import authStore from "../../../Stores/AuthStore/AuthStore";
import 'react-circular-progressbar/dist/styles.css';
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";


export const NotificationsAccount = observer( () => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType === "Children"){
        history.push('/account')
    }

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">
                    {authStore.userData.UserType === "Parent"? "АККАУНТ РОДИТЕЛЯ/" : ""}
                    {authStore.userData.UserType === "Pedagog"? "АККАУНТ ПЕДАГОГА/" : ""}
                </Link>
                <Link to={'/account/notification'} className="blue_text">УВЕДОМЛЕНИЯ</Link>
            </p>
            <p className="greetings pt-sm-60">
                {authStore.decodeJwt.email_active? "ПОЧТА УСПЕШНО ПОДТВЕРЖДЕНА"
                :
                "ПОДТВЕРЖДЕНИЕ ПОЧТЫ"}
            </p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                    <div className="row" style={{width: '100%'}}>

                        <div className="col-md-12 col-sm-12">
                            <div className="form_group pt-30" style={{display: "flex", justifyContent: "space-around"}}>
                                <p>{authStore.decodeJwt.email}</p>
                            </div>
                        </div>

                        {!authStore.decodeJwt.email_active?
                        <div className="col-md-12 col-sm-12">
                            <div className="form_group pt-30 ml-md-5 mt-md-10 mb-md-10">
                                <button style={{width: '100%', margin: 0}} id="registration_button" className="buttons button_blue" type="button" disabled={authStore.decodeJwt.email_active} onClick={() => {notificationAccountStore.activateAccount()}}>Подтвердить почту</button>
                            </div>
                        </div> : <></>}

                    </div>
                </div>
            </div>
        </div>
    </>
})

