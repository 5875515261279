import {makeAutoObservable, observable} from "mobx";

class MasterRegStore {
    @observable pageSwapBool = false;

    constructor() {
        makeAutoObservable(this);
    }

    setStep(step){
        localStorage.setItem('step', step);
    }
}

const masterRegStore = new MasterRegStore();

export default masterRegStore;