import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {rThen} from "../../requestThen";

export let addChildInGroup = async (kindegartenId=null, groupId) => {

    if(validationUpdateChildren)
        return request.post(
            `/api/children_group/add_child/${kindegartenId === null? authStore.decodeJwt.userId : kindegartenId}/${groupId}`,
            {
                name: nameStore.GetName,
                surname: surnameStore.GetSurname
            },
            true
        ).then(r => {
            return rThen(r);
        })
}

let validationUpdateChildren = () =>{
    nameStore.ValidName();
    surnameStore.ValidSurname();

    return nameStore.GetName !== '' && surnameStore.GetSurname !== '';
}