import request from "../../Request";
import {rThen} from "../../requestThen";
import {emailStore} from "../../../Components/Inputs/Email/EmailStore";

export let restorePasswordPost = async () => {

    return request.post(
        `/api/User/restore_password/email/send`,
        {
            email: emailStore.GetEmail
        },
        false
    ).then(r => {
        return rThen(r);
    })
}