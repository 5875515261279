import {observer} from "mobx-react";
import reportStore, {Base64} from "../../../Stores/Report/ReportStore";

export const GameTimeDashboardPhone = observer((props) => {
    return <div style={{width: '100%'}}>

        {reportStore.gamesTime.map(game => <>

            <div className={"row"} style={{width: "100%"}}>
                <div className={"col-sm-8"} style={{display: "flex", flexDirection: 'row'}}>
                    <div style={{minWidth: '18px', height: '18px', borderRadius: '50%', background: game.color, margin: 'auto 8px auto 0'}}/>
                    <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>{Base64.decode(game.LevelName)}</p>
                </div>

                <div className={"col-sm-4"}>
                    <p className="game_text" style={{margin: '18px 0', textAlign: 'right'}}>
                        {game.Seconds / 60 >= 1? `${Math.floor(game.Seconds/60)}м.${game.Seconds - Math.floor(game.Seconds/60) * 60}с.` : `${game.Seconds}с.`}
                    </p>
                </div>
            </div>

        </>)}
    </div>
})