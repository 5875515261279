import relatedAccsStore from '../RelatedAccsStore'
import {appConfig} from "../../../config"
import modalStore from "../../../Components/modal/modalStore";

export async function GetRelatedAccounts(userId, accessToken) {
  const url = `${appConfig.api_host}/api/base_user/related/${userId}`
  let response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': `Bearer ${accessToken}`,
    }
  });
  await response.json().then(
    (response) =>{
      if(response.type === "Data"){
        relatedAccsStore.setRelatedAccounts(response.data)
      }
      else
      {
      }
    },
    (error) =>{
        modalStore.SetModalInfo("Произошла ошибка при получении связанных аккаунтов", "Ошибка")
        modalStore.simpleModalOpen();
    }
  )
}
