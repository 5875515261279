import {observer} from "mobx-react";
import reportStore, {Base64} from "../../../Stores/Report/ReportStore";

export const ProgressDashboardPhone = observer((props) => {
    return <div style={{posotion: 'relative', width: '100%'}}>
        {reportStore.percentsByGame.map((game, i) => <>
            <div>
                <div style={{display: "flex", flexDirection: "column", position: 'relative'}}>
                    <div style={{padding: '6px 0'}}/>
                    <div style={{display: "flex", flexDirection: 'row'}}>
                        <div style={{position: "relative", width: '100%', height: '36px', background: "#E6E9ED", display: "flex", alignItems: "flex-end", borderLeft: '2px solid rgba(67, 74, 84, 0.12)'}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', background: "#A0D468", height: '100%', width: `${game.Percent * 100}%`}}>

                            </div>
                            <p style={{margin: 'auto 0', position: "absolute", color: '#434A54', top:'25%', right: '5%'}}>{Math.round(game.Percent * 100)}</p>
                            <p style={{margin: 'auto 0', position: "absolute", color: '#434A54', top:'25%', left: '5%'}}>{Base64.decode(game.LevelName)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>)}
    </div>
})