import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import {oldPasswordStore} from "../../../Components/Inputs/Password/OldPasswordStore";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";

export let updatePassword = async () => {

    return request.put(
        `/api/User/update_password/${authStore.decodeJwt.userId}`,
        {
            oldPassword: oldPasswordStore.GetPassword,
            newPassword: newPasswordStore.GetPassword
        }
    ).then(r => {
        return rThen(r);
    })
}