import {observer} from "mobx-react";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import React from "react";
import {Link} from "react-router-dom";
import authStore from "../../../../Stores/AuthStore/AuthStore";

export const TableGroup = observer(() => {

    return <>
        <table className="table mr-20 ml-20 mt-5">
            <thead style={{width: '100%'}}>
            <tr className="table_header">
                <th className="col-6">Название группы</th>
                <th className="col-6">ФИО педагога</th>
            </tr>
            </thead>
            <tbody>
            {childGroupsStore.Groups.map(group =>
                <>
                    {childGroupsStore.groupId === group.GroupId?
                        <>
                            <tr className="table_main" style={{background: "#E6E9ED", color: "black"}} onClick={() => {childGroupsStore.closeButtonsGroup()}}>
                                <td>{group.Name}</td>
                                <td>{group.Pedagog.Surname + ' ' + group.Pedagog.Name + ' ' + group.Pedagog.MiddleName}</td>
                            </tr>
                            <tr className="table_main" style={{background: "#E6E9ED", color: "black"}}>
                                <td colSpan={3}>
                                    <div className="row">
                                        <div className="form_row">
                                            <div className={authStore.userData.CreatedGartenId === 0? "col-lg-6 col-md-6 col-sm-6" : "col-lg-4 col-md-4 col-sm-4"}>
                                                <div className="form_group mr-5 ml-5 mb-5">
                                                    <Link to={'/groups/change'} style={{width: '100%', display: 'flex', textDecoration: 'none'}}>
                                                        <button id="select_education" className="buttons button_green"
                                                                style={{width: '100%', margin: 0, padding: 0}}>ИЗМЕНИТЬ ДАННЫЕ <span className="sm_none"> ГРУППЫ</span>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>


                                            {authStore.userData.CreatedGartenId === 0 ? <></> :
                                                <div className="col-lg-4 col-md-4 col-sm-4">
                                                    <div className="form_group mr-5 ml-5 mb-5">
                                                        <button onClick={() => childGroupsStore.deleteGroup()}
                                                                className="buttons button_blue" style={{
                                                            width: '100%',
                                                            margin: 0,
                                                            padding: 0,
                                                            background: "#4FC1E9"
                                                        }}>
                                                            УДАЛИТЬ ГРУППУ
                                                        </button>
                                                    </div>
                                                </div>
                                            }

                                            <div className={authStore.userData.CreatedGartenId === 0? "col-lg-6 col-md-6 col-sm-6" : "col-lg-4 col-md-4 col-sm-4"}>
                                                <div className="form_group mr-5 ml-5 mb-5">
                                                    <button onClick={(e) => childGroupsStore.PageSwap(e)} id="select_education" className="buttons button_yellow" style={{width: '100%', margin: 0, padding: 0, background: "#FFCE54"}}>
                                                        ПЕРЕЙТИ В ГРУППУ
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </>
                        :
                        <tr className="table_main" onClick={() => {childGroupsStore.SelectGroup(group)}}>
                            <td>{group.Name}</td>
                            <td>{group.Pedagog.Surname + ' ' + group.Pedagog.Name + ' ' + group.Pedagog.MiddleName}</td>
                        </tr>
                    }
                </>
            )}
            </tbody>
        </table>
    </>
})