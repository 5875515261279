import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import {telephoneStore} from "../../../Components/Inputs/Telephone/TelephoneStore";

export let updateTelephone = async () => {

    return request.put(
        `/api/User/update_phone_number`,
        {
            phone: telephoneStore.GetTelephone
        }
    ).then(r => {
        return rThen(r);
    })
}