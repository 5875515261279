import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import kindergardenStore from "../../../Modules/Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";

export let deleteGroup = async (groupId) => {

    return request.delete(`/api/children_group/delete/${authStore.userData.CreatedGartenId}/${groupId}`,).then(r => {
        return rThen(r);
    })
}