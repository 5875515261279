import {observer} from "mobx-react";
import React from "react";
import HelpText from "../../HelpText";
import yearStore from "../ChildAgeStore/yearStore";
import {ageStore} from "../ageStore";

const YearUI = observer( (props) => {

    function onDropDownCountry() {
        document.getElementById('dropdown_year_list').style.display = 'inline';
        // ageStore.changeStyle();
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const yearList = (elem, index) => {
        return <button key={"year_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {yearStore.ChoiceYear(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){yearStore.ChoiceYear(elem); props.checkChange()}}}>

            <div style={{width: "100%"}}>
                <p className={'dropdown_element'}>{elem}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
        <input className={`${props.disabled? "input_test" : "date_input_change_last dropdown_img_active"}`} id="dropdown_year" autoComplete="off"
               disabled={props.disabled} placeholder={"Выберите год"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={yearStore.year}
               onFocus={() => yearStore.FocusYear()}
               onBlur={() => yearStore.Blur()}
               onClick={() => {onDropDownCountry()}}/>

        <div className="dropdown_col_4" id="dropdown_year_list" style={{width: "100%", display: "none", background: "#F5F7FA", overflowY: "scroll", maxHeight: '10em'}}>
            {yearStore.returnYearList.map((elem, index) => yearList(elem, index))}
        </div>

        <HelpText text={yearStore.HelpText}/>
    </div>
})

export default YearUI;