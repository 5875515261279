import placeStore from "../../../Stores/Registration/place";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import stateStore from "../State/StateStore";

class CountryStore{
    @observable returnCountryList = [];
    @observable country = "Россия";
    @observable validCountry = "";
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceCounty(country){
        document.getElementById('dropdown_countr_list').style.display = 'none';
        this.country = country;
        stateStore.SetTextState("");
        this.UserKeyDown();
        this.elem = document.activeElement.className;
        document.getElementById('dropdown_countr').focus();
        // window.$('#dropdown_countr').dropdown('hide');
    }

    FocusCountry(){
        placeStore.GetCountry();
        this.returnCountryList = placeStore.country
    }

    setCountry(e){
        this.UserKeyDown();
    }

    ValidCountry(){
        if(placeStore.country.indexOf(this.country) !== -1){
            runInAction(() => {this.UserKeyDown();})
        } else {
            runInAction(() => {this.UserKeyDown("is-invalid", "Укажите страну")})
        }
    }

    get getCountry(){
        this.ValidCountry();
        if(this.validCountry === ""){
            return this.country
        } else {
            return ""
        }
    }

    @action Clear(){
        this.country = "Россия";
        this.validCountry = "";
        this.HelpText = "";
        this.elem = "";
    }

    @action UserKeyDown(validCountry="", helpText=""){
        this.validCountry = validCountry;
        this.HelpText = helpText;
    }

    Blur(){
        document.getElementById('dropdown_countr_list').style.display = 'none';
    }
}

const countryStore = new CountryStore();

export default countryStore;