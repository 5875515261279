import { observable, makeAutoObservable } from "mobx"
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import {oldPasswordStore} from "../../Components/Inputs/Password/OldPasswordStore";
import {confirmPasswordStore} from "../../Components/Inputs/Password/ConfirmPasswordStore";
import {restorePasswordPut} from "../../Request/api/User/restorePasswordPut";
import modalStore from "../../Components/modal/modalStore";

class Restore {
    @observable message = "";
    cid = new URLSearchParams(window.location.search).get("cid");
    key = new URLSearchParams(window.location.search).get("key");

    constructor() {
        makeAutoObservable(this);
    }

    async checkRestoreData(){
        newPasswordStore.ValidPassword();
        confirmPasswordStore.ValidPassword();

        if(newPasswordStore.GetPassword !== "" && confirmPasswordStore.GetPassword !== ""){
            await this.UpdatePassword();
        }
    }

    async UpdatePassword(){
        restorePasswordPut(this.cid, this.key).then(r => {
            if(r !== null && r.type === "Data"){
                modalStore.SetModalInfo("", "ПАРОЛЬ УСПЕШНО ОБНОВЛЕН")
                modalStore.simpleModalOpen();
                newPasswordStore.Clear();
                oldPasswordStore.Clear();
                confirmPasswordStore.Clear();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Обишка")
                modalStore.simpleModalOpen();
            }
        })
    }
}

const restore = new Restore();

export default restore;