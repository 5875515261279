import {observable, action, makeAutoObservable, runInAction} from "mobx"

class InviteInputStore {
    @observable invite = ""
    @observable inviteValid = ""
    @observable HelpText = ""

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(inviteValid="", helpText=""){
        this.inviteValid = inviteValid;
        this.HelpText = helpText;
    }

    ValidInvite() {
        if (this.invite.length >= 4) {
            runInAction(() => {this.UserKeyDown();})
        } else {
            runInAction(() => {this.UserKeyDown("is-invalid", "Указан не верный код-приглашение");})
        }
    }

    get GetInvite(){
        this.ValidInvite();
        if(this.inviteValid === "is-invalid"){
            return "";
        } else {
            return this.invite;
        }
    }

    @action SetNewValue(e) {
        this.UserKeyDown();
        this.invite = e.target.value
    }

    @action Clear(){
        this.invite = "";
        this.inviteValid = "";
        this.HelpText = "";
    }
}

const inviteInputStore = new InviteInputStore()

export default inviteInputStore