import {observer} from "mobx-react";
import React from "react";

export const MiddleTextAndSvg = observer((props) => {
    return <>
        <div style={{display: "flex", width: '100%', flexDirection: "column"}}>
            <p style={{textAlign: "center"}} >{props.text}</p>
            <svg style={{margin: '0 auto'}} width="76" height="4" viewBox="0 0 76 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="76" height="4" rx="2" fill="#4FC1E9"/>
            </svg>
        </div>
    </>
})