import {action, makeAutoObservable, observable} from "mobx";
import modalStore from "../../Components/modal/modalStore";
import React from "react";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../Components/Inputs/middleName/middleNameStore";
import {getPedagogList} from "../../Request/api/Gartens/getPedagogList";
import {createPedagogInvite} from "../../Request/api/Gartens/createPedagogInvite";
import {deletePedagog} from "../../Request/api/Gartens/deletePedagog";
import {updatePedagogPosition} from "../../Request/api/Gartens/updatePedagogPosition";
import {addGartenPedagog} from "../../Request/api/Gartens/addGartenPedagog";
import authStore from "../AuthStore/AuthStore";
import {updateKindegartenPedagog} from "../../Request/api/ChildrenGroups/ChangePedagog";

class GardenStore {
    @observable offset = 0;
    count = 4;
    @observable Teachers = [];
    @observable disabledTeacherButton = 'disabled';
    PutMemberId = null;
    kindegartenId = null;
    changeFlag = false;
    teacherId = null;
    teacher = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action async GetTeachers(){

        if(authStore.isAuth){
            this.kindegartenId = authStore.userData.CreatedGartenId === 0? null : authStore.userData.CreatedGartenId;

            this.Teachers = await getPedagogList(this.kindegartenId,0,100).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                    return [];
                }
            })
        }
    }

    @action GetInviteCode() {
        createPedagogInvite(this.kindegartenId, this.teacher.MemberId).then(r => {
            if(r !== null && r.type === 'Data'){

                modalStore.SetModalInfo(
                    <p className={"modal_invite_p simple_modal_component"}>Передайте этот код педагогу:<br/> <span className={"modal_invite_span simple_modal_component"}>{r.data.key}</span> <br/> Код действителен до {r.data.ExpiredDate.replace("T", " ").substr(0, r.data.ExpiredDate.lastIndexOf('.'))}</p>
                    , <>
                        <span className="invite_mobile">ИНВАЙТ-КОД</span>
                        <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                    </>)
                modalStore.simpleModalOpen();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    async deletePedagog(){
        const promiseToModal = new Promise((resolve) => {
            setTimeout(() => {resolve();}, 0);
        }).then(r => {
            modalStore.SetModalInfo('', "УДАЛИТЬ ПЕДАГОГА?")
            modalStore.modalCode = true;
            modalStore.simpleModalOpen();
        })
    }

    deletePedagogOk = () => {
        deletePedagog(this.kindegartenId, this.teacher.MemberId).then(r => {
            if(r !== null && r.type === 'Data'){
                this.GetTeachers();
                modalStore.SetModalInfo("", "ВЫ УСПЕШНО УДАЛИЛИ ПЕДАГОГА")
                modalStore.simpleModalOpen();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    UpdateTeacherPosition(history){
        if(dropdownPositionStore.getPosition !== "")
            updatePedagogPosition(this.kindegartenId, this.teacher.MemberId).then(r => {
                if(r !== null && r.type === 'Data'){
                    this.PutMemberId = null;
                    dropdownPositionStore.Clear();
                    modalStore.SetModalInfo("", "ПЕДАГОГ ИЗМЕНЕН")
                    modalStore.simpleModalOpen();
                    history.push('/kindergarten');
                    this.closeButtons();
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
    }

    ChangePedagog = (history) => {
        updateKindegartenPedagog(this.teacher.MemberId).then(r => {
            if(r !== null && r.type === 'Data'){
                this.PutMemberId = null;
                dropdownPositionStore.Clear();
                modalStore.SetModalInfo("", "ПЕДАГОГ ИЗМЕНЕН")
                modalStore.simpleModalOpen();
                history.push('/kindergarten');
                this.closeButtons();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    CheckFormTeacherChange = () => {
        if(nameStore.Name !== '' && surnameStore.Surname !== '' && dropdownPositionStore.position !== ''){
            this.disabledTeacherButton = ''
        } else {
            this.disabledTeacherButton = 'disabled'
        }
    }

    CheckFormTeacherChangeUser = () => {
        if(nameStore.Name !== '' && surnameStore.Surname !== '' && dropdownPositionStore.position !== '' &&
            (nameStore.Name !== this.teacher.Name || surnameStore.Surname !== this.teacher.Surname ||
            dropdownPositionStore.position !== this.teacher.Position.PositionName || middleNameStore.middleName !== this.teacher.MiddleName)){
            this.disabledTeacherButton = ''
        } else {
            this.disabledTeacherButton = 'disabled'
        }
    }

    keyDownFormTeacher = (e, history) => {
        if(this.disabledTeacherButton === '' && e.code === "Enter" && document.activeElement.tagName !== "BUTTON" && dropdownPositionStore.elem !== 'dropdown-item'){
            this.AddPedagog(history);
        }
    }

    ChangeTd(teacher){
        if(!teacher.IsDefault){
            this.changeFlag = true;
            this.teacher = teacher;
            this.teacherId = teacher.MemberId;
        }
    }


    closeButtons(){
        this.changeFlag = false;
        this.teacher = null;
        this.teacherId = null;
    }

    async AddPedagog(history){
        if(gardenStore.disabledTeacherButton === '' && authStore.isAuth)
            this.kindegartenId = authStore.userData.CreatedGartenId === 0? null : authStore.userData.CreatedGartenId;
            addGartenPedagog(this.kindegartenId).then(r => {
                if(r !== null && r.type === 'Data'){
                    this.GetTeachers();
                    modalStore.SetModalInfo("", "ВЫ ДОБАВИЛИ ПЕДАГОГА")
                    modalStore.simpleModalOpen();
                    nameStore.Clear();
                    surnameStore.Clear();
                    middleNameStore.Clear();
                    dropdownPositionStore.Clear();
                    this.disabledTeacherButton = 'disabled';
                    history.push('/kindergarten');
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
    }
}

const gardenStore = new GardenStore();

export default gardenStore;