import {makeAutoObservable, observable} from "mobx";
import monthStore from "./monthStore";
import {ageStore} from "../ageStore";
import yearStore from "./yearStore";

class DayStore{
    @observable returnDayList = [];
    @observable day = 1;
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceDay(day){
        document.getElementById('dropdown_day_list').style.display = 'none';
        this.day = day;
        this.elem = document.activeElement.className;
        document.getElementById('dropdown_day').focus();
    }

    FocusDay(){
        ageStore.changeStyle();
        this.returnDayList = [];

        if( ageStore.monthList.indexOf(monthStore.month) === 1){
            if (yearStore.year % 4 === 0 && (yearStore.year % 100 !== 0 || yearStore.year % 400 === 0)) {
                for(let i=0; i < ageStore.lastDayInMonth[ageStore.monthList.indexOf(monthStore.month)]; i++){
                    this.returnDayList.push(i+1);
                }
            } else {
                for(let i=0; i < 28; i++){
                    this.returnDayList.push(i+1);
                }
            }
        } else {
            for(let i=0; i < ageStore.lastDayInMonth[ageStore.monthList.indexOf(monthStore.month)]; i++){
                this.returnDayList.push(i+1);
            }
        }
    }

    Blur(){
        ageStore.changeStyleBack();
        document.getElementById('dropdown_day_list').style.display = 'none';
    }
}

const dayStore = new DayStore();

export default dayStore;