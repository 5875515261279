import React from 'react'
import authStore from '../../../Stores/AuthStore/AuthStore'
import { observer } from "mobx-react"
import PasswordUI from "../../../Components/Inputs/Password/PasswordUI";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";
import EmailUI from "../../../Components/Inputs/Email/EmailUI";
import '../linkStyle.css'
import {Link} from "react-router-dom";

const AuthForm = observer ((props) => {
    authStore.setIsRegistrationFalse();



  return <>

      <div className="row">
        <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
          <div className="row pt-30" style={{width: '100%'}}>

            <div className="col-md-6 col-sm-12">
              <div className="form_group mr-md-5 mb-sm-10" style={{display: "flex", justifyContent: "space-around"}}>
                  <EmailUI disabled={false} styleDisplay={'none'}/>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form_group ml-md-5 mb-md-10 mb-sm-10">

                  <PasswordUI text={"Пароль"} valid={newPasswordStore.passwordValid}
                              placeholder={"Введите пароль"} password={newPasswordStore.password}
                              func={newPasswordStore.SetPassword} HelpText={newPasswordStore.HelpText}
                              src={newPasswordStore.imgEye} type={newPasswordStore.inputType} eyeClcik={newPasswordStore.eyeClcik}
                              styleDisplay={'none'} disabled={false}
                  />

              </div>
            </div>

              <div className="col-md-6 col-sm-12">
                  <div className="form_group ml-md-5 mb-md-10 mt-md-10 mb-sm-10">
                      <div>
                          <input type="checkbox" id="forgot_pass" onClick={authStore.setSavePassword}/>
                          <label className="label_text" htmlFor="forgot_pass" style={{paddingLeft: '15px'}}>Запомнить пароль</label>
                      </div>
                  </div>
              </div>
              <div className="col-md-6 col-sm-12">
                  <div className="form_group ml-md-5 mb-md-10 mt-md-10 link_flex mb-sm-10">
                      <div className="">
                          <Link className="link link_pass" to='/restore'>Забыли пароль?</Link>
                      </div>
                  </div>
              </div>


              <div className="col-md-12 col-sm-12">
                  <div className="form_group mb-md-10 mt-md-10 mb-sm-10">
                      <button type='button' disabled={authStore.isLoading} id="registration_button" style={{width: '100%', margin: 0}} className="buttons button_blue" onClick={authStore.Auth}>Войти</button>
                  </div>
              </div>

          </div>
        </div>
      </div>
  </>
})

export default AuthForm