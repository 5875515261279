import React from 'react'
import { observer } from 'mobx-react'
import inviteSendStore from "./InviteSendStore"

const InviteSendButton = observer(() => {
    return <>
        <button id="registration_button" className={`buttons button_blue ${inviteSendStore.disabled}`} style={{width: '100%', margin: 0}} type="button" onClick={() => inviteSendStore.SendData()} disabled={inviteSendStore.loading}>Ввести</button>
    </>
})

export default InviteSendButton
