import {observer} from "mobx-react";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import {LeftHeader} from "../../../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../../../Structure/RightHeader/rightHeader";
import React, {useEffect} from "react";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import ChildGroupUI from "../../../../Components/Inputs/ChildGroup/ChildGroupUI";
import {groupStore} from "../../../../Components/Inputs/ChildGroup/ChildGroupStore";
import modalStore from "../../../../Components/modal/modalStore";

export const ChangeGroup = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.userData.CreatedGartenId === 0){
        history.push('/groups');

        new Promise((resolve) => {
            setTimeout(() => {resolve();}, 0);
        }).then(r => {
            modalStore.SetModalInfo('Вносить изменения в группы может только заведующий дет. сада', "Предупреждение")
            modalStore.simpleModalOpen();
        })

        return null;
    }

    if (authStore.userData.UserType !== 'Pedagog' || authStore.userData.Position.IsEditable) {
        history.push('/account');
        return null;
    }

    if(childGroupsStore.group === null){
        history.push('/groups');
        return null;
    }

    useEffect(() => {
        groupStore.Group = childGroupsStore.GroupName;
        childGroupsStore.CheckGroupChange();
    })

    return <Page/>

})

const Page = observer(() => {
    let history = useHistory();
    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/groups'} className="grey_text">ГРУППЫ/</Link>
                <Link to={'/groups/change'} className="blue_text">ИЗМЕНЕНИЕ ГРУППЫ</Link>
            </p>
            <p className="greetings">РЕДАКТИРОВАТЬ ГРУППУ</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <form style={{width: '100%'}} onChange={() => childGroupsStore.CheckGroupChange()} onKeyDown={(e) => childGroupsStore.KeyDownGroupChange(e,history)}>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                    <ChildGroupUI/>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                    <button className={`buttons button_blue ${childGroupsStore.disabledGroupChangeButton}`} style={{width: '100%', margin: 0}} id="registration_button"
                                            type="button" onClick={() => childGroupsStore.ChangeGroup(history)}>Создать</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
})