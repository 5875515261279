import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";
import {innStore} from "../../../Components/Inputs/INN/innStore";
import {nameGardenStore} from "../../../Components/Inputs/GardenName/nameGardenStore";

export let updateGarten = async () => {

    return request.put(
        `/api/gartens/update_garten/${authStore.userData.CreatedGartenId}`,
        {
            inn: innStore.GetINN,
            name: nameGardenStore.GetGarden
        }
    ).then(r => {
        return rThen(r);
    })
}