import {makeAutoObservable, observable} from "mobx";

class ButtonProfileStore {

    @observable buttonBlock = false;
    @observable saveButtonBlock = true;

    constructor() {
        makeAutoObservable(this);
    }

    SetButtonBlock = () => {
        this.buttonBlock = !this.buttonBlock;
    }
    SetSaveButtonBlock = (val) => {
        this.saveButtonBlock = val;
    }
}


const buttonProfileStore = new ButtonProfileStore();

export default buttonProfileStore;