import React, {useEffect} from "react";
import {LeftHeader} from "../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../Stores/AuthStore/AuthStore";
import buttonProfileStore from "../../Components/Buttons/ProfileButton/buttonProfileStore";
import {KindergardenProfile} from "../Account/AccountPages/ProfileUser/KindergardenProfile";
import {observer} from "mobx-react";
import {KindegartenAvatar} from "./Components/Avatar";
import {KindergartenButton} from "./Components/KindergartenButton";
import kindergardenStore from "../Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";
import {RightHeader} from "../../Structure/RightHeader/rightHeader";
import cropStore from "../../Components/AvatarCrop/AvatarCropStore/cropStore";
import {AvatarCrop} from "../../Components/AvatarCrop/AvavarCropUI/AvatarCrop";

export const KindergartenChange = (() => {
    useEffect(() => {
        kindergardenStore.getUserData();
    })

    return <div className='ml-2 ml-sm-auto ml-md-auto ml-lg-auto ml-xl-auto'><Page/></div>
})


export const Page = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.userData.UserType !== 'Pedagog' || authStore.userData.CreatedGartenId === 0){
        history.push('/account');
        return null;
    }

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header main_relative">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/kindergarten'} className="grey_text">ДЕТСКИЙ САД/</Link>
                <Link to={'/kindergarten/change'} className="blue_text">УПРАВЛЕНИЕ</Link>
            </p>

            <div className="row content_margin">
                <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                    <KindegartenAvatar/>
                </div>

                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 acc_data">
                    <div>
                        <p style={{margin: 0, padding: 0}}>ОСНОВНЫЕ ДАННЫЕ АККАУНТА</p>
                        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                        </svg>
                    </div>
                    <KindergardenProfile disabled={!buttonProfileStore.buttonBlock}/>
                    <KindergartenButton/>
                </div>
            </div>

            {cropStore.openCropModal? <><AvatarCrop/><div onClick={() => {cropStore.closeCropModal()}} className="crop_opacity"/></>: <></>}
        </div>
    </>
})