import { makeAutoObservable, observable} from "mobx";
import {ageStore} from "../ageStore";
import dayStore from "./dayStore";
import monthStore from "./monthStore";

class YearStore{
    @observable returnYearList = [];
    @observable year = ageStore.getTodaysDate(20);
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceYear(year){
        document.getElementById('dropdown_year_list').style.display = 'none';
        this.year = year;

        if(ageStore.monthList.indexOf(monthStore.month) === 1){
            if(!(yearStore.year % 4 === 0 && (yearStore.year % 100 !== 0 || yearStore.year % 400 === 0))) {
                if(dayStore.day > 28){
                    dayStore.day = 28;
                }
            }
        }

        this.elem = document.activeElement.className;
        document.getElementById('dropdown_year').focus();
    }

    range(start, stop, step=1) {
        let a = [start], b = start;
        while (b < stop) {
            a.push(b += step || 1);
        }
        return a;
    }

    FocusYear(){
        ageStore.changeStyle();
        this.returnYearList = [];
        this.returnYearList = this.range(ageStore.getTodaysDate(20), ageStore.getTodaysDate(4))
    }

    Blur(){
        ageStore.changeStyleBack();
        document.getElementById('dropdown_year_list').style.display = 'none';
    }
}

const yearStore = new YearStore();

export default yearStore;