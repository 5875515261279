import {action, makeAutoObservable, observable, runInAction} from "mobx";

class InnStore {
    INN = "";
    @observable INNValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    PatternKeyDown(e){
        if(!/[0-9]/.test(e.key)){
            if(e.keyCode === 8 || e.keyCode === 13 || e.keyCode === 9){

            } else {e.preventDefault()}
        }
    }

    @action UserKeyDown(innValid="", helpText=""){
        this.INNValid = innValid;
        this.HelpText = helpText;
    }

    SetINN(e){
        this.INN = e.target.value;
        this.UserKeyDown();
    }

    ValidINN(){
        if(this.INN.length === 12){
            runInAction(() => {this.UserKeyDown();})
        } else if(this.INN.length === 10){
            runInAction(() => {this.UserKeyDown();})
        } else if(this.INN.length === 8){
            runInAction(() => {this.UserKeyDown();})
        }else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите ИНН');})
        }
    }

    get GetINN(){
        this.ValidINN();
        if(this.INNValid === "is-invalid"){
            return "";
        } else {
            return this.INN;
        }
    }

    @action Clear(){
        this.INN = "";
        this.HelpText = "";
        this.INNValid = "";
    }
}

export const innStore = new InnStore();
