import React, {useEffect} from "react";
import {LeftHeader} from "../../Structure/LeftHeader/leftHeader";
import gardenStore from "../../Stores/Garden/GardenStore";
import {TeacherTable} from "./Components/teacherTable";
import authStore from "../../Stores/AuthStore/AuthStore";
import {Link, useHistory} from "react-router-dom";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {middleNameStore} from "../../Components/Inputs/middleName/middleNameStore";
import dropdownPositionStore from "../../Components/Inputs/Position/dropdownPositionStore";
import {observer} from "mobx-react";
import {RightHeader} from "../../Structure/RightHeader/rightHeader";

export const Kindergarten = (() => {

    useEffect(() => {
        gardenStore.GetTeachers();
    })

    return <Page/>
})

const Page = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.userData.UserType !== 'Pedagog' || authStore.userData.CreatedGartenId === 0){
        history.push('/account');
        return null;
    }

    nameStore.Clear();
    surnameStore.Clear();
    middleNameStore.Clear();
    dropdownPositionStore.Clear();

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/kindergarten'} className="blue_text">ДЕТСКИЙ САД</Link>
            </p>

            <p style={{margin: "90px 0 0 0", padding: 0}}>ДЕТСКИЙ САД</p>
            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row pt-30">
                <div className="col-md-12 col-sm-12">
                    <div className="form_group">
                        <div className="form_row ml-20 mr-20 mb-5">

                            <Link to={'/kindergarten/change'} style={{width: '100%'}} onClick={() => {}}>
                                <button id="registration_button" className="buttons button_blue"
                                        style={{width: '100%', margin: 0, padding: 0}}>УПРАВЛЕНИЕ ДЕТСКИМ САДОМ
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>

            <p style={{margin: "34px 0 0 0", padding: 0}}>ПЕДАГОГИ ДЕТСКОГО САДА</p>
            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row pt-30">
                <div className="col-md-12 col-sm-12">
                    <div className="form_group">
                        <div className="form_row ml-20 mr-20 mb-5">

                            <Link to={'/kindergarten/teacher'} style={{width: '100%'}} onClick={() => {}}>
                                <button id="select_education" className="buttons button_green"
                                        style={{width: '100%', margin: 0, padding: 0}}>ДОБАВИТЬ ПЕДАГОГА
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>

            <TeacherTable/>


        </div>
    </>
})