import {observer} from "mobx-react";
import React from "react";
import HelpText from "../../HelpText";
import dayStore from "../ChildAgeStore/dayStore";
import {ageStore} from "../ageStore";

const DayUI = observer( (props) => {

    function onDropDownDay() {
        document.getElementById('dropdown_day_list').style.display = 'inline';
        // ageStore.changeStyle();
    }

    const style = {background: "transparent", outline: "none",
        display: "flex", flexDirection: "column", padding: 0,
        width: '100%', border: '0 solid transparent'}

    const dayList = (elem, index) => {
        return <button key={"day_" + index}
            style={style} type="button" className="dropdown_buttons"
            onMouseDown={() => {dayStore.ChoiceDay(elem); props.checkChange()}}
            onKeyDown={(e) => {if(e.key === "Enter"){dayStore.ChoiceDay(elem); props.checkChange()}}}>

            <div style={{width: "100%"}}>
                <p className={'dropdown_element'}>{elem}</p>
            </div>
        </button>
    }

    return <div className="dropdown_div">
        <input className={`${props.disabled? "date_input" : "date_input_change_first dropdown_img_active"}`} id="dropdown_day" autoComplete="off"
               disabled={props.disabled} placeholder={"Выберите день"} type="text"
               style={{width: "100%", caretColor: "transparent", cursor: "default"}}
               value={dayStore.day}
               onFocus={() => dayStore.FocusDay()}
               onClick={() => {onDropDownDay()}}
               onBlur={() => dayStore.Blur()}
        />
        <div className="dropdown_col_4" id="dropdown_day_list" style={{width: "100%", display: "none", background: "#F5F7FA", overflowY: "scroll", maxHeight: '10em'}}>
            {dayStore.returnDayList.map((elem, index) => dayList(elem, index))}
        </div>

        <HelpText text={dayStore.HelpText}/>
    </div>
})

export default DayUI;