import {observer} from "mobx-react";
import authStore from "../../Stores/AuthStore/AuthStore";
import './childStyle.css'
import React, {useEffect} from "react";
import {LeftHeader} from "../../Structure/LeftHeader/leftHeader";
import {childrenStore} from "../../Stores/Children/Children";
import {Link, useHistory} from "react-router-dom";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../Components/Inputs/gender/genderStore";
import {registrationButtonStore} from "../../Components/Buttons/RegistrationButton/RegistrationButtonStore";
import {ChildrenTable} from "./Components/ChildrenTable";
import {RightHeader} from "../../Structure/RightHeader/rightHeader";
import {ageStore} from "../../Components/Inputs/Age/ageStore";

export const Children = (() => {
    useEffect(() => {
        childrenStore.GetChldrens();
    })

    return <Page/>
})

const Page = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType !== "Parent"){
        history.push('/account')
    }

    nameStore.Clear();
    surnameStore.Clear();
    genderStore.Clear();
    registrationButtonStore.Clear();

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                <Link to={'/children'} className="blue_text">ДЕТИ</Link>
            </p>

            <p className="child_margin">ДЕТИ</p>
            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row pt-30">
                <div className="col-md-6 col-sm-12">
                    <div className="form_group">
                        <div className="form_row ml-20 mr-md-5 mb-5 mb-sm-15">

                        <Link to={'/children/add'} style={{width: '100%'}} onClick={() => {childrenStore.changeFlag = false; nameStore.Clear(); surnameStore.Clear(); genderStore.Clear(); ageStore.Clear();}}>
                            <button id="select_education" className="buttons button_green"
                                    style={{width: '100%', margin: 0, padding: 0}}>ДОБАВИТЬ РЕБЁНКА
                            </button>
                        </Link>

                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="form_group">
                        <div className="form_row mr-20 ml-md-5 mb-5 mb-sm-15">

                            <Link to={'/children/invite'} style={{width: '100%'}}>
                                <button id="auth_button" className="buttons button_blue"
                                        style={{width: '100%', margin: 0, padding: 0}}>
                                    <span className="invite_mobile">ИНВАЙТ-КОД</span>
                                    <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <ChildrenTable/>

        </div>
    </>
})