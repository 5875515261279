import React from "react";
import { observer } from "mobx-react";
import mainStore from "./StoreProfile/mainProfile";
import relatedAccsStore from "../../../../Stores/RelatedAccsStore/RelatedAccsStore";
import InputMask from "react-input-mask";
import TelephoneUI from "../../../../Components/Inputs/Telephone/TelephoneUI";
import buttonProfileStore from "../../../../Components/Buttons/ProfileButton/buttonProfileStore";
import parentStore from "./StoreProfile/parentProfile";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import teacherStore from "./StoreProfile/teacherProfile";

export const MainProfile = observer( () => {
    if(relatedAccsStore.ReletedAccountMain){
        mainStore.getUserData();
        relatedAccsStore.SetRelatedAccountMainFlag()
    }

    return <div className="pt-30" style={{width: '100%'}}>
            <div className="form_row">
                <div className="col-md-12 col-sm-12">
                    <p className="mb-5">КОНТАКТЫ АККАУНТА</p>
                    <div className="rect">
                        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="form_row pt-30">
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-20 ml-5 mb-sm-5">
                        <input className={`input_test ${mainStore.validEmail}`} id="user_email" type="email"
                               placeholder="Email" value={mainStore.email} onChange={(e) => mainStore.setEmail(e)} disabled={true}/>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form_group ml-5 mr-20 mr-5 mt-sm-5">

                        {authStore.decodeJwt.userType === "Parent"? <>
                            <form onChange={parentStore.CheckForm} onKeyDown={(e) => parentStore.keyDownForm(e)} style={{width: '100%'}}>
                                <TelephoneUI change={parentStore.CheckForm} disabled={!buttonProfileStore.buttonBlock}/>
                            </form></> :<></>}

                        {authStore.decodeJwt.userType === "Pedagog"? <>
                            <form onChange={teacherStore.CheckForm} onKeyDown={(e) => teacherStore.keyDownForm(e)} style={{width: '100%'}}>
                                <TelephoneUI change={teacherStore.CheckForm} disabled={!buttonProfileStore.buttonBlock}/>
                            </form></> :<></>}

                        {authStore.decodeJwt.userType === "Children"? <>
                            <form style={{width: '100%'}}>
                                <TelephoneUI change={() => {}} disabled={true}/>
                            </form></> :<></>}
                    </div>
                </div>
            </div>

    </div>
})