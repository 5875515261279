import request from "../../Request";
import {rThen} from "../../requestThen";

export let getTokensRefresh = async (refresh) => {

    return request.post(
        `/oauth/refresh`,
        {
            token: refresh
        },
        false).then(r => {
        return rThen(r)
    })

}