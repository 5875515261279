import {action, makeAutoObservable, observable, runInAction} from "mobx";
import relatedAccsStore from "../../../Stores/RelatedAccsStore/RelatedAccsStore";

class ChildDropdownStore {
    @observable returnChildList = [];
    @observable child = "";
    @observable validChild = "";
    @observable HelpText = "";
    childId = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceChild(child){
        document.getElementById('dropdown_children_list').style.display = 'none';
        this.child = child.Surname + ' ' + child.Name;
        this.childId = child.UserId;
        this.UserKeyDown();
        this.elem = document.activeElement.className;
    }
    async FocusChild(){
        this.returnChildList = []
        relatedAccsStore.relatedAccounts.map(user => {
            if(user.UserType === "Children"){
                this.returnChildList.push(user)
            }
        })
    }

    ValidChild(){
        let userListId = []
        relatedAccsStore.relatedAccounts.map(user => {
            if(user.UserType === "Children"){
                userListId.push(user.UserId)
            }
        })

        if(userListId.indexOf(this.childId) !== -1){
            runInAction(() => {this.UserKeyDown();})
        } else {
            runInAction(() => {this.UserKeyDown("is-invalid", "Укажите ребёнка")})
        }
    }

    get getChild(){
        this.ValidChild();
        if(this.validChild === ""){
            return this.childId
        } else {
            return ""
        }
    }

    @action Clear(){
        this.child = "";
        this.childId = "";
        this.validChild = "";
        this.HelpText = "";
        this.returnChildList = [];
        this.elem = "";
    }

    @action UserKeyDown(validChild="", helpText=""){
        this.validChild = validChild;
        this.HelpText = helpText;
    }

    Blur(){
        document.getElementById('dropdown_children_list').style.display = 'none';
    }
}

const childDropdownStore = new ChildDropdownStore();

export default childDropdownStore;