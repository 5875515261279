import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {LeftHeader} from "../../../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import {RightHeader} from "../../../../Structure/RightHeader/rightHeader";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import NameUI from "../../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../../Components/Inputs/Surname/surnameUI";
import {nameStore} from "../../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../../Components/Inputs/Surname/surnameStore";

export const ChangeGroupChildren = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if (authStore.userData.UserType !== 'Pedagog') {
        history.push('/account');
        return null;
    }

    if(childGroupsStore.children === null){
        history.push('/groups');
        return null;
    }

    useEffect(()=> {
        nameStore.Name = childGroupsStore.children.Name;
        surnameStore.Surname = childGroupsStore.children.Surname;
        childGroupsStore.CheckFormChildChangeInGroup();
    })

    return <Page/>
    })

const Page = observer(() => {
    let history = useHistory();
    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/groups'} className="grey_text">ГРУППЫ/</Link>
                <Link to={'/groups/children/change'} className="blue_text">ИЗМЕНИТЬ РЕБЁНКА</Link>
            </p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ РЕБЁНКА</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <form style={{width: '100%'}} onChange={() => childGroupsStore.CheckFormChildChangeInGroup()} onKeyDown={(e) => childGroupsStore.keyDownFormChildChange(e)}>
                        <div className="row pt-30">
                            <div className="col-md-6 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5">
                                    <NameUI/>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                    <SurnameUI/>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                    <button className={`buttons button_blue ${childGroupsStore.disabledChildButton}`} id="registration_button"
                                            style={{width: '100%', margin: 0}} type="button" onClick={() => childGroupsStore.ChangeChild(history)}>Далее</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
})