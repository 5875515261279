import {action, makeAutoObservable, observable} from "mobx";

class RegistrationButtonStore {
    @observable disabledButton = 'disabled';

    constructor() {
        makeAutoObservable(this);
    }

    SetDisabledButton(val=''){
        this.disabledButton = val;
    }

    @action Clear(){
        this.disabledButton = 'disabled'
    }
}

export const registrationButtonStore = new RegistrationButtonStore();