import {observer} from "mobx-react";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import {DropdownTeacherUI} from "../../../../Components/Inputs/TeacherDropdown/teacherDropdownUI";
import ChildGroupUI from "../../../../Components/Inputs/ChildGroup/ChildGroupUI";
import React from "react";
import {useHistory} from "react-router-dom";

export const FormGroup = observer(() => {
    let history = useHistory();

    return <>
        <form style={{width: '100%'}} onChange={() => childGroupsStore.CheckFormGroupChange()} onKeyDown={(e) => childGroupsStore.keyDownFormGroup(e,history)}>
            <div className="row">

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 pt-30">
                        <DropdownTeacherUI checkChange={childGroupsStore.CheckFormGroupChange}/>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <ChildGroupUI/>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <button className={`buttons button_blue ${childGroupsStore.disabledGroupButton}`} style={{width: '100%', margin: 0}} id="registration_button"
                                type="button" onClick={() => childGroupsStore.CreateNewGroup(history)}>Создать</button>
                    </div>
                </div>
            </div>
        </form>
    </>
})