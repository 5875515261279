import { observable, makeAutoObservable } from "mobx"
import regStepStore from "../../Stores/Registration/StepRegistration";
import authStore from "../../Stores/AuthStore/AuthStore";

class ModalStore {
    @observable message = '';
    @observable type = '';
    @observable RedirectFlag = false;
    EndReg = false;
    modalCode = false;

    constructor() {
        makeAutoObservable(this);
    }

    SetModalInfo(message, type){
        this.message = message;
        this.type = type;
    }

    ModalOpen(){
        document.getElementById('modal_message').style.display = 'flex';
        document.getElementById('opacity').style.display = "inline";
    }

    simpleModalOpen(){
        document.getElementById('simple_modal_message').style.display = 'flex';
        document.getElementById('opacity').style.display = "inline";
    }

    ModalClose(){
        modalStore.EndReg = false;
        document.getElementById('modal_message').style.display = 'none';
        if(window.location.href.indexOf('registration1') !== -1 && (regStepStore.step === "2.1" ||
            regStepStore.step === "2.2" || regStepStore.step === "KindergartenQuestion"
            || regStepStore.step === "RegKindergarten")){
            regStepStore.setStep('invite');
            authStore.setIsRegistrationFalse();
        }
    }

}

const modalStore = new ModalStore();

export default modalStore;