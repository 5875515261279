import {makeAutoObservable, observable} from "mobx";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../Components/Inputs/gender/genderStore";
import {getChildrenList} from "../../Request/api/Childrens/getChildrenList";
import {updateChildren} from "../../Request/api/Childrens/updateChild";
import modalStore from "../../Components/modal/modalStore";
import authStore from "../AuthStore/AuthStore";
import Notify from "../../Notifications/Notify";
import {ageStore} from "../../Components/Inputs/Age/ageStore";
import {registrationButtonStore} from "../../Components/Buttons/RegistrationButton/RegistrationButtonStore";
import {deleteChildren} from "../../Request/api/BaseUser/deleteUser";
import dayStore from "../../Components/Inputs/Age/ChildAgeStore/dayStore";
import monthStore from "../../Components/Inputs/Age/ChildAgeStore/monthStore";
import yearStore from "../../Components/Inputs/Age/ChildAgeStore/yearStore";

class Children {
    ChildList = [];
    child = null;
    @observable changeFlag = false;
    @observable childId = 0;
    @observable disabledChangeButton = 'disabled';
    tempName = '';
    tempSurname = '';
    tempGender = '';
    tempBirthday = '';

    constructor() {
        makeAutoObservable(this);
    }

    ChangePOnInput = (child) => {
        this.changeFlag = true;
        this.childId = child.UserId
        nameStore.Name = child.Name;
        surnameStore.Surname = child.Surname;
        genderStore.Gender = (child.Sex? "Мужской" : "Женский");
        this.tempName = child.Name;
        this.tempSurname = child.Surname;
        this.tempBirthday = child.BirthDay.substr(0, child.BirthDay.indexOf("T"))
        this.tempGender = (child.Sex? "Мужской" : "Женский");
        this.child = child;
    }

    ChangeInputOnP = () => {
        this.changeFlag = false;
        this.childId = null;
        nameStore.Name = null;
        surnameStore.Surname = null;
        genderStore.Gender = null;
        this.tempName = null;
        this.tempSurname = null;
        this.tempBirthday = null;
        this.tempGender = null;
    }

    CheckChildChange = (history) => {
        if(nameStore.Name !== '' && surnameStore.Surname !== '' &&
            genderStore.Gender !== '' && ageStore.Age !== '' &&
            this.tempGender !== '' && this.tempBirthday !== '' &&
           this.tempName !== '' && this.tempSurname !== '' &&
            (nameStore.Name !== this.tempName || surnameStore.Surname !== this.tempSurname ||
            ageStore.Age !== this.tempBirthday || genderStore.Gender !== this.tempGender ||
            dayStore.day != childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(8).replace('0', '') ||
            monthStore.month !== ageStore.monthList[childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(5, 7).replace('0', '') -1] ||
            yearStore.year !== childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(0,4))){
            updateChildren(this.childId).then(r => {
                if(r !== null && r.type === 'Data'){
                    this.ChangeInputOnP();
                    modalStore.SetModalInfo("Данные аккаунта обновлены.", "Успешно")
                    window.$('#simple_message_modal').modal('show');
                    if(!Notify.authResult){
                        authStore.getCompulsoryToken();
                    }
                    history.push('/children');
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            });
        } else {
            this.ChangeInputOnP();
        }
    }

    CheckFormChange = () => {

        if(nameStore.Name !== '' && surnameStore.Surname !== '' && genderStore.Gender !== '' && ageStore.Age !== '' &&
            (
                nameStore.Name !== this.child.Name ||
                surnameStore.Surname !== this.child.Surname ||
                genderStore.Gender !== (this.child.Sex? "Мужской" : "Женский") ||
                ageStore.Age !== this.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")) ||
                dayStore.day != childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(8).replace('0', '') ||
                monthStore.month !== ageStore.monthList[childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(5, 7).replace('0', '') -1] ||
                yearStore.year !== childrenStore.child.BirthDay.substr(0, authStore.userData.BirthDay.indexOf("T")).slice(0,4)
            )){
            this.disabledChangeButton = '';
        } else {
            this.disabledChangeButton = 'disabled';
        }
    }

    keyDownFormChange = (e, history=null) => {
        if(e.code === "Enter"){e.preventDefault()}
        if(registrationButtonStore.disabledButton === '' && e.code === "Enter" && genderStore.elem !== 'dropdown-item'){
            this.CheckChildChange(history);
        }
        genderStore.elem = ''
    }

    async GetChldrens(){
        if(authStore.isAuth){
            this.ChildList = await getChildrenList().then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                    return [];
                }
            })
        }
    }


    DeleteChild(){
        new Promise((resolve) => {
            setTimeout(() => {resolve();}, 0);
        }).then(r => {
            modalStore.SetModalInfo('', "УДАЛИТЬ РЕБЕНКА?")
            modalStore.modalCode = true;
            modalStore.simpleModalOpen();
        })
    }

    deleteChildrenOk(){
        deleteChildren(this.childId).then(r => {
            if(r !== null && r.type !== "Error"){
                modalStore.SetModalInfo("", "Ребенок успешно удален")
                modalStore.simpleModalOpen();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }
}
export const childrenStore = new Children();