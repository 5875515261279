import {makeAutoObservable, action} from "mobx";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import dropdownPositionStore from "../../../../../Components/Inputs/Position/dropdownPositionStore";
import modalStore from "../../../../../Components/modal/modalStore";
import {cityStore} from "../../../../../Components/Inputs/City/cityStore";
import stateStore from "../../../../../Components/Inputs/State/StateStore";
import countryStore from "../../../../../Components/Inputs/Country/CountryStore";
import buttonProfileStore from "../../../../../Components/Buttons/ProfileButton/buttonProfileStore";
import {nameStore} from "../../../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../../../Components/Inputs/gender/genderStore";
import {middleNameStore} from "../../../../../Components/Inputs/middleName/middleNameStore";
import {updatePedagog} from "../../../../../Request/api/Pedagogs/updatePedagog";
import Notify from "../../../../../Notifications/Notify";
import {telephoneStore} from "../../../../../Components/Inputs/Telephone/TelephoneStore";
import {updateTelephone} from "../../../../../Request/api/User/updateUser";

class TeacherStore {

    @action getUserData(){
        nameStore.Name = authStore.userData.Name;
        surnameStore.Surname = authStore.userData.Surname;
        middleNameStore.middleName = authStore.userData.MiddleName;
        genderStore.Gender = (authStore.userData.Sex? "Мужской" : "Женский");
        dropdownPositionStore.position = authStore.userData.Position.PositionName;
        dropdownPositionStore.positionId = authStore.userData.Position.PositionId;
        cityStore.City = authStore.userData.City;
        stateStore.state = authStore.userData.State;
        countryStore.country = authStore.userData.Country;
        telephoneStore.Telephone = authStore.masterAccountData.phone.slice(1);
    }

    constructor() {
        makeAutoObservable(this);
    }

    CheckForm = () => {
        if(cityStore.City !== '' && nameStore.Name !== '' && surnameStore.Surname !== ''
            && genderStore.Gender !== '' && countryStore.country !== '' && stateStore.state !== '' &&
            dropdownPositionStore.position !== '' && telephoneStore.Telephone !== '' &&
            (nameStore.Name !== authStore.userData.Name ||
                surnameStore.Surname !== authStore.userData.Surname ||
                middleNameStore.middleName !== authStore.userData.MiddleName ||
                genderStore.Gender !== (authStore.userData.Sex? "Мужской" : "Женский") ||
                countryStore.country !== authStore.userData.Country ||
                stateStore.state !== authStore.userData.State ||
                cityStore.City !== authStore.userData.City ||
                dropdownPositionStore.position !== authStore.userData.Position.PositionName ||
                (telephoneStore.Telephone.slice(0,1) === '7'? telephoneStore.Telephone.slice(1) : telephoneStore.Telephone) !== authStore.masterAccountData.phone.slice(2))){
            buttonProfileStore.SetSaveButtonBlock(false)
        } else
            buttonProfileStore.SetSaveButtonBlock(true)
    }

    UpdateTeacher = async () =>{
        nameStore.ValidName();
        surnameStore.ValidSurname();
        middleNameStore.ValidmiddleName();
        genderStore.ValidGender();
        cityStore.ValidCity();
        dropdownPositionStore.ValidPosition();
        countryStore.ValidCountry();
        stateStore.ValidState();
        telephoneStore.ValidTelephone();

        if(nameStore.GetName !== '' && surnameStore.GetSurname !== '' &&
            typeof genderStore.getGender === 'boolean' && cityStore.GetCity !== '' && telephoneStore.GetTelephone !== '' &&
            dropdownPositionStore.getPosition !== '' && countryStore.getCountry !== '' && stateStore.getState !== ''){
            updatePedagog().then(r => {
                if(r !== null && r.type === "Data"){
                    buttonProfileStore.SetSaveButtonBlock(true)
                    buttonProfileStore.SetButtonBlock()
                    modalStore.SetModalInfo("", "ДАННЫЕ АККАУНТА ОБНОВЛЕНЫ")
                    modalStore.simpleModalOpen();
                    if(!Notify.authResult){
                        authStore.getCompulsoryToken();
                    }
                } else {
                    modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
            if(telephoneStore.Telephone !== authStore.masterAccountData.phone.slice(1) && telephoneStore.GetTelephone !== ''){
                updateTelephone().then(r => {
                    if(r !== null && r.type === "Data"){
                        if(!Notify.authResult){
                            authStore.getCompulsoryToken();
                        }
                    } else {
                        modalStore.SetModalInfo('Произошла ошибка при обновлении телефона', "Ошибка")
                        modalStore.simpleModalOpen();
                    }
                })
            }
        }
    }

    keyDownForm = (e) => {
        if(!buttonProfileStore.saveButtonBlock && e.code === "Enter"
            && countryStore.elem !== 'dropdown-item' &&
            stateStore.elem !== 'dropdown-item' &&
            genderStore.elem !== 'dropdown-item' &&
            dropdownPositionStore.elem !== 'dropdown-item'){
            this.UpdateTeacher();
        }
        dropdownPositionStore.elem = ''
        stateStore.elem = ''
        countryStore.elem = ''
        genderStore.elem = ''
    }
}

const teacherStore = new TeacherStore();

export default teacherStore;
