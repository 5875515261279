import { makeAutoObservable } from 'mobx'
import {getCountry} from "../../Request/api/Regions/getCountry";
import {getRegionByCountry} from "../../Request/api/Regions/getRegionByCountry";

class PlaceStore {

    country = []
    state = []
    city = []

    constructor() {
        makeAutoObservable(this);
        this.GetCountry()
    }

    async GetCountry() {
        if(this.country.length === 0)
            this.country = await getCountry().then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {

                    return [];
                }
            })
    }

    async GetRegionByCountry(country){
        if(this.state.length === 0)

            this.state = await getRegionByCountry(country).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {

                    return [];
                }
            })
    }
}

const placeStore = new PlaceStore();

export default placeStore;
