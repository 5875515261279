import {observer} from "mobx-react";
import React from "react";
import {emailStore} from './EmailStore'
import HelpText from "../HelpText";

const EmailUI = observer((props) => {

    return <>
        <div className="btn btn-primary tooltip" style={{width: '100%'}}>

            <input  className={props.disabled? `input_test ${emailStore.EmailValid}`: `input_test_change ${emailStore.EmailValid}`} type="email" onKeyDown={emailStore.emailKeyDown}
                    onChange={(e)=>{emailStore.SetEmail(e)}} value={emailStore.Email}
                    aria-describedby="emailHelp" placeholder="Укажите вашу почту" autoComplete="off" id="email_reg"
            />
            <HelpText text={emailStore.HelpText}/>

            <div className="top" style={{display: props.styleDisplay}}>
                <p>Почта должна содержать @gmail.com, @mail.ru и т.п.</p>
                <i></i>
            </div>
        </div>
    </>
})

export default EmailUI;