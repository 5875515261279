import { observable, action, makeAutoObservable  } from "mobx"
import inviteInputStore from "../../Inputs/InviteCodeInput/InviteInputStore"
import modalStore from "../../modal/modalStore"
import childDropdownStore from "../../Inputs/ChildDropdown/ChildDropdownStore";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {checkPedagogInvite} from "../../../Request/api/Pedagogs/checkPedagogInvite";
import {checkChildInvite} from "../../../Request/api/Childrens/checkChildInvite";

class InviteSendStore {
    @observable loading = false
    @observable disabled = 'disabled'

    constructor() {
        makeAutoObservable(this);
    }

    FormChange = () => {
        if(
            (authStore.decodeJwt.userType === "Parent" && inviteInputStore.invite !== '' && childDropdownStore.childId !== '')
        ||
            (authStore.decodeJwt.userType === "Pedagog" && inviteInputStore.invite !== '')
        ){
            this.disabled = ''
        } else {
            this.disabled = 'disabled'
        }
    }

    @action SendData() {
        if(this.disabled === ''){
            childDropdownStore.ValidChild();
            inviteInputStore.ValidInvite();
            if(
                (authStore.decodeJwt.userType === "Parent" && childDropdownStore.getChild !== "" && inviteInputStore.GetInvite !== "")
                ||
                (authStore.decodeJwt.userType === "Pedagog" && inviteInputStore.GetInvite !== "")
            ){
                if(authStore.decodeJwt.userType === "Parent"){
                    checkChildInvite(inviteInputStore.GetInvite, childDropdownStore.getChild).then(r => {
                        if(r === null){
                            modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                            modalStore.simpleModalOpen();
                        } else if (r.type === "Data"){
                            modalStore.SetModalInfo("", "Вы успешно прикреплены к дет.саду")
                            modalStore.simpleModalOpen();
                            childDropdownStore.Clear();
                            inviteInputStore.Clear();
                        } else {
                            modalStore.SetModalInfo("", "Ваш код-приглашение недействителен")
                            modalStore.simpleModalOpen();
                        }
                    })
                } else {
                    checkPedagogInvite(inviteInputStore.GetInvite).then(r => {
                        if(r === null){
                            modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                            modalStore.simpleModalOpen();
                        } else if (r.type === "Data"){
                            modalStore.SetModalInfo("", "Вы успешно прикреплены к дет.саду")
                            modalStore.simpleModalOpen();
                            authStore.getCompulsoryToken();
                            childDropdownStore.Clear();
                            inviteInputStore.Clear();
                        } else {
                            modalStore.SetModalInfo("", "Ваш код-приглашение недействителен")
                            modalStore.simpleModalOpen();
                        }
                    })
                }

            }
        }
    }

    KeyDown = (e) => {
        if(e.code === "Enter")
            e.preventDefault();
        if(this.disabled === '' && e.code === "Enter" && childDropdownStore.elem !== 'dropdown-item'){
            this.SendData();
        }
        childDropdownStore.elem = '';
    }

    @action Clear(){
        this.loading = false;
        this.disabled = 'disabled';
    }
}

const inviteSendStore = new InviteSendStore()

export default inviteSendStore