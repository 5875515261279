import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {cityStore} from "./cityStore";

const CityUI = observer((props) => {
    return <>
        <input className={`${props.disabled? "input_test" : "input_test_change"} ${cityStore.CityValid}`} type="text" disabled={props.disabled}
               aria-describedby="emailHelp" placeholder="Город" value={cityStore.City} onChange={(e) => cityStore.SetCity(e)}/>
        <HelpText text={cityStore.HelpText}/>
    </>
})

export default CityUI;