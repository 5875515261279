import {action, makeAutoObservable, observable, runInAction} from "mobx";

class SurnameStore {
    Surname = "";
    @observable SurnameValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(SurnameValid="", helpText=""){
        this.SurnameValid = SurnameValid;
        this.HelpText = helpText;
    }

    SetSurname(e){
        this.Surname = e.target.value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        this.UserKeyDown();
    }

    ValidSurname(){
        if(this.Surname.trim().length >= 1){
            runInAction(() => {this.UserKeyDown();})
        } else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите фамилию');})
        }
    }

    get GetSurname(){
        this.ValidSurname();
        if(this.SurnameValid === "is-invalid"){
            return "";
        } else {
            return this.Surname;
        }
    }

    @action Clear(){
        this.Surname = "";
        this.HelpText = "";
        this.SurnameValid = "";
    }
}

export const surnameStore = new SurnameStore();