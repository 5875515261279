import React from 'react';
import { observer } from "mobx-react";
import 'react-select-search/style.css';
import {regChild} from "../../../Stores/Registration/SecondRegistration";
import RegistrationButtonUI from "../../../Components/Buttons/RegistrationButton/RegistrationButtonUI";
import GenderUI from "../../../Components/Inputs/gender/genderUI";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import {useHistory} from "react-router-dom";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {CheckStep} from "../registration1";
import AgeUI from "../../../Components/Inputs/Age/AgeUI";


export const RegStep3Children = observer(() => {
    let history = useHistory();
    if(authStore.isRegistration){
        CheckStep('children');
        return <>
            <div className="main">
                <p className="title_greetings">РЕГИСТРАЦИЯ АККАУНТА</p>
                <p className="greetings">ВВЕДИТЕ ДАННЫЕ РЕБЁНКА</p>

                <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                </svg>

                <div className="row">
                    <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                        <form onChange={regChild.CheckForm} onKeyDown={(e) => regChild.keyDownForm(e, history)}>
                            <div className="row pt-30">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5">
                                        <NameUI/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                        <SurnameUI/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                        <GenderUI checkChange={regChild.CheckForm}/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                                        <AgeUI checkChange={regChild.CheckForm}/>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                                        <RegistrationButtonUI checkUserData={() => regChild.CheckChildData(history)}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <svg className="step_reg" width="70" height="10" viewBox="0 0 70 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="65" cy="5" r="5" fill="#434A54"/>
                    <circle cx="35" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
                    <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
                </svg>
            </div>
        </>
    } else {
        return <>
            <form onChange={regChild.CheckForm} onKeyDown={(e) => regChild.keyDownForm(e, history)}>
                <div className="row pt-30">
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-10">
                            <NameUI/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-10">
                            <SurnameUI/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5">
                            <GenderUI checkChange={regChild.CheckForm}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                            <AgeUI checkChange={regChild.CheckForm}/>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                        <div className="form_group mr-5 ml-5 mb-5 mt-5">
                            <RegistrationButtonUI checkUserData={() => regChild.CheckChildData(history)}/>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
})

export default RegStep3Children;