import {observer} from "mobx-react";
import reportStore from "../../../Stores/Report/ReportStore";

export const TotalTimeDashboard = observer((props) => {

    let prevStrokeDash = 25;
    let prevPercent = 100;

    return <div style={{width: '280px', height: '280px', margin: '0 auto', position: 'relative'}}>
        <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
            <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"/>
            <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#d2d3d4"
    strokeWidth="8"/>

        {reportStore.gamesTime.map(game => {

                prevStrokeDash = 100 - prevPercent + +prevStrokeDash
                prevPercent = game.Seconds / reportStore.totalTime * 100

                return <>
                    <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={game.color}
                            strokeWidth="8" strokeDasharray={`
                ${game.Seconds / reportStore.totalTime * 100} ${100 - game.Seconds / reportStore.totalTime * 100}
                `} strokeDashoffset={prevStrokeDash}/>
                </>
            }
        )}
        </svg>

        <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
            <div style={{display: 'flex'}}>
                <div style={{paddingRight: '6px'}}>
                    <p style={{margin: 0, fontSize: "36px"}}>{parseInt(+reportStore.totalTime / 60)}</p>
                    <p style={{margin: 0, fontSize: "12px"}}>{reportStore.declOfNum(parseInt(+reportStore.totalTime / 60), ['МИНУТА','МИНУТЫ','МИНУТ'])}</p>
                </div>
                <div style={{paddingLeft: '6px'}}>
                    <p style={{margin: 0, fontSize: "36px"}}>{+reportStore.totalTime % 60}</p>
                    <p style={{margin: 0, fontSize: "12px"}}>{reportStore.declOfNum(+reportStore.totalTime % 60, ['СЕКУНДА','СЕКУНДЫ','СЕКУНД'])}</p>
                </div>
            </div>
        </div>
    </div>
})