import React from 'react';
import { observer } from "mobx-react";
import confirmMail from '../../Stores/Registration/confirmMail';
import {Link} from "react-router-dom";


export const ComfirmMail1 = observer( () => {

    return <>
        <div className="main pt_header">
            <p className="title_greetings">ПОДТВЕРЖДЕНИЕ ПОЧТЫ</p>
            <p className="greetings">{confirmMail.message}</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div style={{marginTop: "40px"}}>
                <Link to={'/account'} style={{fontSize: '20px'}}>Вернуться на главную страницу</Link>
            </div>
        </div>
    </>
})