import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {nameStore} from "./nameStore";

const NameUI = observer((props) => {
    return <>
            <input className={`${props.disabled? "input_test" : "input_test_change"} ${nameStore.NameValid}`} id="user_name" type="text" disabled={props.disabled} autoComplete={'off'}
                   aria-describedby="emailHelp" placeholder="Имя" value={nameStore.Name} onChange={(e) => nameStore.SetName(e)}/>
            <HelpText text={nameStore.HelpText}/>
    </>
})

export default NameUI;