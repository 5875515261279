import request from "../../Request";
import {rThen} from "../../requestThen";
import authStore from "../../../Stores/AuthStore/AuthStore";

export let LogoutApi = async () => {

    return request.post(
        `/oauth/logout`,
        {
            token: authStore.refresh
        },
        false).then(r => {
        return rThen(r)
    })
}