import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {groupStore} from "./ChildGroupStore";

const ChildGroupUI = observer((props) => {
    return <>
            <input className={`input_test_change ${groupStore.GroupValid}`} id="group_user_fio" type="text" disabled={props.disabled}
                   aria-describedby="emailHelp" placeholder="Укажите название группы" value={groupStore.Group} onChange={(e) => groupStore.SetGroup(e)}/>
            <HelpText text={groupStore.HelpText}/>
    </>
})

export default ChildGroupUI;