import {observer} from "mobx-react";
import reportStore, {Base64} from "../../../Stores/Report/ReportStore";

export const GameTimeDashboard = observer((props) => {
    return <div style={{padding: '0 80px'}}>

        {reportStore.gamesTime.map((game, iter) => <>

            <div className={"row"}>
                <div className={"col-md-4"} style={{display: "flex", flexDirection: 'row'}}>
                    <div style={{minWidth: '18px', height: '18px', borderRadius: '50%', background: game.color, margin: 'auto 8px auto 0'}}/>
                    <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>{Base64.decode(game.LevelName)}</p>
                </div>

                <div className={"col-md-7"} style={{display: "flex", flexDirection: "column", justifyContent: "center", position: "relative", padding: '9px 0'}}>
                    <div style={{width: '100%', height: '36px', background: "transparent", display: "flex", alignItems: "flex-end"}}>
                        <div style={{marginLeft: '2px', display: 'flex', justifyContent: 'flex-end', background: game.color, height: '100%', width: `${Math.round(game.Seconds * 100 / 600)}%`}}/>
                    </div>

                    <div style={{position: "absolute", display: "flex", justifyContent: "space-between", width: '100%', height: '100%'}}>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                        <div className={reportStore.gamesTime.length === iter + 1? 'line_game_time_last' : 'line_game_time'}/>
                    </div>
                </div>

                <div className={"col-md-1"}>
                    <p className="game_text" style={{margin: '18px 0', textAlign: 'right'}}>
                        {game.Seconds / 60 >= 1? `${Math.floor(game.Seconds/60)}м.${game.Seconds - Math.floor(game.Seconds/60) * 60}с.` : `${game.Seconds}с.`}
                    </p>
                </div>
            </div>

            </>)}
    </div>
})