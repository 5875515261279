import {observer} from "mobx-react";
import {GameTimeDashboard} from "./GameTimeDashboard";
import {ProgressDashboard} from "./ProgressDashboard";
import React from "react";
import {CircleDashboard} from "./circleDashboard";
import reportStore from "../../../Stores/Report/ReportStore";
import {TotalTimeDashboard} from "./totalTimeDashboard";
import {BestOrBadCircle} from "./bestOrBadCircle";
import {SmallTextAndSvg} from "./smallTextAndSvg";
import {BigTextAndSvg} from "./bigTextAndSvg";
import {LevelButton} from "./levelButton";
import {MiddleTextAndSvg} from "./middleTextAndSvg";
import {ProgressDashboardPhone} from "./ProgressDashboardPhone";
import {GameTimeDashboardPhone} from "./GameTimeDashboardPhone";

export const Dashboard = observer((props) => {
    return <>

        <div className="row sm_none_flex" style={{margin: '24px 20px 0 20px'}}>

            <div style={{display: "flex", width: '100%'}}>
                <div className="col-sm-4">
                    <LevelButton text={'1 УРОВЕНЬ'} level={1}/>
                </div>

                <div className="col-sm-4">
                    <LevelButton text={'2 УРОВЕНЬ'} level={2}/>
                </div>

                <div className="col-sm-4">
                    <LevelButton text={'3 УРОВЕНЬ'} level={3}/>
                </div>
            </div>

            {reportStore.SelectedLevel === null? <></>:
            <>
                {reportStore.PlayStats === null || reportStore.PlayStats.length === 0 || reportStore.totalTime === 0?
                    <>
                        <p style={{fontSize: '14px', color: '#434A54', fontFamily: 'PT Sans Caption, sans-serif', width: '100%', marginTop: '30px'}}>Нет данных</p>
                    </>
                    :
                    <>
                        <div style={{display: "flex", flexDirection: "column", width: '100%'}}>

                            <BigTextAndSvg divM={'30px auto 0 auto'} pM={'0 0 10px 0'} div2M={'0 auto 30px auto'} text={'ОБЩЕЕ ВЫПОЛНЕНИЕ УРОВНЯ (%)'}/>
                            <CircleDashboard/>

                            <BigTextAndSvg divM={'45px auto 10px auto'} pM={'0'} div2M={'0 auto 30px auto'} text={'ВЫПОЛНЕНИЕ ОДНОЙ ИГРЫ, %'}/>
                            <ProgressDashboard/>

                            <BigTextAndSvg divM={'11px auto 0 auto'} pM={'0'} div2M={'10px auto 37px auto'} text={'ВРЕМЯ, ПРОВЕДЕННОЕ НА ОДНОМ УРОВНЕ'}/>
                            <TotalTimeDashboard/>

                            <BigTextAndSvg divM={'30px auto 0 auto'} pM={'0'} div2M={'10px auto 30px auto'} text={'ВРЕМЯ, ПРОВЕДЕННОЕ В ОДНОЙ ИГРЕ'}/>
                            <GameTimeDashboard/>

                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: '20px'}}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <SmallTextAndSvg text={<p style={{textAlign: "center", margin: 0}} >ИГРА С НАИЛУЧШИМИ <br/> ПОКАЗАТЕЛЯМИ</p>}/>
                                </div>

                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <SmallTextAndSvg text={<p style={{textAlign: "center", margin: 0}} >ИГРА, ГДЕ СТОИТ <br/> УЛУЧШИТЬ РЕЗУЛЬТАТЫ</p>}/>
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <BestOrBadCircle best={true} bigSize={240} smallSize={163}/>

                                <BestOrBadCircle best={false} bigSize={240} smallSize={163}/>
                            </div>

                            <div style={{width: '100%'}}>
                                <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-around"}}>
                                    <div style={{display: "flex", flexDirection: 'row'}}>
                                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#A0D468', margin: 'auto 8px auto 0'}}/>
                                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Прогресс</p>
                                    </div>

                                    <div style={{display: "flex", flexDirection: 'row'}}>
                                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#4FC1E9', margin: 'auto 8px auto 0'}}/>
                                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Время</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>}
            </>}

        </div>

        <div className="row md_none_flex">

            <MiddleTextAndSvg text={'ВЫБЕРИТЕ УРОВЕНЬ'}/>
            <div style={{height: '26px', background: "transparent", width: '100%'}}/>
            <div style={{display: "flex", width: '100%'}}>
                <div className="col-sm-4">
                    <LevelButton text={'1'} level={1}/>
                </div>

                <div className="col-sm-4">
                    <LevelButton text={'2'} level={2}/>
                </div>

                <div className="col-sm-4">
                    <LevelButton text={'3'} level={3}/>
                </div>
            </div>

            {reportStore.SelectedLevel === null? <></>:
                <>
                {reportStore.PlayStats === null || reportStore.PlayStats.length === 0 || reportStore.totalTime === 0?
                    <>
                        <p style={{fontSize: '14px', color: '#434A54', fontFamily: 'PT Sans Caption, sans-serif', width: '100%', marginTop: '30px'}}>Нет данных</p>
                    </>
                    :
                    <>
                        <MiddleTextAndSvg text={'ОБЩЕЕ ВЫПОЛНЕНИЕ УРОВНЯ (%)'}/>
                        <div style={{height: '26px', background: "transparent", width: '100%'}}/>
                        <CircleDashboard/>

                        <div style={{height: '26px', background: "transparent", width: '100%'}}/>
                        <MiddleTextAndSvg text={'ВЫПОЛНЕНИЕ ОДНОЙ ИГРЫ, %'}/>
                        <div style={{height: '17px', background: "transparent", width: '100%'}}/>
                        <ProgressDashboardPhone/>

                        <div style={{height: '26px', background: "transparent", width: '100%'}}/>
                        <MiddleTextAndSvg text={'ВРЕМЯ, ПРОВЕДЕННОЕ НА ОДНОМ УРОВНЕ'}/>
                        <div style={{height: '26px', background: "transparent", width: '100%'}}/>
                        <TotalTimeDashboard/>

                        <BigTextAndSvg divM={'30px auto 0 auto'} pM={'0'} div2M={'10px auto 30px auto'} text={'ВРЕМЯ, ПРОВЕДЕННОЕ В ОДНОЙ ИГРЕ'}/>
                        <GameTimeDashboardPhone/>

                        <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: '100%'}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <SmallTextAndSvg text={<p style={{textAlign: "center", margin: 0}} >ИГРА С НАИЛУЧШИМИ <br/> ПОКАЗАТЕЛЯМИ</p>}/>
                            </div>
                            <BestOrBadCircle best={true} bigSize={200} smallSize={145}/>

                            <div style={{display: "flex", flexDirection: "column"}}>
                                <SmallTextAndSvg text={<p style={{textAlign: "center", margin: 0}} >ИГРА, ГДЕ СТОИТ <br/> УЛУЧШИТЬ РЕЗУЛЬТАТЫ</p>}/>
                            </div>
                            <BestOrBadCircle best={false} bigSize={200} smallSize={145}/>

                            <div style={{width: '100%'}}>
                                <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-around"}}>
                                    <div style={{display: "flex", flexDirection: 'row'}}>
                                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#A0D468', margin: 'auto 8px auto 0'}}/>
                                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Прогресс</p>
                                    </div>

                                    <div style={{display: "flex", flexDirection: 'row'}}>
                                        <div style={{width: '18px', height: '18px', borderRadius: '50%', background: '#4FC1E9', margin: 'auto 8px auto 0'}}/>
                                        <p className="game_text" style={{margin: 'auto 0', textAlign: "left"}}>Время</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </>}

        </div>

    </>
})