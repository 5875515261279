import {observer} from "mobx-react";
import reportStore from "../../../Stores/Report/ReportStore";

export const ChildIconDashboard = observer((props) => {
    return <>
        <div className="child_dashboard_container" onClick={() => {reportStore.GetPlayerStats(props.id)}}>
            <div className="img_container" style={reportStore.ChildSelectId === props.id? {background: '#4FC1E9'} : {}}>
                <img className="img_child" style={reportStore.ChildSelectId === props.id? {background: 'white'} : {}} src={props.img} alt={""}/>
                <p className={"child_dashboard_name"}>{props.name}</p>
            </div>
        </div>
    </>
})