import {action, makeAutoObservable, observable} from "mobx";

class TelephoneStore {
    Telephone = "";
    @observable TelephoneValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(telephoneValid="", helpText=""){
        this.TelephoneValid = telephoneValid;
        this.HelpText = helpText;
    }

    SetTelephone(value, change){
        if(value === null || value === undefined)
            return

        this.Telephone = value;
        change();
        this.UserKeyDown();

    }

    ValidTelephone(){
        if(this.Telephone.length !== 11){
            this.TelephoneValid = "is-invalid";
            this.HelpText = "Указан неверный номер телефона";
        } else if (this.Telephone.length === 0){
            this.TelephoneValid = "is-invalid";
            this.HelpText = "Укажите номер телефона";
        } else {
            this.TelephoneValid = "";
            this.HelpText = "";
        }
    }

    get GetTelephone(){
        this.ValidTelephone();
        if(this.TelephoneValid === "is-invalid"){
            return "";
        } else {
            return '+'+this.Telephone;
        }
    }

    @action Clear(){
        this.Telephone = "";
        this.HelpText = "";
        this.TelephoneValid = "";
    }
}

export const telephoneStore = new TelephoneStore();