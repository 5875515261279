import {observer} from "mobx-react";
import React from "react";
import MonthUI from "./ChildAgeUI/monthUI";
import DayUI from "./ChildAgeUI/dayUI";
import YearUI from "./ChildAgeUI/yearUI";
import {ageStore} from "./ageStore";
import {regChild} from "../../../Stores/Registration/SecondRegistration";

const AgeUI = observer((props) => {

    function onAnotherDate() {
        document.getElementById('date_before').style.display = 'none';
        document.getElementById('date_after').style.display = 'flex';
        if(window.location.href.indexOf("/registration/children") !== -1)
            regChild.CheckForm();
        else if(window.location.href.indexOf("/children/add") !== -1)
            regChild.CheckForm();
    }

    return <>
        <input id={"date_before"} className={props.disabled? "input_test" : "input_test_change"} placeholder={"Дата рождения"} onFocus={onAnotherDate} disabled={props.disabled} value={ageStore.Age}/>

        <div id={"date_after"} className="row" onBlur={() => ageStore.changeStyleBack()}>
            <div className={"col-sm-3"}>
                <div className="date_with_border">
                    <DayUI checkChange={props.checkChange} disabled={props.disabled}/>
                </div>
            </div>
            <div className={"col-sm-5"}>
                <div className="date_with_border">
                    <MonthUI checkChange={props.checkChange} disabled={props.disabled}/>
                </div>
            </div>
            <div className={"col-sm-4"}>
                <div style={{height: '100%'}}>
                    <YearUI checkChange={props.checkChange} disabled={props.disabled}/>
                </div>
            </div>
        </div>
    </>
})

export default AgeUI;