import {observer} from "mobx-react";
import childGroupsStore from "../../../Stores/ChildGroups/ChildGroupsStore";
import React from "react";
import {ChildrenGroupTable} from "../Components/ChildrenGroup/childrenGroupTable";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {Link} from "react-router-dom";

export const ChildrenPage = observer(() => {
    nameStore.Clear();
    surnameStore.Clear();

    return <>

        <p style={{margin: "90px 0 0 0", padding: 0}}>ГРУППА: {childGroupsStore.GroupName}</p>
        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
        </svg>

        <div className="row pt-30">
            <div className="col-md-6 col-sm-12">
                <div className="form_group">
                    <div className="form_row ml-20 mb-5 mr-md-5">

                        <Link to={'/groups/children/add'} style={{width: '100%'}} onClick={() => {}}>
                            <button id="select_education" className="buttons button_green"
                                    style={{width: '100%', margin: 0, padding: 0}}>ДОБАВИТЬ РЕБЁНКА
                            </button>
                        </Link>

                    </div>
                </div>
            </div>

            <div className="col-md-6 col-sm-12">
                <div className="form_group">
                    <div className="form_row ml-md-5 mr-20 mb-5 mt-sm-5">

                        <button id="registration_button" className="buttons button_blue" onClick={(e) => {childGroupsStore.PageSwap(e); childGroupsStore.GroupChlid = []}}
                                style={{width: '100%', margin: 0, padding: 0}}>НАЗАД
                        </button>

                    </div>
                </div>
            </div>
        </div>

        <ChildrenGroupTable/>

    </>
})