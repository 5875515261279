import { observable, makeAutoObservable } from "mobx"
import {emailStore} from "../../Components/Inputs/Email/EmailStore";
import {restorePasswordPost} from "../../Request/api/User/restorePasswordPost";
import modalStore from "../../Components/modal/modalStore";

class RestorePassword {
    @observable message = "";

    constructor() {
        makeAutoObservable(this);
    }

    async checkRestoreData(){
        if(emailStore.GetEmail !== ''){
            await this.PostRestorePassword();
        }
    }

    async PostRestorePassword(){
        restorePasswordPost().then(r => {
            if(r !== null){
                if(r.type === "Data"){
                    modalStore.SetModalInfo("Проверьте почту, вам должно придти уведомление.", "Успешно")
                    modalStore.simpleModalOpen();
                    emailStore.Clear();
                }
                else {
                    modalStore.SetModalInfo(r.message, "Ошибка")
                    modalStore.simpleModalOpen();
                }
            } else {
                modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }
}

const restorePassword = new RestorePassword();

export default restorePassword;