import {observer} from "mobx-react";

export const TextDashboard = observer((props) => {
    return <div style={{display: 'flex', alignItems: "center", height: '100%'}}>
        <p className="game_text">{props.text}</p>
    </div>

    return <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{width: '36px', height: '152px', background: "transparent", display: "flex", alignItems: "flex-end"}}>
            <div style={{background: "transparent", height: '100%', width: '100%', position: "relative"}}>
                <div style={{transform: 'rotate(-90deg)', position: "absolute", top: '80%', left: 0, right: 0}}>
                    <div style={{width: '150px'}}>
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    </div>
})

{/*<div style={{width: '60px', height: '160px', display: "flex", alignItems: "center", justifyContent: "center"}}>*/}
{/*    <p style={{transform: 'rotate(-90deg)', height: '60px', margin: 0}}>Расшифруй фигуры</p>*/}
{/*</div>*/}