import {makeAutoObservable, observable, action} from "mobx";
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import modalStore from "../../../../../Components/modal/modalStore";
import buttonProfileStore from "../../../../../Components/Buttons/ProfileButton/buttonProfileStore";
import {innStore} from "../../../../../Components/Inputs/INN/innStore";
import {nameGardenStore} from "../../../../../Components/Inputs/GardenName/nameGardenStore";
import {getGartenInfo} from "../../../../../Request/api/Gartens/getGartenInfo";
import {updateGarten} from "../../../../../Request/api/Gartens/updateGarten";

class KindergardenStore {
    KindergardenData = null;
    @observable avatar = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action async getUserData(){

        if(authStore.isAuth){

            this.KindergardenData = await getGartenInfo(authStore.userData.CreatedGartenId).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    window.$('#simple_message_modal').modal('show');
                    return {};
                }
            })

            if(this.KindergardenData === null){
                this.avatar = null;
                innStore.INN = null;
                nameGardenStore.Garden = null;
            } else {
                this.avatar = this.KindergardenData.Avatar;
                innStore.INN = this.KindergardenData.KGInn;
                nameGardenStore.Garden = this.KindergardenData.KGName;
            }

        }
    }

    CheckForm = () => {
        if(innStore.INN !== '' && nameGardenStore.Garden !== '' &&
            (innStore.INN !== this.KindergardenData.KGInn ||
            nameGardenStore.Garden !== this.KindergardenData.KGName)){
            buttonProfileStore.SetSaveButtonBlock(false)
        } else
            buttonProfileStore.SetSaveButtonBlock(true)
    }

    UpdateGarden = () => {
        innStore.ValidINN();
        nameGardenStore.ValidGarden();

        if(innStore.GetINN !== '' && nameGardenStore.GetGarden !== ''){
            updateGarten().then(r => {
                if(r !== null && r.type === "Data"){
                    buttonProfileStore.SetSaveButtonBlock(true)
                    buttonProfileStore.SetButtonBlock()
                    modalStore.SetModalInfo("Данные аккаунта обновлены.", "Успешно")
                    window.$('#simple_message_modal').modal('show');
                } else {
                    modalStore.SetModalInfo('Произошла ошибка', "Ошибка")
                    window.$('#simple_message_modal').modal('show');
                    buttonProfileStore.SetSaveButtonBlock(true)
                    buttonProfileStore.SetButtonBlock()
                }
                this.getUserData();
            })
        }
    }

    keyDownForm = (e) => {
        if(!buttonProfileStore.saveButtonBlock && e.code === "Enter"){
            this.UpdateGarden();
        }
    }
}

const kindergardenStore = new KindergardenStore();

export default kindergardenStore;
