import {observer} from "mobx-react";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import NameUI from "../../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../../Components/Inputs/Surname/surnameUI";
import React from "react";
import {useHistory} from "react-router-dom";

export const ChildrenGroupForm = observer(() => {
    let history = useHistory();

    return <>
        <form style={{width: '100%'}} onChange={() => childGroupsStore.CheckFormChildChange()} onKeyDown={(e) => childGroupsStore.keyDownFormChild(e, history)}>
            <div className="row pt-30">
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5">
                        <NameUI/>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-sm-5">
                        <SurnameUI/>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-5 ml-5 mb-5 mt-5">
                        <button className={`buttons button_blue ${childGroupsStore.disabledChildButton}`} id="registration_button"
                                style={{width: '100%', margin: 0}} type="button" onClick={() => childGroupsStore.AddChild(history)}>Далее</button>
                    </div>
                </div>
            </div>
        </form>
    </>
})