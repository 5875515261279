import React from "react";
import { observer } from "mobx-react";
import authStore from '../../../../Stores/AuthStore/AuthStore'
import imageStore from "./StoreProfile/image";
import './fileInputStyle.css'

export const ImageProfile = observer( () => {

    return <>
        <p style={{margin: 0, padding: 0}}>АВАТАР</p>
        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
        </svg>
        <div className="pt-30"/>
        <div className="row image_div" style={{display: "flex", justifyContent: "space-around"}}>

            <div className="avatar">
                <div className={'avatar_hover'}>
                    <input type="file" name="file" id="file" onChange={(e) => imageStore.uploadFile(e)} style={{display: 'none'}}/>
                    <label htmlFor="file" className="btn btn-tertiary js-labelFile" style={{width: '100%'}}>
                        ИЗМЕНИТЬ<br/>АВАТАР
                    </label>
                </div>
                <img src={authStore.masterAccountData.avatar} width="100%" height="100%" style={{borderRadius: '5px'}} alt=""/>
            </div>

            <div className="pt-30 load_image">
                <span className="link" onClick={() => {authStore.deleteAvatar(authStore.decodeJwt.userId)}}>Удалить аватар</span>
            </div>

        </div>
    </>

    // return <>
    //     <p style={{margin: 0, padding: 0}}>АВАТАР</p>
    //     <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
    //     </svg>
    //     <div className="pt-30"/>
    //     <div className="row image_div" style={{display: "flex", justifyContent: "space-around"}}>
    //
    //         <div className="avatar">
    //             <div className={'avatar_hover'} onClick={() => {console.log('heh')}}>
    //                 <p>ИЗМЕНИТЬ<br/>АВАТАР</p>
    //             </div>
    //             <img src={authStore.masterAccountData.avatar} width="100%" height="100%" style={{borderRadius: '5px'}} alt=""/>
    //         </div>
    //
    //         <div className="pt-30 load_image">
    //             <input type="file" name="file" id="file" onChange={(e) => imageStore.uploadFile(e)} style={{display: 'none'}}/>
    //             <label htmlFor="file" className="btn btn-tertiary js-labelFile" style={{width: '80%'}}>
    //                 <span className="link">Загрузить изображение</span>
    //             </label>
    //         </div>
    //
    //     </div>
    // </>
})