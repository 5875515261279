import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import cropStore from '../AvatarCropStore/cropStore'
import imageStore from "../../../Modules/Account/AccountPages/ProfileUser/StoreProfile/image";
import authStore from "../../../Stores/AuthStore/AuthStore";

export const AvatarCrop = () => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await cropStore.getCroppedImg(
                cropStore.img,
                croppedAreaPixels,
            )

            if(window.location.href.indexOf('/kindergarten/change') !== -1){
                await imageStore.SendImage(croppedImage, authStore.userData.CreatedGartenId);
            } else {
                await imageStore.SendImage(croppedImage);
            }
            cropStore.closeCropModal();
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    const onClose = useCallback(() => {
        cropStore.closeCropModal();
    }, [])

    return (
        <div className="crop_modal">
            <h1>РЕДАКТИРОВАТЬ АВАТАР</h1>
            <svg width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div>
                <div className={'crop_container'}>
                    <Cropper
                        image={cropStore.img}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 4}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                    />
                </div>
            </div>

            <div className="buttons_crop">
                <button onClick={showCroppedImage} className="buttons button_green" style={{margin: 0}}>ГОТОВО</button>
                <button onClick={onClose} className="buttons button_blue" style={{margin: 0}}>НАЗАД</button>
            </div>
        </div>
    )
}