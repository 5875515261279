import {observer} from "mobx-react";
import React from "react";
import buttonProfileStore from "../../../Components/Buttons/ProfileButton/buttonProfileStore";
import ProfileButtonUI from "../../../Components/Buttons/ProfileButton/ButtonProfileUI";
import {ButtonType} from "../../Account/AccountPages/Profile";
import {innStore} from "../../../Components/Inputs/INN/innStore";
import {nameGardenStore} from "../../../Components/Inputs/GardenName/nameGardenStore";
import kindergardenStore from "../../Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";


export const KindergartenButton = observer(() => {
    return <div className="row">
        <div className="col-sm-12">
            <div className="form_group">
                {!buttonProfileStore.buttonBlock ?
                    <div className="ml-5 mr-5 mt-5" style={{width: '100%'}}>
                        <ProfileButtonUI color={"button_blue"} func={buttonProfileStore.SetButtonBlock} text={'Изменить данные'}
                                         disabled={buttonProfileStore.buttonBlock}/>
                    </div>
                    :
                    <ButtonType userType={'Pedagog'}
                                undo={() => {
                                    buttonProfileStore.SetButtonBlock();
                                    innStore.Clear();
                                    nameGardenStore.Clear();
                                    kindergardenStore.getUserData()
                                }}
                                send={() => {
                                    kindergardenStore.UpdateGarden()
                                }}
                    />
                }
            </div>
        </div>
    </div>
})
