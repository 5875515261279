import {makeAutoObservable, observable} from "mobx";

class RegStepStore {
    @observable step = "invite";

    constructor() {
        makeAutoObservable(this);
    }

    setStep(step){
        this.step = step;
    }
}

const regStepStore = new RegStepStore();

export default regStepStore;