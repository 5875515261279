import request from "../../Request";
import {rThen} from "../../requestThen";

export let getChildrenTokens = async (childrenId) => {

    return request.get(
        `/oauth/get_children_tokens/${childrenId}`, true).then(r => {
        return rThen(r)
    })
}
