import {observer} from "mobx-react";
import React from "react";
import {inviteStore} from "./InviteStore";
import {useHistory} from "react-router-dom";

const InviteUI = observer( () => {
    let history = useHistory();

    return <>

        <div className="row">
            <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                <div className="row" style={{width: '100%'}}>
                    <div className="col-sm-12 pt-30">
                        <div className="form_group">
                            <input  className={`input_test_change ${inviteStore.InviteValid}`} type="text" onKeyDown={inviteStore.KeyDown}
                                    onChange={(e)=>{inviteStore.SetInvite(e)}} value={inviteStore.Invite}
                                    placeholder="Введите код-приглашение" autoComplete="off"
                            />
                            <small className="form-text text-red" style={{color: "red"}}>{inviteStore.HelpText}</small>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form_group mt-10">
                            <button type="button" id="registration_button" className={`buttons button_blue ${inviteStore.disabled}`} style={{width: '100%', margin: 0}}
                                    onClick={() => {inviteStore.CheckInviteCode(history)}}>Далее</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
})

export default InviteUI;