import { observable, makeAutoObservable } from "mobx"
import authStore from "../../../../../Stores/AuthStore/AuthStore";
import kindergardenStore from "./KindergardenProfile";
import {updateAvatar} from "../../../../../Request/api/BaseUser/updateAvatar";
import loadStore from "../../../../../Stores/LoadStore/loadStore";
import modalStore from "../../../../../Components/modal/modalStore";
import Notify from "../../../../../Notifications/Notify";
import reportStore from "../../../../../Stores/Report/ReportStore";
import cropStore from "../../../../../Components/AvatarCrop/AvatarCropStore/cropStore";

class ImageStore {

    name='Choose file...'
    @observable loadImage = false;

    async uploadFile(inputElement) {

        let file = inputElement.target.files[0];
        inputElement.target.value = "";
        let reader = new FileReader();
        reader.onloadend = function() {
            cropStore.img = reader.result;
            cropStore.openCropModal = true;
        }

        if(typeof file !== "undefined")
            reader.readAsDataURL(file);

        // нормальная версия
        // loadStore.load = true;
        // let file = inputElement.target.files[0];
        // console.log(file)
        // this.loadImage = true;
        // await this.SendImage(file, kindegartenId);
        // this.loadImage = false;
    }

    async SendImage(file, kindegartenId=null){
        const formData  = new FormData();
        formData.append("avatar", file);

        loadStore.load = true;
        updateAvatar(formData, kindegartenId).then(r => {
            if(r !== null && r.type === 'Data'){
                if(kindegartenId !== null){
                    kindergardenStore.getUserData();
                }
                //authStore.CheckStoreType(() => {authStore.getCompulsoryToken()});
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
            if(!Notify.authResult){
                authStore.getCompulsoryToken();
            }
            loadStore.load = false;
        })
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const imageStore = new ImageStore();

export default imageStore;