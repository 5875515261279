import {action, makeAutoObservable, observable, runInAction} from "mobx";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {getPedagogList} from "../../../Request/api/Gartens/getPedagogList";

class TeacherDropdownStore {
    @observable teacher = "";
    @observable teacherId = "";
    @observable validTeacher = "";
    @observable HelpText = "";
    @observable teacherList = [];
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action async GetTeachers(){
        if(authStore.isAuth){
            let response = await getPedagogList(authStore.userData.CreatedGartenId === 0? null : authStore.userData.CreatedGartenId, 0, 100).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data;
                } else {
                    return [{}];
                }
            })

            response.forEach(
                user => {
                    if(user.UserId !== 0){
                        this.teacherList.push(user)
                    }
                }
            )
        }
    }

    ChoiceTeacher(teacher){
        document.getElementById('dropdown_teacher_list').style.display = 'none';
        this.teacher = teacher.User.Surname + ' ' + teacher.User.Name
        this.teacherId = teacher.UserId
        this.UserKeyDown();
        this.elem = document.activeElement.className;
        document.getElementById('dropdown_teacher').focus();
    }
    async FocusTeacher(){
        this.teacherList = []
        await this.GetTeachers();
    }

    ValidTeacher(){
        let userListId = []

        this.teacherList.forEach(user => {
            userListId.push(user.UserId)
        })

        if(userListId.indexOf(this.teacherId) !== -1){
            runInAction(() => {this.UserKeyDown();})
        } else {
            runInAction(() => {this.UserKeyDown("is-invalid", "Укажите педагога")})
        }
    }

    get getTeacher(){
        this.ValidTeacher();
        if(this.validTeacher === ""){
            return this.teacherId
        } else {
            return ""
        }
    }

    @action Clear(){
        this.teacher = "";
        this.teacherId = "";
        this.validTeacher = "";
        this.HelpText = "";
        this.teacherList = [];
        this.elem = "";
    }

    @action UserKeyDown(validTeacher="", helpText=""){
        this.validTeacher = validTeacher;
        this.HelpText = helpText;
    }

    Blur(){
        document.getElementById('dropdown_teacher_list').style.display = 'none';
    }
}

const teacherDropdownStore = new TeacherDropdownStore();

export default teacherDropdownStore;