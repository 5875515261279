import {action, makeAutoObservable, observable} from "mobx";

class MiddleNameStore {
    middleName = "";
    @observable middleNameValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(middleNameValid="", helpText=""){
        this.middleNameValid = middleNameValid;
        this.HelpText = helpText;
    }

    SetmiddleName(e){
        this.middleName = e.target.value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        this.UserKeyDown();
    }

    ValidmiddleName(){
    }

    get GetmiddleName(){
        this.ValidmiddleName();
        if(this.middleNameValid === "is-invalid"){
            return "";
        } else {
            return this.middleName;
        }
    }

    @action Clear(){
        this.middleName = "";
        this.HelpText = "";
        this.middleNameValid = "";
    }
}

export const middleNameStore = new MiddleNameStore();