import request from "../../Request";
import {rThen} from "../../requestThen";
import {emailStore} from "../../../Components/Inputs/Email/EmailStore";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";

export let getTokensAuth = async () => {

    return request.post(
        `/oauth/authentication`,
        {
            email: emailStore.GetEmail,
            password: newPasswordStore.GetPassword
        },
        false).then(r => {
        return rThen(r)
    })
}