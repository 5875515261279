import {action, makeAutoObservable, observable, runInAction} from "mobx";

class CityStore {
    City = "";
    @observable CityValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(cityValid="", helpText=""){
        this.CityValid = cityValid;
        this.HelpText = helpText;
    }

    SetCity(e){
        this.City = e.target.value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        this.UserKeyDown();
    }

    ValidCity(){
        if(this.City.trim().length > 0){
            runInAction(() => {this.UserKeyDown();})
        } else {
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите город')})
        }
    }

    get GetCity(){
        this.ValidCity();
        if(this.CityValid === "is-invalid"){
            return "";
        } else {
            return  this.City;
        }
    }

    @action Clear(){
        this.City = "";
        this.HelpText = "";
        this.CityValid = "";
    }
}

export const cityStore = new CityStore();