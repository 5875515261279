import React from 'react'
import { observer } from "mobx-react"
import inviteInputStore from "./InviteInputStore"
import HelpText from "../HelpText";

const InviteInput = observer (() => {
    return <>
            <div style={{width: '100%'}} className={'sm_none'}>
                <input className={`input_test_change ${inviteInputStore.inviteValid}`} id="add_teacher" type="text"
                       placeholder="Код-приглашение"
                       value={inviteInputStore.invite}
                       onChange={(e) => inviteInputStore.SetNewValue(e)}
                />
                <HelpText text={inviteInputStore.HelpText}/>
            </div>
            <div style={{width: '100%'}} className={'md_none'}>
                <input className={`input_test_change ${inviteInputStore.inviteValid}`} id="add_teacher" type="text"
                       placeholder="Инвайт-код"
                       value={inviteInputStore.invite}
                       onChange={(e) => inviteInputStore.SetNewValue(e)}
                />
                <HelpText text={inviteInputStore.HelpText}/>
            </div>
    </>
})

export default InviteInput
