import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {nameStore} from "../../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../../Components/Inputs/Surname/surnameStore";
import {genderStore} from "../../../Components/Inputs/gender/genderStore";
import {rThen} from "../../requestThen";
import {ageStore} from "../../../Components/Inputs/Age/ageStore";
import dayStore from "../../../Components/Inputs/Age/ChildAgeStore/dayStore";
import monthStore from "../../../Components/Inputs/Age/ChildAgeStore/monthStore";
import yearStore from "../../../Components/Inputs/Age/ChildAgeStore/yearStore";

export let updateChildren = async (childId=null) => {

    if(validationUpdateChildren())
        return request.put(
            `/api/childrens/update/${childId === null? authStore.userData.UserId : childId}`,
            {
                name: nameStore.GetName,
                surname: surnameStore.GetSurname,
                sex: genderStore.getGender,
                birthday: `${yearStore.year}-${String(ageStore.monthList.indexOf(monthStore.month)+1).length === 1? "0": ""}${ageStore.monthList.indexOf(monthStore.month)+1}-${String(dayStore.day).length === 1? "0" : ""}${dayStore.day}T00:00:00`
                // birthday: ageStore.GetAge+"T00:00:00"
            }
            ).then(r => {
                return rThen(r)
        })
    else
        return null;
}

let validationUpdateChildren = () =>{
    nameStore.ValidName();
    surnameStore.ValidSurname();
    genderStore.ValidGender();
    ageStore.ValidAge();
    return nameStore.GetName !== '' && surnameStore.GetSurname !== '' && genderStore.getGender !== '' && ageStore.GetAge !== '';
}