import {observer} from "mobx-react";
import React from "react";
import {telephoneStore} from "./TelephoneStore";
import HelpText from "../HelpText";
import { IMaskInput } from 'react-imask';

const TelephoneUI = observer((props) => {
    return <>

        <IMaskInput
            mask={"+{7}(000)000-00-00"}
            radix="."
            value={telephoneStore.Telephone}
            unmask={true}
            disabled={props.disabled}
            className={props.disabled? `input_test ${telephoneStore.TelephoneValid}`: `input_test_change ${telephoneStore.TelephoneValid}`}
            onAccept={(value) => {telephoneStore.SetTelephone(value, props.change)}}
            placeholder='Укажите ваш телефон'
        />
        <HelpText text={telephoneStore.HelpText}/>

    </>
})

export default TelephoneUI;