import {action, makeAutoObservable, observable, runInAction} from "mobx";

class NameStore {
    Name = "";
    @observable NameValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(NameValid="", helpText=""){
        this.NameValid = NameValid;
        this.HelpText = helpText;
    }

    SetName(e){
        this.Name = e.target.value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
        this.UserKeyDown();
    }

    ValidName(){
        if(this.Name.trim().length >= 1){
            runInAction(() => {this.UserKeyDown();})
        } else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите имя');})
        }
    }

    get GetName(){
        this.ValidName();
        if(this.NameValid === "is-invalid"){
            return "";
        } else {
            return this.Name;
        }
    }

    @action Clear(){
        this.Name = "";
        this.HelpText = "";
        this.NameValid = "";
    }
}

export const nameStore = new NameStore();