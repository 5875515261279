import {action, makeAutoObservable, observable, runInAction} from "mobx";

class GroupStore {
    Group = "";
    @observable GroupValid = "";
    @observable HelpText = "";

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(groupValid="", helpText=""){
        this.GroupValid = groupValid;
        this.HelpText = helpText;
    }

    SetGroup(e){
        this.Group = e.target.value;
        this.UserKeyDown();
    }

    ValidGroup(){
        if(this.Group.trim().length >= 1){
            runInAction(() => {this.UserKeyDown();})
        } else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите название группы');})
        }
    }

    get GetGroup(){
        this.ValidGroup();
        if(this.GroupValid === "is-invalid"){
            return "";
        } else {
            return this.Group.trim();
        }
    }

    @action Clear(){
        this.Group = "";
        this.HelpText = "";
        this.GroupValid = "";
    }
}

export const groupStore = new GroupStore();