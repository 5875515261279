import { observable, makeAutoObservable } from "mobx"
import {CheckEmailActivation} from "../../Request/api/User/CheckEmailActivation";

class ConfirmMail {
    @observable message = "";
    cid = new URLSearchParams(window.location.search).get("cid");
    key = new URLSearchParams(window.location.search).get("key");

    constructor() {
        makeAutoObservable(this);
        if(window.location.href.indexOf('confirmEmail') !== -1){
            this.comfirmMail();
        }
    }

    async comfirmMail(){
        this.message = await CheckEmailActivation(this.cid, this.key).then(r => {
            if(r !== null){
                return r.message
            } else {
                return 'Произошла ошибка'
            }
        })
    }
}

const confirmMail = new ConfirmMail();

export default confirmMail;