import  authStore from "../Stores/AuthStore/AuthStore"
import {CheckReletedAcc} from "../Modules/RegAuth/registration1";
import masterRegStore from "../Stores/Registration/MasterRegStore";
import {ChangeUserAfterReg} from "../Stores/Registration/SecondRegistration";
import modalStore from "../Components/modal/modalStore";
import {childrenStore} from "../Stores/Children/Children";
import {GetRelatedAccounts} from "../Stores/RelatedAccsStore/Requests/GetRelatedAccounts";
import {appConfig} from "../config";
import kindergardenStore from "../Modules/Account/AccountPages/ProfileUser/StoreProfile/KindergardenProfile";

class NotifyClass {
  authResult = false;
  errorNotifyCounter = 0;
  connectionId = this.uuidv4();

  async connectToNotify(){
        this.authResult = true;

        let response = await fetch(`${appConfig.notify_host}?token=${sessionStorage.getItem('access') || localStorage.getItem('access')}&&connectionId=${this.connectionId}`, {
            // let response = await fetch(`https://notify.playstand.ru?token=${await authStore.getact()}`, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json;charset=utf-8'}
        }).then(function (response) {
            if (response.ok) {
                return response.text();
            } else {
                Notify.authResult = false;
                Notify.errorNotifyCounter += 1;
                if (Notify.errorNotifyCounter >= 5) {
                    setTimeout(() => {
                        Notify.errorNotifyCounter = 0;
                        Notify.connectToNotify()
                        Notify.authResult = true;
                    }, 10000)
                } else {
                    Notify.connectToNotify()
                    Notify.authResult = true;
                }
            }
        }).then(function (text) {
            Notify.authResult = false;

            switch (text) {
                case "UserChange": {
                    Notify.userChange();
                    Notify.connectToNotify()
                    Notify.authResult = true;
                    break;
                }
                case "NewRelatedAccount": {
                    Notify.NewRelatedAccount();
                    Notify.connectToNotify()
                    Notify.authResult = true;
                    break;
                }
                case "token error": {
                    Notify.authResult = false;
                    Notify.errorNotifyCounter += 1;
                    if (Notify.errorNotifyCounter >= 1) {
                        setTimeout(() => {
                            Notify.errorNotifyCounter = 0;
                            Notify.connectToNotify()
                            Notify.authResult = true;
                        }, 10000)
                    } else {
                        Notify.connectToNotify()
                        Notify.authResult = true;
                    }
                    break;
                }
                default: {
                }
            }

        })
        .catch(function (error) {
            Notify.authResult = false;
            setTimeout(() => {
                Notify.connectToNotify()
                Notify.authResult = true;
            }, 10000)
        });
}


  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  userChange = () => {

      authStore.getCompulsoryToken();

      if(window.location.href.indexOf('/children') !== -1){
        childrenStore.GetChldrens();
      }

      if(window.location.href.indexOf('/kindergarten/change') !== -1){
          kindergardenStore.getUserData();
      }
    }


  NewRelatedAccount = () => {

        if (authStore.decodeJwt.userType === 'User') {
          CheckReletedAcc().then(r => {

                // if (r.indexOf('Children') !== -1 || r.indexOf('Kindergarten') !== -1) {

                if (r.indexOf('Children') !== -1 || this.pedagogReg) {

                  modalStore.EndReg = true;
                  ChangeUserAfterReg();
                  modalStore.SetModalInfo("Осталось только подтвердить ваш e-mail", "ВЫ УСПЕШНО ЗАРЕГИСТРИРОВАЛИСЬ!")
                  modalStore.ModalOpen();
                  masterRegStore.setStep('invite');

                } else if (r.indexOf('Parent') !== -1) {
                  if(sessionStorage.getItem('invite') === null)
                    masterRegStore.setStep('questChild');
                  else
                    masterRegStore.setStep('children')
                  window.location.reload();

                } else if (r.indexOf('Pedagog') !== -1) {

                  if(sessionStorage.getItem('invite') === null)
                    masterRegStore.setStep('questKindegarten');
                  else
                    masterRegStore.setStep('kindegarten');
                  window.location.reload();

                }
              },
              e => {

              })
        } else {
          if(window.location.href.indexOf('/children') !== -1){
            childrenStore.GetChldrens();
            GetRelatedAccounts(authStore.decodeJwt.userId, authStore.access);
          }
        }

    }



}

const Notify = new NotifyClass()

export default Notify
