import {action, makeAutoObservable, observable, runInAction} from "mobx";

class GenderStore {
    GenderList = ["Мужской", "Женский"]
    Gender = "";
    @observable dropdownGenderFlag = true;
    @observable validGender = "";
    @observable HelpText = "";
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    setGender(e){
        this.UserKeyDown()
        this.GenderList = ["Мужской", "Женский"]
        e.preventDefault();
    }

    @action UserKeyDown(validPos="", helpText=""){
        this.validGender = validPos;
        this.HelpText = helpText;
    }

    ValidGender(){
        if(this.Gender.trim().length !== 0){
            runInAction(() => {this.UserKeyDown();})
        }
        else{
            runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите пол');})
        }
    }

    ChoiceGender(Gender){
        document.getElementById('dropdown_gender_list').style.display = 'none';
        this.Gender = Gender;
        this.UserKeyDown();
        this.elem = document.activeElement.className
        document.getElementById('dropdown_gender').focus();
    }

    get getGender(){
        this.ValidGender();
        if(this.validGender === ""){
            return this.Gender === "Мужской";
        } else
            return ""
    }

    @action Clear(){
        this.Gender = "";
        this.validGender = "";
        this.HelpText = "";
        this.elem = "";
    }

    Blur(){
        document.getElementById('dropdown_gender_list').style.display = 'none';
    }
}

export const genderStore = new GenderStore();
