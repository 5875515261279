import request from "../../Request";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {rThen} from "../../requestThen";

export let updateAvatar = async (formData, kindegartenId=null) => {

    return request.put(
        `/api/base_user/upload_avatar/${kindegartenId === null? authStore.decodeJwt.userId : kindegartenId}`,
        {},
        formData
    ).then(r => {
        return rThen(r)
    })
}

