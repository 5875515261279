import {observer} from "mobx-react";
import React from "react";
import HelpText from "../HelpText";
import {innStore} from './innStore'
import '../../../styles/tooltip.css'

const InnUI = observer((props) => {

    return <>
        <div className="btn btn-primary tooltip mr-md-5" style={{width: '99%'}}>
            <input id={'INN'} className={`${props.disabled? "input_test" : "input_test_change"} ${innStore.INNValid}`} type="text" maxLength={12} disabled={props.disabled}
                   aria-describedby="emailHelp" placeholder="12 символов" value={innStore.INN}
                   onChange={(e) => innStore.SetINN(e)}
                   onKeyDown={(e) => innStore.PatternKeyDown(e)}
            />
            <HelpText text={innStore.HelpText}/>
            <div className="top" style={{display: props.styleDisplay}}>
                <p>ИНН состоит из 8, 10 или 12 цифр.</p>
                <i></i>
            </div>
        </div>
    </>
})

export default InnUI;
