import placeStore from "../../../Stores/Registration/place";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import countryStore from "../Country/CountryStore";

class StateStore{
    @observable returnStateList = [];
    @observable state = "";
    @observable validState = "";
    @observable stateFlag = false;
    @observable HelpText = "";
    @observable dropdownVisual = false;
    elem = "";

    constructor() {
        makeAutoObservable(this);
    }

    ChoiceState(state){
        document.getElementById('dropdown_state_list').style.display = 'none';
        this.state = state;
        this.HelpText = "";
        this.UserKeyDown();
        this.elem = document.activeElement.className;
        document.getElementById('dropdown_stat').focus();
    }

    FocusState(){
        if(countryStore.country === 'Россия')
            placeStore.GetRegionByCountry(countryStore.country).then(r => {
            this.returnStateList = placeStore.state
        })
    }

    setState(e){
        this.HelpText = "";
        this.validState = "";
        this.state = e.target.value;
    }

    @action UserKeyDown(validState="", helpText=""){
        this.validState = validState;
        this.HelpText = helpText;
    }

    ValidState(){
        if(countryStore.country === "Россия"){
            if(placeStore.state.indexOf(this.state) !== -1){
                runInAction(() => {this.UserKeyDown();})
            } else {
                runInAction(() => {this.UserKeyDown("is-invalid", "Укажите регион")})
            }
        } else {
            if(this.state.trim().length >= 1){
                runInAction(() => {this.UserKeyDown();})
            } else{
                runInAction(() => {this.UserKeyDown('is-invalid', 'Укажите регион');})
            }
        }
    }

    get getState(){
        this.ValidState();
        if(this.validState === ""){
            return this.state
        } else {
            return ""
        }
    }

    @action Clear(){
        this.validState = "";
        this.state = "";
        this.HelpText = "";
        this.elem = "";
    }

    SetTextState(text){
        this.state = text;
    }

    KeyDown(e){
        if(countryStore.country === "Россия"){
            if(e.keyCode === 8 || e.keyCode === 13 || e.keyCode === 9){}
            else {e.preventDefault()}
        }
    }

    Blur(){
        document.getElementById('dropdown_state_list').style.display = 'none';
    }
}

const stateStore = new StateStore();

export default stateStore;