import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {FormGroup} from "./formGroup";
import childGroupsStore from "../../../../Stores/ChildGroups/ChildGroupsStore";
import authStore from "../../../../Stores/AuthStore/AuthStore";
import {LeftHeader} from "../../../../Structure/LeftHeader/leftHeader";
import {Link, useHistory} from "react-router-dom";
import {RightHeader} from "../../../../Structure/RightHeader/rightHeader";
import teacherDropdownStore from "../../../../Components/Inputs/TeacherDropdown/teacherDropdownStore";
import {groupStore} from "../../../../Components/Inputs/ChildGroup/ChildGroupStore";


export const AddGroup = observer(() => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if (authStore.userData.UserType !== 'Pedagog' || authStore.userData.Position.IsEditable) {
        history.push('/account');
        return null;
    }

    if(authStore.userData.CreatedGartenId === 0){
        history.push('/groups');
        return null;
    }

    useEffect(() => {
        teacherDropdownStore.Clear();
        groupStore.Clear();
        childGroupsStore.CheckFormGroupChange();
    })

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">
            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ ПЕДАГОГА/</Link>
                <Link to={'/groups'} className="grey_text">ГРУППЫ/</Link>
                <Link to={'/groups/add'} className="blue_text">СОЗДАТЬ ГРУППУ</Link>
            </p>
            <p className="greetings">СОЗДАТЬ ГРУППУ</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                    <FormGroup/>
                </div>
            </div>
        </div>
    </>
})