import {action, makeAutoObservable, observable} from "mobx";

class AgeStore {
    Age = "";
    @observable AgeValid = "";
    @observable HelpText = "";
    monthList = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ]
    lastDayInMonth = [ 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
    @observable ageFlag = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action UserKeyDown(ageValid="", helpText=""){
        this.AgeValid = ageValid;
        this.HelpText = helpText;
    }

    SetAge(e){

    }

    ageKeyDown = (e) => {

    }

    ValidInputAge(){

    }

    getTodaysDate(subtract){
        let date = new Date();
        let year = date.getFullYear();
        return year-subtract;
    }

    ValidAge(){
        if(this.Age === ""){
            this.HelpText = "Укажите дату рождения";
            this.AgeValid = "is-invalid";
        } else {
            this.AgeValid = "";
            this.HelpText = "";
        }
    }

    get GetAge(){
        this.ValidAge();
        this.ValidInputAge();

        if(this.AgeValid === "is-invalid"){
            return "";
        } else {
            return this.Age;
        }
    }

    @action Clear(){
        this.Age = "";
        this.HelpText = "";
        this.AgeValid = "";
    }

    changeStyle = () => {
        document.getElementById('dropdown_day').style.borderLeft = '1px solid #4FC1E9';
        document.getElementById('dropdown_day').style.borderTop = '1px solid #4FC1E9';
        document.getElementById('dropdown_day').style.borderBottom = '1px solid #4FC1E9';
        document.getElementById('dropdown_year').style.border = '1px solid #4FC1E9';
        document.getElementById('dropdown_month').style.border = '1px solid #4FC1E9';
    }

    changeStyleBack = () => {
        document.getElementById('dropdown_day').style.borderLeft = '1px solid rgba(67,74,84, .2)';
        document.getElementById('dropdown_day').style.borderTop = '1px solid rgba(67,74,84, .2)';
        document.getElementById('dropdown_day').style.borderBottom = '1px solid rgba(67,74,84, .2)';
        document.getElementById('dropdown_year').style.border = '1px solid rgba(67,74,84, .2)';
        document.getElementById('dropdown_month').style.border = '1px solid rgba(67,74,84, .2)';
    }
}

export const ageStore = new AgeStore();