import React from 'react';
import { observer } from "mobx-react";
import restore from "../../Stores/Registration/restore";
import PasswordUI from "../../Components/Inputs/Password/PasswordUI";
import {newPasswordStore} from "../../Components/Inputs/Password/NewPasswordStore";
import {confirmPasswordStore} from "../../Components/Inputs/Password/ConfirmPasswordStore";

export const RestorePassword1 = observer( () => {

    return <>
        <div className="main pt_header">
            <p className="title_greetings">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</p>
            <p className="greetings">ИЗМЕНЕНИЕ ПАРОЛЯ</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                    <div className="row" style={{width: '100%'}}>
                        <form style={{width: '100%'}}>
                            <div className="col-md-12">
                                <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10 mt-sm-5">
                                    <PasswordUI text={"Новый пароль"} valid={newPasswordStore.passwordValid}
                                                password={newPasswordStore.password}
                                                func={newPasswordStore.SetPassword}
                                                placeholder={'Укажите новый пароль'}
                                                HelpText={newPasswordStore.HelpText}
                                                src={newPasswordStore.imgEye}
                                                type={newPasswordStore.inputType}
                                                eyeClcik={newPasswordStore.eyeClcik}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10">
                                    <PasswordUI text={"Подтвердите пароль"} valid={confirmPasswordStore.passwordValid}
                                                password={confirmPasswordStore.password}
                                                func={confirmPasswordStore.SetPassword}
                                                placeholder={'Подтвердите пароль'}
                                                HelpText={confirmPasswordStore.HelpText}
                                                src={confirmPasswordStore.imgEye}
                                                type={confirmPasswordStore.inputType}
                                                eyeClcik={confirmPasswordStore.eyeClcik}
                                                styleDisplay={'none'}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10">
                                    <button style={{width: '100%', margin: 0}} id="registration_button" className={`buttons button_blue`} type="button" onClick={() => {restore.checkRestoreData()}}>Далее</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
})